/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import CustomTable from "@Molecule/CustomTable";
import AddContactInfoModal from "@Molecule/_modal/AddContactInfoModal";
import Images from "@Theme/Images";
import { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import ErrorBubble from "@Atom/ErrorBubble";

export default function ContactsSection(props) {
  const {
    form,
    setForm,
    setTrigger,
    alertMessage = [],
    clientContacts,
  } = props;

  const [showAdd, setShowAdd] = useState(false);

  const [contacts, setContacts] = useState(form?.contacts);

  useEffect(() => {
    setContacts(form?.contacts);
  }, [form?.contacts]);

  const columns = [
    {
      name: "name",
      label: "Name",
    },
    {
      name: "jobTitle",
      label: "Position",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "phoneNumber",
      label: "Phone",
    },
    {
      name: "workUnit",
      label: "Unit Kerja",
    },
    {
      name: "",
      label: "Action",
      align: "center",
      renderData: (row) => (
        <div
          className={Styles.deleteContactWrapper}
          onClick={() =>
            setContacts(contacts?.filter((obj) => obj?.id !== row?.id))
          }
        >
          <button className={Styles.deleteContact}>
            <Icon icon={"trash"} size={20} color={"#FF3E13"} />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const newForm = { ...form };
    newForm.contacts = contacts;
    setForm(newForm);
    setTrigger(Math.random());
    // sessionStorage.setItem('additionalForm', JSON.stringify(form))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setForm, setTrigger, contacts]);

  return (
    <div
      id="Contact Information"
      className={`${Styles.container} ${
        alertMessage?.length > 0 &&
        alertMessage?.find(
          (el) =>
            el?.remark === "Contact Information" &&
            el?.validateContactInformation === true
        ) &&
        !contacts?.length
          ? Styles.error
          : ""
      }`}
    >
      {alertMessage?.length > 0 &&
        alertMessage?.find(
          (el) =>
            el?.remark === "Contact Information" &&
            el?.validateContactInformation === true
        ) &&
        !contacts?.length && (
          <ErrorBubble
            marginTop="0px"
            errorText="Contact information belum di isi"
            marginBottom="8px"
            noTip
          />
        )}
      <div className={Styles.header}>
        <h3>Contact Information</h3>
        <p>
          (Informasi kontak harus memiliki minimal <span>1 kontak Invoice</span>{" "}
          dan <span>1 kontak Payroll</span>)
        </p>
        {!!contacts?.length && (
          <button className={Styles.cIButton} onClick={() => setShowAdd(true)}>
            <Icon icon={"circle-plus"} size={20} color={"#fff"} />
            <span>Contact Information</span>
          </button>
        )}
      </div>
      {contacts?.length ? (
        <div className={Styles.contentTable}>
          <CustomTable
            noPagination
            data={contacts}
            columns={columns}
            oddRowClassName={Styles.odd}
            minHeight="250px"
          />
        </div>
      ) : (
        <div className={Styles.emptyWrapper}>
          <img src={Images.CONTACT_PLACEHOLDER} alt="" />
          <span>Belum ada kontak klien ditambahkan</span>
          <button className={Styles.cIButton} onClick={() => setShowAdd(true)}>
            <Icon icon={"circle-plus"} size={20} color={"#fff"} />
            <span>Contact Information</span>
          </button>
        </div>
      )}

      {showAdd && (
        <AddContactInfoModal
          show={showAdd}
          setShow={setShowAdd}
          contacts={contacts}
          setContacts={setContacts}
          clientContacts={clientContacts}
        />
      )}
    </div>
  );
}
