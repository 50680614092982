/*eslint-disable no-useless-escape */

import { useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import InputNumberField from "@Molecule/InputNumberField";
import InputField, { InputTitle } from "@Molecule/InputField";
import { makeRandomString } from "@Helpers/makeRandomString";
import Dropdown from "@Atom/DropDown";

export default function FlowSection(props) {
  const { form, setForm, setTrigger, alertMessage = [] } = props;
  const [dateOfReceiptOfData, setDateOfReceiptOfData] = useState(
    form?.invoiceFlow?.dateOfReceiptOfData
  );
  const [dateOfFillingOfSoftCopy, setDateOfFillingOfSoftCopy] = useState(
    form?.invoiceFlow?.dateOfFillingOfSoftCopy
  );
  const [dateOfFillingOfHardCopy, setDateOfFillingOfHardCopy] = useState(
    form?.invoiceFlow?.dateOfFillingOfHardCopy
  );
  const [UP, setUP] = useState(form?.invoiceFlow?.UP);
  const [payrollDate, setPayrollDate] = useState(
    form?.invoiceFlow?.payrollDate
  );
  const [cutOffAttendance, setCutOffAttendance] = useState(
    form?.invoiceFlow?.cutOffAttendance
  );
  const [docSupport, setDocSupport] = useState(
    form?.invoiceFlow?.supportingDocument
  );

  useEffect(() => {
    const newForm = { ...form };
    newForm.invoiceFlow.dateOfReceiptOfData = Number(dateOfReceiptOfData);
    newForm.invoiceFlow.dateOfFillingOfSoftCopy = Number(
      dateOfFillingOfSoftCopy
    );
    newForm.invoiceFlow.dateOfFillingOfHardCopy = Number(
      dateOfFillingOfHardCopy
    );
    newForm.invoiceFlow.UP = UP;
    newForm.invoiceFlow.payrollDate = Number(payrollDate);
    newForm.invoiceFlow.cutOffAttendance = Number(cutOffAttendance);
    newForm.invoiceFlow.supportingDocument = docSupport;
    setForm(newForm);
    setTrigger(makeRandomString(5));
    // sessionStorage.setItem('additionalForm', JSON.stringify(form))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setForm,
    dateOfReceiptOfData,
    dateOfFillingOfSoftCopy,
    dateOfFillingOfHardCopy,
    UP,
    payrollDate,
    cutOffAttendance,
    docSupport,
    setTrigger,
  ]);

  const validUp = useMemo(() => {
    return form?.contacts?.find((contact) => contact?.name === UP) || null;
  }, [form, UP]);

  useEffect(() => {
    if (validUp === null) {
      setUP("");
    }
  }, [validUp]);

  return (
    <div className={Styles.container}>
      <h3>Flow Invoice</h3>

      <div className={Styles.content}>
        <div className={Styles.selectsWrapper}>
          <div className={Styles.inputWrapper} id="Tanggal Diterima Data">
            <InputNumberField
              title="Tanggal Diterima Data"
              min={1}
              max={31}
              value={dateOfReceiptOfData}
              setValue={setDateOfReceiptOfData}
              isError={
                alertMessage?.length > 0 &&
                alertMessage?.find(
                  (el) =>
                    el?.remark === "Tanggal Diterima Data" &&
                    el?.validateDateOfReceiptOfData === true
                ) &&
                !dateOfReceiptOfData
              }
              isErrorV2
              errorText="Tanggal diterima data belum dipilih"
              required
            />
          </div>
          <div
            className={Styles.inputWrapper}
            id="Tanggal Pengajuan Soft Copy Invoice"
          >
            <InputNumberField
              title="Tanggal Pengajuan Soft Copy Invoice"
              min={1}
              max={31}
              value={dateOfFillingOfSoftCopy}
              setValue={setDateOfFillingOfSoftCopy}
              isError={
                alertMessage?.length > 0 &&
                alertMessage?.find(
                  (el) =>
                    el?.remark === "Tanggal Pengajuan Soft Copy Invoice" &&
                    el?.validateDateOfFillingOfSoftCopy === true
                ) &&
                !dateOfFillingOfSoftCopy
              }
              errorText="Tanggal pengajuan soft copy invoice belum dipilih"
              isErrorV2
              required
            />
          </div>
          <div
            className={Styles.inputWrapper}
            id="Tanggal Hard Copy Invoice Diterima"
          >
            <InputNumberField
              title="Tanggal Hard Copy Invoice Diterima"
              min={1}
              max={31}
              value={dateOfFillingOfHardCopy}
              setValue={setDateOfFillingOfHardCopy}
              isError={
                alertMessage?.length > 0 &&
                alertMessage?.find(
                  (el) =>
                    el?.remark === "Tanggal Hard Copy Invoice Diterima" &&
                    el?.validateDateOfFillingOfHardCopy === true
                ) &&
                !dateOfFillingOfHardCopy
              }
              isErrorV2
              errorText="Tanggal pengajuan hard copy belum dipilih"
              required
            />
          </div>
          <div className={Styles.inputWrapper} id="UP di Invoice">
            <InputTitle title={"UP di Invoice"} required={true} />
            <Dropdown
              placeholder={"Name"}
              value={UP || ""}
              setValue={setUP}
              isError={
                alertMessage?.length > 0 &&
                alertMessage?.find(
                  (el) =>
                    el?.remark === "UP di Invoice" && el?.validateUP === true
                ) &&
                !UP
              }
              isErrorV2={
                alertMessage?.length > 0 &&
                alertMessage?.find(
                  (el) =>
                    el?.remark === "UP di Invoice" && el?.validateUP === true
                ) &&
                !UP
              }
              errorText="UP di invoide belum diisi"
              autoClose
              options={
                form?.contacts?.map((contact, idx) => {
                  return {
                    id: idx,
                    name: contact?.name,
                  };
                }) || []
              }
            />
          </div>
          <div className={Styles.selectWrapper} id="Tanggal Mulai Penggajian">
            <InputNumberField
              title="Tanggal Mulai Penggajian"
              min={1}
              max={31}
              value={payrollDate}
              setValue={setPayrollDate}
              isError={
                alertMessage?.length > 0 &&
                alertMessage?.find(
                  (el) =>
                    el?.remark === "Tanggal Mulai Penggajian" &&
                    el?.validatePayrollDate === true
                ) &&
                !payrollDate
              }
              isErrorV2
              errorText="Tanggal mulai penggajian belum diisi"
              required
            />
          </div>
          <div className={Styles.selectWrapper} id="Tanggal Cut Off Absensi">
            <InputNumberField
              title="Tanggal Cut Off Absensi"
              min={1}
              max={31}
              value={cutOffAttendance}
              setValue={setCutOffAttendance}
              isError={
                alertMessage?.length > 0 &&
                alertMessage?.find(
                  (el) =>
                    el?.remark === "Tanggal Cut Off Absensi" &&
                    el?.validateCutOffAttendance === true
                ) &&
                !cutOffAttendance
              }
              isErrorV2
              errorText="Tanggal cut off absensi belum diisi"
              required
            />
          </div>
        </div>
        <div className={Styles.descWrapper}>
          <InputField
            type="desc"
            title="Dokumen Pendukung Invoice"
            value={docSupport || ""}
            onChange={(e) =>
              setDocSupport(
                e.target.value.replace(
                  /[^0-9/\[\]\\_+,.@!`~#%$*&()={}|:;"'<,>?\-\n a-zA-Z]+/gi,
                  ""
                )
              )
            }
            placeholder={"Deskripsi"}
          />
        </div>
      </div>
    </div>
  );
}
