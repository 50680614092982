import { useEffect, useRef } from "react";
import BPJSSection from "./BPJSSection";
import OthersSection from "./OthersSection";
import TaxesSection from "./TaxesSection";
import Styles from "./style.module.scss";
import TKOContractSection from "./TKOContractSection";

export default function SecondStep(props) {
  const {
    form,
    setForm,
    bankOptions,
    // onNext,
    // onPrevious,
    options,
    setTrigger,
    setActiveButtonBPJS,
    selectedButtonBPJS,
    setSelectedButtonBPJS,
    selectedValueBPU,
    setSelectedValueBPU,
    alertMessage = [],
    contractType,
    withBPJS = false,
    setWithBPJS = () => {},
    optionalBPJS = false,
  } = props;

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <TKOContractSection
        setTrigger={setTrigger}
        form={form}
        setForm={setForm}
        alertMessage={alertMessage}
      />
      <TaxesSection
        setTrigger={setTrigger}
        form={form}
        setForm={setForm}
        options={options.taxs}
        alertMessage={alertMessage}
        contractType={contractType}
      />
      <BPJSSection
        setTrigger={setTrigger}
        form={form}
        setForm={setForm}
        options={options?.bpjs}
        setActiveButtonBPJS={setActiveButtonBPJS}
        selectedButtonBPJS={selectedButtonBPJS}
        setSelectedButtonBPJS={setSelectedButtonBPJS}
        selectedValueBPU={selectedValueBPU}
        setSelectedValueBPU={setSelectedValueBPU}
        alertMessage={alertMessage}
        withBPJS={withBPJS}
        setWithBPJS={setWithBPJS}
        optionalBPJS={optionalBPJS}
      />
      <OthersSection
        bankOptions={bankOptions}
        setTrigger={setTrigger}
        form={form}
        setForm={setForm}
        alertMessage={alertMessage}
        options={options?.others}
      />
      {/* <div className={Styles.buttonWrapper}>
        <button onClick={onPrevious}>Back</button>
        <button onClick={onNext}>Next Step</button>
      </div> */}
    </div>
  );
}
