import LoadingAnimation from "@Atom/LoadingAnimation";
import SchemeDetailContent from "@Organism/Manager/Client/WorkSchemes/SchemeDetail/Layout";
import SchemeDetailNavigator from "@Organism/Manager/Client/WorkSchemes/SchemeDetail/Navigation";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { getSchemeDetail, orderSync } from "@Services/manager/client";
import { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Await, defer, useLoaderData } from "react-router-dom";
import Styles from "./style.module.scss";
import UpdateLogs from "./UpdateLogs";
import Icon from "@Atom/Icon";
import UpdateLogsModal from "@Organism/HumanCapital/FLBVerification/FLBVerificationDetails/UpdateLogsModal";

export async function loader({ params }) {
  const id = params.id;
  const schemeDetailPromise = getSchemeDetail(id);
  const orderSyncResult = orderSync("OWNER");

  return defer({
    packageSchemeDetail: schemeDetailPromise,
    packageOrderSync: orderSyncResult,
  });
}

export default function SchemeDetail() {
  const { packageSchemeDetail, packageOrderSync } = useLoaderData();
  const [selectedLogs, setSelectedLogs] = useState(null);
  const [showLogResponsive, setShowLogResponsive] = useState(false);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Scheme Details</title>
      </Helmet>
      <div className={Styles.navigatorWrapper}>
        <SchemeDetailNavigator />
      </div>

      <div className={Styles.contentWrapper}>
        <Suspense
          fallback={
            <div className={Styles.loadingWrapper}>
              <LoadingAnimation />
            </div>
          }
        >
          <Await
            resolve={packageSchemeDetail}
            errorElement={
              <div style={{ height: "calc(100vh - 220px)" }}>
                <AsyncErrorBoundary />
              </div>
            }
          >
            {(packageSchemeDetail) => (
              <Suspense
                fallback={
                  <div className={Styles.loadingWrapper}>
                    <LoadingAnimation />
                  </div>
                }
              >
                <Await resolve={packageOrderSync}>
                  {(packageOrderSync) => (
                    <div className={Styles.schemeDetailBox}>
                      {windowSize[0] <= 1024 ? (
                        !packageSchemeDetail?.response?.humanResources
                          ?.updateLogs ? null : (
                          <div
                            className={Styles.stickyResponsive}
                            onClick={() => setShowLogResponsive(true)}
                          >
                            <div>
                              <Icon
                                icon={"clock-back"}
                                size={24}
                                color={"#EDEDED"}
                              />
                            </div>
                          </div>
                        )
                      ) : null}
                      <div
                        className={` ${Styles.detailFLBBox} ${
                          packageSchemeDetail?.response?.humanResources
                            ?.updateLogs === null || windowSize[0] <= 1024
                            ? Styles.width
                            : ""
                        }`}
                      >
                        <SchemeDetailContent
                          data={packageSchemeDetail?.response}
                          qualifications={
                            packageOrderSync?.response?.qualifications
                          }
                          isHC
                          selectedLogs={selectedLogs}
                          updateLogs={
                            packageSchemeDetail?.response?.humanResources
                              ?.updateLogs || []
                          }
                        />
                      </div>
                      {!packageSchemeDetail?.response?.humanResources
                        ?.updateLogs || windowSize[0] <= 1024 ? null : (
                        <div className={Styles.updateLogsBox}>
                          <UpdateLogs
                            data={packageSchemeDetail?.response}
                            selectedLogs={selectedLogs}
                            setSelectedLogs={setSelectedLogs}
                          />
                        </div>
                      )}

                      {showLogResponsive && (
                        <UpdateLogsModal
                          handleDone={() => setShowLogResponsive(false)}
                          data={packageSchemeDetail?.response}
                          selectedLogs={selectedLogs}
                          setSelectedLogs={setSelectedLogs}
                          showLogResponsive={showLogResponsive}
                        />
                      )}
                    </div>
                  )}
                </Await>
              </Suspense>
            )}
          </Await>
        </Suspense>
      </div>
    </div>
  );
}
