/* eslint-disable no-unused-vars */
import SelectOptionsToggle from "@Molecule/SelectOptionToggle";
import Styles from "./style.module.scss";
import { useEffect, useState } from "react";
import FormErrorMessage from "@Atom/FormErrorMessage";
import { InputTitle } from "@Molecule/InputField";
import DinamicDecorativeInputField from "@Molecule/DinamicDecorativeInputField";
import ErrorBubble from "@Atom/ErrorBubble";
import Switch from "@Atom/Switch";

export default function BPJSSection(props) {
  const {
    form,
    setForm,
    options,
    setTrigger,
    setActiveButtonBPJS,
    selectedButtonBPJS,
    setSelectedButtonBPJS,
    selectedValueBPU,
    setSelectedValueBPU,
    alertMessage = [],
    withBPJS = false,
    setWithBPJS = () => {},
    optionalBPJS = false,
  } = props;

  const [registration, setRegistration] = useState(form?.bpjs?.registration);
  const [paymentProof, setPaymentProof] = useState(form?.bpjs?.paymentProof);
  const [jkkOn, setJkkOn] = useState(form?.bpjs?.jkkOn);
  const [jkk, setJkk] = useState(form?.bpjs?.jkk);
  const [jkmOn, setJkmOn] = useState(form?.bpjs?.jkmOn);
  const [jkm, setJkm] = useState(form?.bpjs?.jkm);
  const [jhtOn, setJhtOn] = useState(form?.bpjs?.jhtOn);
  const [jht, setJht] = useState(form?.bpjs?.jht);
  const [jpOn, setJpOn] = useState(form?.bpjs?.jpOn);
  const [jp, setJp] = useState(form?.bpjs?.jp);
  const [ksOn, setKsOn] = useState(form?.bpjs?.healthOn);
  const [ks, setKs] = useState(form?.bpjs?.health);
  const [akdhkOn, setAkdhkOn] = useState(form?.bpjs?.akhdkOn);
  const [akdhk, setAkdhk] = useState(form?.bpjs?.akhdk);
  const [cutOffBPJSTKO, setCutOffBPJSTKO] = useState(form?.bpjs?.cutOffBPJSTKO);
  const [cutOffNominal, setCutOffNominal] = useState(
    form?.bpjs?.cutOffNominal || ""
  );

  useEffect(() => {
    if (form?.tkoContract?.includes("PKWT")) {
      setActiveButtonBPJS("Tenaga Kerja");
    }
  }, [form?.tkoContract, setActiveButtonBPJS]);

  const optionsBPU = [
    {
      id: 1,
      name: "Tagih Ke Perusahaan",
    },
    {
      id: 2,
      name: "Tagih Ke Karyawan",
    },
    {
      id: 2,
      name: "Tagih Ke Perusahaan & Karyawan",
    },
  ];

  useEffect(() => {
    const newForm = form;
    newForm.bpjs.registration = registration;
    newForm.bpjs.paymentProof = paymentProof;
    newForm.bpjs.jkk = jkk;
    newForm.bpjs.jkkOn = jkkOn;
    newForm.bpjs.jkm = jkm;
    newForm.bpjs.jkmOn = jkmOn;
    newForm.bpjs.jhtOn = jhtOn;
    newForm.bpjs.jht = jht;
    newForm.bpjs.jpOn = jpOn;
    newForm.bpjs.jp = jp;
    newForm.bpjs.healthOn = ksOn;
    newForm.bpjs.health = ks;
    newForm.bpjs.akhdk = akdhk;
    newForm.bpjs.akhdkOn = akdhkOn;
    newForm.bpjs.cutOffBPJSTKO = cutOffBPJSTKO;
    newForm.bpjs.cutOffNominal = cutOffNominal;
    setForm(newForm);
    setTrigger(Math.random());
  }, [
    form,
    setForm,
    registration,
    paymentProof,
    jkk,
    jkm,
    jht,
    jp,
    ks,
    akdhk,
    cutOffBPJSTKO,
    setTrigger,
    cutOffNominal,
    jkkOn,
    jkmOn,
    jhtOn,
    jpOn,
    ksOn,
    akdhkOn,
  ]);

  const handleRadioChange = (event) => {
    setSelectedValueBPU(event.target.value);
  };

  useEffect(() => {
    if (selectedButtonBPJS === "Tenaga Kerja") {
      setSelectedValueBPU("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedButtonBPJS]);

  // console.log(alertMessage);

  return (
    <div className={Styles.container}>
      <h3>BPJS</h3>
      {optionalBPJS && (
        <div className={Styles.oCard}>
          <span>Gunakan skema BPJS untuk FLB ini</span>
          <Switch
            isChecked={withBPJS}
            setIsChecked={setWithBPJS}
            height="28px"
            width="56px"
          />
        </div>
      )}

      {withBPJS && (
        <div className={Styles.content}>
          <div className={Styles.top}>
            <div className={Styles.selectWrapper} id="Pendaftaran BPJS">
              <SelectOptionsToggle
                title="Pendaftaran BPJS"
                options={options?.registration}
                placeholder={"Select"}
                value={registration}
                setValue={setRegistration}
                isError={
                  alertMessage?.length > 0 &&
                  alertMessage?.find(
                    (el) =>
                      el?.remark === "Pendaftaran BPJS" &&
                      el?.validatePendaftaranBPJS === true
                  ) &&
                  !registration
                }
                errorText="Pendaftaran BPJS belum dipilih"
                autoClose={true}
                isErrorV2={true}
                required
              />
            </div>
            <div className={Styles.selectWrapper} id="Bukti Pembayaran BPJS">
              <SelectOptionsToggle
                title="Bukti Pembayaran BPJS"
                options={options?.paymentProof}
                placeholder={"Select"}
                value={paymentProof}
                setValue={setPaymentProof}
                isError={
                  alertMessage?.length > 0 &&
                  alertMessage?.find(
                    (el) =>
                      el?.remark === "Bukti Pembayaran BPJS" &&
                      el?.validateBuktiPembayaranBPJS === true
                  ) &&
                  !paymentProof
                }
                errorText="Bukti Pembayaran BPJS belum dipilih"
                autoClose={true}
                isErrorV2={true}
                required
              />
            </div>
            {/* <div className={Styles.selectWrapper}>
              <SelectOptionsToggle
                title="Potongan BPJS TKO"
                options={options?.cutOffBPJSTKO}
                placeholder={"Select"}
                value={cutOffBPJSTKO}
                setValue={setCutOffBPJSTKO}
                isError={alertMessage && !cutOffBPJSTKO}
                errorText="Potongan BPJS TKO belum dipilih"
                autoClose={true}
                required
              />
            </div> */}
            <div className={Styles.cutOffSelector}>
              <InputTitle title={"Perhitungan Potongan BPJS TKO"} />
              <div
                className={Styles.cutOffOptions}
                id="Perhitungan Potongan BPJS TKO"
              >
                {options?.cutOffBPJSTKO?.map((c, cI) => (
                  <div
                    key={cI}
                    className={`${
                      cutOffBPJSTKO === c?.name ? Styles.active : ""
                    } ${
                      alertMessage?.length > 0 &&
                      alertMessage?.find(
                        (el) =>
                          el?.remark === "Perhitungan Potongan BPJS TKO" &&
                          el?.validatePerhitunganPotonganBPJSTKO === true
                      ) &&
                      !cutOffBPJSTKO
                        ? Styles.error
                        : ""
                    }`}
                    onClick={() => setCutOffBPJSTKO(c?.name)}
                  >
                    {c?.name}
                  </div>
                ))}
              </div>
              {alertMessage?.length > 0 &&
                alertMessage?.find(
                  (el) =>
                    el?.remark === "Perhitungan Potongan BPJS TKO" &&
                    el?.validatePerhitunganPotonganBPJSTKO === true
                ) &&
                !cutOffBPJSTKO && (
                  <ErrorBubble errorText="Perhitungan potongan BPJS TKO belum dipilih" />
                )}
            </div>
            {cutOffBPJSTKO === "Manual" && (
              <div id="Perhitungan Potongan BPJS TKO Manual">
                <DinamicDecorativeInputField
                  onChange={(_, newVal) => setCutOffNominal(newVal)}
                  name={""}
                  value={cutOffNominal || ""}
                  title={"Manual"}
                  adornment="Rp"
                  type="currency"
                  preventDelete={true}
                  isError={
                    alertMessage?.length > 0 &&
                    alertMessage?.find(
                      (el) =>
                        el?.remark === "Perhitungan Potongan BPJS TKO Manual" &&
                        el?.validatePerhitunganPotonganBPJSTKO === true
                    ) &&
                    cutOffBPJSTKO === "Manual" &&
                    !cutOffNominal
                  }
                  errorText="Perhitungan Potongan BPJS TKO belum diisi"
                  isErrorV2={true}
                />
              </div>
            )}
          </div>
          <div className={Styles.middle}>
            <div className={Styles.buttonOption}>
              <div
                className={`${Styles.buttonLeft} ${
                  selectedButtonBPJS === "Tenaga Kerja" && Styles.buttonActive
                }`}
                onClick={() => setActiveButtonBPJS("Tenaga Kerja")}
              >
                Penerima Upah
              </div>
              <div
                className={`${Styles.buttonRight} ${
                  selectedButtonBPJS === "BPU" && Styles.buttonActive
                } ${
                  jkk ||
                  jkkOn ||
                  jkm ||
                  jkmOn ||
                  jht ||
                  jhtOn ||
                  jp ||
                  jpOn ||
                  form?.tkoContract?.includes("PKWT")
                    ? Styles.disabled
                    : ""
                }`}
                onClick={() => {
                  if (
                    !jkk &&
                    !jkkOn &&
                    !jkm &&
                    !jkmOn &&
                    !jht &&
                    !jhtOn &&
                    !jp &&
                    !jpOn &&
                    !form?.tkoContract?.includes("PKWT")
                  ) {
                    setActiveButtonBPJS("BPU");
                  }
                }}
              >
                Bukan Penerima Upah
              </div>
            </div>
            {selectedButtonBPJS === "Tenaga Kerja" && (
              <div>
                {" "}
                <h4>Ketenagarjaan</h4>
                <div className={Styles.subContent}>
                  <div
                    className={Styles.selectSwitchWrapper}
                    id="JKK (Jaminan Kelengkapan Kerja)"
                  >
                    <SelectOptionsToggle
                      title="JKK (Jaminan Kelengkapan Kerja)"
                      options={options?.jkk}
                      placeholder={"Select"}
                      value={jkk}
                      setValue={setJkk}
                      withToggle={true}
                      toggleValue={jkkOn}
                      setToggleValue={setJkkOn}
                      autoClose={true}
                      isError={
                        alertMessage?.length > 0 &&
                        alertMessage?.find(
                          (el) =>
                            el?.remark === "JKK (Jaminan Kelengkapan Kerja)" &&
                            el?.validateJKK === true
                        ) &&
                        jkkOn &&
                        !jkk
                      }
                      isErrorV2={true}
                      errorText="JKK belum dipilih"
                    />
                  </div>
                  <div
                    className={Styles.selectSwitchWrapper}
                    id="JKM (Jaminan Kematian)"
                  >
                    <SelectOptionsToggle
                      title="JKM (Jaminan Kematian)"
                      options={options?.jkm}
                      placeholder={"Select"}
                      value={jkm}
                      setValue={setJkm}
                      toggleValue={jkmOn}
                      setToggleValue={setJkmOn}
                      withToggle={true}
                      autoClose={true}
                      isError={
                        alertMessage?.length > 0 &&
                        alertMessage?.find(
                          (el) =>
                            el?.remark === "JKM (Jaminan Kematian)" &&
                            el?.validateJKM === true
                        ) &&
                        jkmOn &&
                        !jkm
                      }
                      isErrorV2={true}
                      errorText="JKM belum dipilih"
                    />
                  </div>
                  <div
                    className={Styles.selectSwitchWrapper}
                    id="JHT (Jaminan Hari Tua)"
                  >
                    <SelectOptionsToggle
                      title="JHT (Jaminan Hari Tua)"
                      options={options?.jht}
                      placeholder={"Select"}
                      value={jht}
                      setValue={setJht}
                      toggleValue={jhtOn}
                      setToggleValue={setJhtOn}
                      withToggle={true}
                      autoClose={true}
                      isError={
                        alertMessage?.length > 0 &&
                        alertMessage?.find(
                          (el) =>
                            el?.remark === "JHT (Jaminan Hari Tua)" &&
                            el?.validateJHT === true
                        ) &&
                        jhtOn &&
                        !jht
                      }
                      isErrorV2={true}
                      errorText="JHT belum dipilih"
                    />
                  </div>
                  <div
                    className={Styles.selectSwitchWrapper}
                    id="JP (Jaminan Pensiun)"
                  >
                    <SelectOptionsToggle
                      title="JP (Jaminan Pensiun)"
                      options={options?.jp}
                      placeholder={"Select"}
                      value={jp}
                      setValue={setJp}
                      toggleValue={jpOn}
                      setToggleValue={setJpOn}
                      withToggle={true}
                      autoClose={true}
                      isError={
                        alertMessage?.length > 0 &&
                        alertMessage?.find(
                          (el) =>
                            el?.remark === "JP (Jaminan Pensiun)" &&
                            el?.validateJP === true
                        ) &&
                        jpOn &&
                        !jp
                      }
                      isErrorV2={true}
                      errorText="JP belum dipilih"
                    />
                  </div>
                </div>
              </div>
            )}
            {selectedButtonBPJS === "BPU" && (
              <div className={Styles.bpuContent}>
                <span className={Styles.warnText}>
                  Silahkan pilih penagihan BPJS akan ditagih ke pihak perusahaan
                  atau karyawan. Jumlah perhitungan akan di input pada step ke-4
                  saat pengaturan gaji man power.
                </span>
                <div className={Styles.radioInputBox} id="Penagihan BPJS">
                  {optionsBPU?.map((bpu, idx) => {
                    return (
                      <div className={Styles.radio} key={idx}>
                        <input
                          type="radio"
                          value={bpu?.name}
                          checked={selectedValueBPU === bpu?.name}
                          onChange={handleRadioChange}
                        />
                        <label>{bpu?.name || "-"}</label>
                      </div>
                    );
                  })}
                </div>
                {selectedButtonBPJS === "BPU" &&
                  !selectedValueBPU &&
                  alertMessage?.length > 0 &&
                  alertMessage?.find(
                    (el) =>
                      el?.remark === "Penagihan BPJS" &&
                      el?.validatePenagihanBPJS === true
                  ) && (
                    <div>
                      <ErrorBubble errorText={"Penagihan BPJS belum dipilih"} />
                    </div>
                  )}
              </div>
            )}
          </div>
          <div className={Styles.bottom}>
            <div className={Styles.boxWrapper}>
              <h4>Kesehatan</h4>
              <div className={Styles.selectSwitchWrapper} id="KS (kesehatan)">
                <SelectOptionsToggle
                  title="KS (kesehatan)"
                  options={options?.health}
                  placeholder={"Select"}
                  value={ks}
                  setValue={setKs}
                  withToggle={true}
                  toggleValue={ksOn}
                  setToggleValue={setKsOn}
                  autoClose={true}
                  isError={
                    alertMessage?.length > 0 &&
                    alertMessage?.find(
                      (el) =>
                        el?.remark === "KS (kesehatan)" &&
                        el?.validateKS === true
                    ) &&
                    ksOn &&
                    !ks
                  }
                  isErrorV2={true}
                  errorText="KS belum dipilih"
                />
              </div>
            </div>
            <div className={Styles.boxWrapper}>
              <h4>AKDHK</h4>
              <div className={Styles.selectSwitchWrapper} id="AKDHK">
                <SelectOptionsToggle
                  title="Asuransi Kecelakaan Diluar Hari Kerja"
                  options={options?.akdhk}
                  placeholder={"Select"}
                  value={akdhk}
                  setValue={setAkdhk}
                  toggleValue={akdhkOn}
                  setToggleValue={setAkdhkOn}
                  withToggle={true}
                  autoClose={true}
                  isError={
                    alertMessage?.length > 0 &&
                    alertMessage?.find(
                      (el) =>
                        el?.remark === "AKDHK" && el?.validateAKDHK === true
                    ) &&
                    akdhkOn &&
                    !akdhk
                  }
                  isErrorV2={true}
                  errorText="AKDHK belum dipilih"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
