/* eslint-disable no-unused-vars */
import { useEffect, useRef } from "react";
import AccountSection from "./AccountSection";
import ContactsSection from "./ContactsSection";
import FlowSection from "./FlowSection";
import OthersSection from "./OthersSection";
import Styles from "./style.module.scss";

export default function ThirdStep(props) {
  const {
    form,
    setForm,
    files,
    setFiles,
    // onPrevious,
    // onNext,
    setTrigger,
    alertMessage = [],
    clientContacts = [],
  } = props;

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      {/* <PeriodSection
        setTrigger={setTrigger}
        form={form}
        setForm={setForm}
        alertMessage={alertMessage}
      /> */}
      <ContactsSection
        setTrigger={setTrigger}
        form={form}
        setForm={setForm}
        alertMessage={alertMessage}
        clientContacts={clientContacts}
      />
      <FlowSection
        setTrigger={setTrigger}
        form={form}
        setForm={setForm}
        alertMessage={alertMessage}
      />
      <AccountSection
        setTrigger={setTrigger}
        form={form}
        setForm={setForm}
        alertMessage={alertMessage}
      />
      <OthersSection
        setTrigger={setTrigger}
        form={form}
        setForm={setForm}
        alertMessage={alertMessage}
      />
      {/* <FileUploadSection
        form={form}
        setForm={setForm}
        files={files}
        setFiles={setFiles}
      /> */}
      {/* <div className={Styles.buttonWrapper}>
        <button onClick={onPrevious}>Back</button>
        <button onClick={onNext}>
          Next Step
        </button>
      </div> */}
    </div>
  );
}
