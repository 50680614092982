import UseOutsideClick from "@Hooks/useOutsideClick";
import Styles from "./styles.module.scss";
import { useEffect, useRef } from "react";
import Icon from "@Atom/Icon";

export default function SideBarErrors({
  handleClose,
  data,
  setActiveCityID,
  activePositionID,
  setActivePositionID,
  activeExactPositionID,
  setActiveExactPositionID,
  positionsForm,
  setPositionsForm,
}) {
  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  useEffect(() => {
    if (boxOutsideClick) {
      handleClose();
    }
  }, [boxOutsideClick, handleClose]);

  return (
    <div className={Styles.body}>
      <div ref={boxRef}>
        <div className={Styles.header}>
          <span>Daftar Data Kosong</span>
          <div onClick={handleClose}>
            <Icon icon={"cross"} size={16} />
          </div>
        </div>
        <div className={Styles.content}>
          <span>
            Terdapat {data?.length || 0} data kosong yang perlu kamu lengkapi:
          </span>

          <div className={Styles.listBox}>
            {data?.map((err, idx) => {
              return (
                <div
                  key={idx}
                  onClick={() => {
                    if (
                      err?.name?.includes("Data Business Manager") ||
                      err?.name?.includes("Data Recruitment Officer")
                    ) {
                      setActiveCityID(err?.cityCode);
                    }

                    if (
                      err?.name?.includes("Belum ada posisi diaktifkan") ||
                      err?.name?.includes("Tempo salary") ||
                      err?.name?.includes("Nominal salary") ||
                      err?.name?.includes("Expected fulfillment date") ||
                      err?.name?.includes("Personal Equipment") ||
                      err?.name?.includes("Allowances")
                    ) {
                      setActivePositionID(err?.positionId);
                      setActiveExactPositionID(
                        err?.positionId + "-" + err?.cityId
                      );
                      setPositionsForm(
                        positionsForm?.map((obj) =>
                          obj?.id === err?.positionId
                            ? {
                                ...obj,
                                isExpanded: true,
                              }
                            : {
                                ...obj,
                                isExpanded: false,
                              }
                        )
                      );
                    }

                    document.getElementById(`${err?.remark}`).scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "start",
                    });
                    handleClose();
                  }}
                >
                  <div className={Styles.icon}>
                    <Icon icon={"arrow-right"} size={20} color={"#fff"} />
                  </div>

                  <span>{err?.name || "-"}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
