import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import ErrorBubble from "@Atom/ErrorBubble";
import { makeRandomString } from "@Helpers/makeRandomString";
import { useEffect, useState } from "react";
import Styles from "./style.module.scss";

export default function TKOContractSection(props) {
  const { form, setForm, setTrigger, alertMessage = [] } = props;
  const [tkoContract, setTkoContract] = useState(form?.tkoContract);

  useEffect(() => {
    const newForm = form;
    newForm.tkoContract = tkoContract;
    setForm(newForm);
    setTrigger(makeRandomString(5));
  }, [form, tkoContract, setForm, setTrigger]);

  return (
    <div className={Styles.container}>
      <h3>Kontrak TKO</h3>
      <div className={Styles.content} id="Kontrak TKO">
        <div
          className={
            alertMessage?.length > 0 &&
            alertMessage?.find(
              (el) =>
                el?.remark === "Kontrak TKO" && el?.validateTkoContract === true
            ) &&
            !tkoContract
              ? Styles.error
              : ""
          }
          onClick={() => setTkoContract("PKWT")}
        >
          <CheckboxJobPosting
            type="radio-section"
            checked={tkoContract === "PKWT"}
          />
          <span>PKWT (Perjanjian Kerja Waktu Tertentu)</span>
        </div>
        <div
          className={
            alertMessage?.length > 0 &&
            alertMessage?.find(
              (el) =>
                el?.remark === "Kontrak TKO" && el?.validateTkoContract === true
            ) &&
            !tkoContract
              ? Styles.error
              : ""
          }
          onClick={() => setTkoContract("PKM")}
        >
          <CheckboxJobPosting
            type="radio-section"
            checked={tkoContract === "PKM"}
          />
          <span>PKM (Perjanjian Kemitraan)</span>
        </div>
      </div>
      {alertMessage?.length > 0 &&
        alertMessage?.find(
          (el) =>
            el?.remark === "Kontrak TKO" && el?.validateTkoContract === true
        ) &&
        !tkoContract && <ErrorBubble errorText={"Kontrak TKO belum dipilih"} />}
    </div>
  );
}
