import Icon from "@Atom/Icon";
import { makeRandomString } from "@Helpers/makeRandomString";
import useQuery from "@Hooks/useQuery";
import { editOrderAndSendFLB } from "@Services/manager/client";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";
import { getPipelineOrderDetails } from "@Services/manager/pipeline";
import { BPURangeSalary } from "@Helpers/BPURangeSalary";
import debounce from "lodash.debounce";
import DeploymentStep from "../../AddOrder/Deployment";
import PositionStep from "../../AddOrder/Position";
import useWindowSize from "@Hooks/useWindowSize";
import LoadingAnimationPage from "@Organism/LoadingAnimationPage";
import SideBarErrors from "@Pages/Auth/Manager/Client/AddNewOrder/SideBarErrors";
import SendFLBModal from "@Molecule/_modal/SendFLBModal";
import ErrorModal from "@Molecule/_modal/ErrorModal";
// import SchemeDetailModal from "@Molecule/_modal/SchemeDetailModal";

export default function SchemeProceedFormLayout({
  syncData,
  defaultOrderForm,
  // steps={stepsOptions}
  // setSteps={setStepsOptions}
  currentStep,
  setCurrentStep,
  headerHeight = 0,
  previousButtonText = "",
  nextButtonText = "",
  activeCityID,
  setActiveCityID,
  activePositionID,
  setActivePositionID,
  activeExactPositionID,
  setActiveExactPositionID,
  positionsForm,
  setPositionsForm,
  showSideBar,
  setShowSideBar,
  alertMessage = [],
  setAlertMessage = () => {},
  steps,
  setSteps,
  setDefaultFourth,
  defaultFourth,
  setViewDetails,
}) {
  const [trigger, setTrigger] = useState(null);
  const navigate = useNavigate();
  const query = useQuery();
  const [isSuccess, setIsSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const [isLoadingFifthNew, setIsLoadingFifthNew] = useState(
    sessionStorage?.getItem("isLoadingFifth2Proceed") === "NO" ? false : true
  );

  const [isLoadingFourth, setIsLoadingFourth] = useState(
    sessionStorage?.getItem("isLoadingFourthProceed") === "NO" ? false : true
  );

  const [defaultFifthNew, setDefaultFifthNew] = useState(
    sessionStorage.getItem("defaultFifthNewProceed")
      ? JSON?.parse(sessionStorage?.getItem("defaultFifthNewProceed"))
      : null
  );

  const activeState = useMemo(() => {
    switch (+currentStep) {
      case 1:
        return {
          isLoading: isLoadingFourth,
          setIsLoading: setIsLoadingFourth,
          defaultDetails: defaultFourth,
          setDefaultDetails: setDefaultFourth,
          step: "four",
        };
      case 2:
        return {
          isLoading: isLoadingFifthNew,
          setIsLoading: setIsLoadingFifthNew,
          defaultDetails: defaultFifthNew,
          setDefaultDetails: setDefaultFifthNew,
          step: "four",
        };
      default:
        return {
          isLoading: isLoadingFourth,
          setIsLoading: setIsLoadingFourth,
          defaultDetails: defaultFourth,
          setDefaultDetails: setDefaultFourth,
          step: "four",
        };
    }
  }, [
    currentStep,
    defaultFifthNew,
    defaultFourth,
    isLoadingFifthNew,
    isLoadingFourth,
    setDefaultFourth,
  ]);

  const fetchDetail = useCallback(async () => {
    if (activeState?.isLoading) {
      try {
        const { response } = await getPipelineOrderDetails(
          query.get("i"),
          activeState?.step
        );
        activeState?.setDefaultDetails(response);
        activeState?.setIsLoading(false);
        setTrigger(makeRandomString(5));
      } catch (err) {
        console.log(err, "ini err");
      }
    }
  }, [activeState, query]);

  useEffect(() => {
    fetchDetail();
  }, [fetchDetail]);

  const clientCode = query.get("c");

  const defaultManPowerForm = useMemo(() => {
    const arr = [];
    const manPowerDefaults = defaultOrderForm?.manPowers;
    const cities = syncData?.cities;

    for (let i = 0; i < manPowerDefaults?.length; i++) {
      const perLocation = manPowerDefaults[i];
      const city = cities?.find((c) => c?.code === perLocation?.cityCode);
      const areaEquipments = perLocation?.areaEquipments?.map((obj) => {
        return {
          id: obj?.id,
          name: obj?.name,
          value: obj?.price,
        };
      });
      let each = {
        id: perLocation?.cityCode,
        location: perLocation?.cityCode,
        umk: city?.umk || 0,
        jobs: [],
        areaWorkEquipments: areaEquipments || [],
      };
      const jobArr = [];
      for (let j = 0; j < perLocation?.manPowers?.length; j++) {
        const job = perLocation?.manPowers[j];
        const allowances = job?.allowances?.map((obj) => {
          return {
            id: obj?.id,
            name: obj?.name,
            value: obj?.value,
            type: obj?.type || "",
          };
        });
        const personalEquipments = job?.personalEquipments?.map((obj) => {
          return {
            id: obj?.id,
            name: obj?.name,
            value: obj?.price,
          };
        });
        const qualifications = job?.qualifications?.map((obj) => {
          return {
            id: obj?.id,
            name: obj?.name,
            value: obj?.value,
          };
        });
        const eachJob = {
          id: job?.id,
          title: job?.name,
          contractType: job?.contractType || "",
          salary: `${job?.salary}`?.split("::")[0] || "",
          salaryPeriod: `${job?.salary}`?.split("::")[1] || "",
          personalEquipments: personalEquipments || [],
          allowances: allowances || [],
          demand: +job?.amount,
          fulfillmentDate: job?.expectedFulfillmentDate || "",
          qualifications: qualifications || [],
        };
        jobArr.push(eachJob);
      }
      each.jobs = jobArr;
      arr.push(each);
    }

    return arr;
  }, [syncData, defaultOrderForm]);

  const defaultDeploymentsData = useMemo(() => {
    return [
      {
        role: "BUSINESS MANAGER",
        required: true,
        delegations: syncData?.users?.businessManagers?.map((obj) => {
          return {
            id: obj?.employeeId,
            name: obj?.name,
            profilePicture: "",
            isPicked: false,
            isPIC: false,
          };
        }),
      },
      {
        role: "ACCOUNT MANAGER / ACCOUNT OFFICER",
        delegations: syncData?.users?.accountManagerOrPIC?.map((obj) => {
          return {
            id: obj?.employeeId,
            name: obj?.name,
            profilePicture: "",
            isPicked: false,
            isPIC: false,
          };
        }),
      },
      {
        role: "ADMIN / ADMIN CONTRACT",
        delegations: syncData?.users?.accountOfficerOrAdmin?.map((obj) => {
          return {
            id: obj?.employeeId,
            name: obj?.name,
            profilePicture: "",
            isPicked: false,
            isPIC: false,
          };
        }),
      },
      {
        role: "RECRUITMENT OFFICER",
        delegations: syncData?.users?.recruitmentOfficers?.map((obj) => {
          return {
            id: obj?.employeeId,
            name: obj?.name,
            profilePicture: "",
            isPicked: false,
            isPIC: false,
          };
        }),
      },
    ];
  }, [
    syncData?.users?.accountManagerOrPIC,
    syncData?.users?.accountOfficerOrAdmin,
    syncData?.users?.businessManagers,
    syncData?.users?.recruitmentOfficers,
  ]);

  const getLocationUMK = useCallback(
    (id) => {
      return syncData?.cities?.find((obj) => obj?.code === id)?.umk || 4500000;
    },
    [syncData?.cities]
  );

  const getDenom = useCallback(
    (name) => {
      return (
        syncData?.qualifications?.find((obj) => obj?.value === name)?.denom ||
        "Bulanan"
      );
    },
    [syncData?.qualifications]
  );

  const [selectedValueBPU, setSelectedValueBPU] = useState("");

  useEffect(() => {
    if (defaultFourth) {
      setSelectedValueBPU(defaultFourth?.workScheme?.PenagihanBPU || "");
    }
  }, [defaultFourth]);

  const [selectedButtonBPJS, setSelectedButtonBPJS] = useState("");

  useEffect(() => {
    if (defaultFourth) {
      setSelectedButtonBPJS(
        defaultFourth?.workScheme?.PenagihanBPU === "" ||
          defaultFourth?.workScheme?.PenagihanBPU === null
          ? "Tenaga Kerja"
          : "BPU"
      );
    }
  }, [defaultFourth]);

  const defaultManPowerFormArr = useMemo(() => {
    return defaultOrderForm?.manPowers?.map((obj) => {
      const locationUsers = obj?.delegations;
      const ddData = [
        {
          role: "BUSINESS MANAGER",
          delegations: syncData?.users?.businessManagers?.map((su) => {
            const found = locationUsers?.find(
              (u) => u?.employeeId === su?.employeeId
            );
            return {
              delegationID: found?.delegationID || "",
              id: su?.employeeId,
              name: su?.name,
              profilePicture: "",
              isPicked: !!found,
              isPIC: found?.isPIC || false,
            };
          }),
        },
        {
          role: "ACCOUNT MANAGER",
          delegations: syncData?.users?.accountManagerOrPIC?.map((su) => {
            const found = locationUsers?.find(
              (u) => u?.employeeId === su?.employeeId
            );
            return {
              delegationID: found?.delegationID || "",
              id: su?.employeeId,
              name: su?.name,
              profilePicture: "",
              isPicked: !!found,
              isPIC: found?.isPIC || false,
            };
          }),
        },
        {
          role: "ACCOUNT OFFICER / ADMIN",
          delegations: syncData?.users?.accountOfficerOrAdmin?.map((su) => {
            const found = locationUsers?.find(
              (u) => u?.employeeId === su?.employeeId
            );
            return {
              delegationID: found?.delegationID || "",
              id: su?.employeeId,
              name: su?.name,
              profilePicture: "",
              isPicked: !!found,
              isPIC: found?.isPIC || false,
            };
          }),
        },
        {
          role: "RECRUITMENT OFFICER",
          delegations: syncData?.users?.recruitmentOfficers?.map((su) => {
            const found = locationUsers?.find(
              (u) => u?.employeeId === su?.employeeId
            );
            return {
              delegationID: found?.delegationID || "",
              id: su?.employeeId,
              name: su?.name,
              profilePicture: "",
              isPicked: !!found,
              isPIC: found?.isPIC || false,
            };
          }),
        },
      ];
      return {
        cityCode: obj?.cityCode,
        locationID: obj?.cityCode,
        locationName: obj?.cityName,
        locationUMK: getLocationUMK(obj?.cityCode),
        deployments: ddData,
        positions: obj?.manPowers?.map((p) => {
          return {
            positionID: p?.id,
            id: p?.id,
            title: p?.name,
            amount: p?.amount,
            contractType: p?.contractType,
            salaryValue: p?.salary?.includes("::")
              ? p?.salary?.split("::")[0]
              : p?.salary,
            salaryDenom: p?.salary?.includes("::")
              ? p?.salary?.split("::")[1]
              : "Bulanan",
            personalEquipments: p?.personalEquipments?.map((pe) => {
              return {
                id: pe?.id,
                equipmentID: pe?.id,
                positionID: pe?.positionID,
                schemeID: pe?.schemeID,
                name: pe?.name,
                value: pe?.price,
              };
            }),
            allowances: p?.allowances?.map((a) => {
              return {
                id: a?.id,
                type: a?.type,
                allowanceID: a?.id,
                positionID: a?.positionID,
                name: a?.name,
                value: a?.value,
              };
            }),
            expectedDate: new Date(p?.expectedFulfillmentDate),
            qualifications: p?.qualifications?.map((q) => {
              return {
                id: q?.id,
                qualificationID: q?.id,
                positionID: q?.positionID,
                name: q?.name,
                value: q?.value,
                denom: getDenom(q?.name),
              };
            }),
          };
        }),
        areaEquipments: obj?.areaEquipments?.map((eq) => {
          return {
            id: eq?.id,
            equipmentID: eq?.id,
            schemeID: eq?.schemeID,
            name: eq?.name,
            value: eq?.price,
          };
        }),
      };
    });
  }, [
    defaultOrderForm?.manPowers,
    getDenom,
    getLocationUMK,
    syncData?.users?.accountManagerOrPIC,
    syncData?.users?.accountOfficerOrAdmin,
    syncData?.users?.businessManagers,
    syncData?.users?.recruitmentOfficers,
  ]);

  const [manPowerFormArr, setManPowerFormArr] = useState(
    defaultManPowerFormArr || []
  );

  const defaultDelegationsForm = useMemo(() => {
    const parsedForm = sessionStorage?.getItem("delegationsFormEditProceed")
      ? JSON.parse(sessionStorage?.getItem("delegationsFormEditProceed"))
      : [];

    return parsedForm?.length
      ? parsedForm
      : defaultFourth?.position?.deployment?.map((obj) => {
          const users = obj?.users;
          const sorted = [
            users?.businessManagers,
            users?.accountManagerOrPIC,
            users?.accountOfficerOrAdmin,
            users?.recruitmentOfficers,
          ];
          return {
            id: makeRandomString(5),
            preventDelete: true,
            cityCode: obj?.cityCode,
            cityName: obj?.cityName,
            umk: syncData?.cities?.find((c) => c?.code === obj?.cityCode)?.umk,
            deployments: defaultDeploymentsData?.map((d, dI) => {
              return {
                ...d,
                delegations: d?.delegations?.map((de) => {
                  return {
                    ...de,
                    delegationID: sorted[dI]?.find(
                      (s) => s?.employeeId === de?.id
                    )?.id,
                    isPicked: sorted[dI]?.find((s) => s?.employeeId === de?.id)
                      ? true
                      : false,
                    isPIC: sorted[dI]?.find((s) => s?.employeeId === de?.id)
                      ?.isPIC
                      ? true
                      : false,
                  };
                }),
              };
            }),
          };
        });
  }, [
    defaultDeploymentsData,
    defaultFourth?.position?.deployment,
    syncData?.cities,
  ]);

  const defaultPositionsForm = useMemo(() => {
    const parsedForm = sessionStorage?.getItem("positionsFormEditProceed")
      ? JSON.parse(sessionStorage?.getItem("positionsFormEditProceed"))
      : [];
    return parsedForm?.length
      ? parsedForm
      : defaultFifthNew?.position?.list?.map((obj) => {
          return {
            id: makeRandomString(5),
            preventDelete: true,
            search: "",
            isExpanded: false,
            positionName: obj?.position,
            dedicatedROName: obj?.dedicatedRO?.name,
            dedicatedROId: obj?.dedicatedRO?.employeeId || "",
            qualifications:
              obj?.qualification?.map((q) => {
                return {
                  id: q?.id,
                  qualificationID: q?.id,
                  name: q?.name,
                  value: q?.value,
                  denom: syncData?.qualifications?.find(
                    (o) => o?.value === q?.name
                  )?.denom,
                };
              }) || [],
            citiesData: defaultDelegationsForm?.map((d) => {
              const found = obj?.cities?.find(
                (ci) => ci?.position?.cityCode === d?.cityCode
              );
              const salaryJobs = found
                ? found?.position?.salary?.includes("::")
                  ? found?.position?.salary?.split("::")[0]
                  : found?.position?.salary
                : syncData?.cities?.find((sc) => sc?.code === d?.cityCode)
                    ?.umk || 4500000;

              const { jkk, jkm, jht } = BPURangeSalary(
                salaryJobs,
                found
                  ? found?.position?.salary?.includes("::")
                    ? found?.position?.salary?.split("::")[1]
                    : "Bulanan"
                  : "Bulanan"
              );
              return {
                id: found?.position?.id || d?.cityName || makeRandomString(5),
                positionID: found?.position?.id || "",
                preventDelete: true,
                preventEdit: true,
                isApplied: found ? true : false,
                cityCode: d?.cityCode,
                cityName: found?.cityName || d?.cityName,
                umk: syncData?.cities?.find((sc) => sc?.code === d?.cityCode)
                  ?.umk,
                amount: found?.position?.amount || 1,
                salaryValue: salaryJobs,
                salaryDenom: found
                  ? found?.position?.salary?.includes("::")
                    ? found?.position?.salary?.split("::")[1]
                    : "Bulanan"
                  : "Bulanan",
                personalEquipments: found
                  ? found?.position?.personalEquipments?.map((p) => {
                      return {
                        equipmentID: p?.id,
                        name: p?.name,
                        value: p?.price,
                      };
                    })
                  : [],
                allowances: found
                  ? found?.position?.allowances
                      ?.filter((a) => a?.type !== "BPU")
                      ?.map((a) => {
                        return {
                          allowanceID: a?.id,
                          name: a?.name,
                          value: a?.value,
                          denom: a?.denom ? a?.denom : "Bulanan",
                        };
                      })
                  : [],
                jkk: found
                  ? selectedValueBPU?.includes("Tagih Ke Perusahaan")
                    ? found?.position?.allowances?.find(
                        (a) =>
                          a?.name === "JKK" && a?.billingTo === "Perusahaan"
                      )?.value || "0"
                    : found?.position?.allowances?.find(
                        (a) => a?.name === "JKK" && a?.billingTo === "Karyawan"
                      )?.value || "0"
                  : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                  ? salaryJobs
                    ? `${+jkk / 2}`
                    : "0"
                  : salaryJobs
                  ? jkk
                  : "0",

                jkkID: selectedValueBPU?.includes("Tagih Ke Perusahaan")
                  ? found?.position?.allowances?.find(
                      (a) => a?.name === "JKK" && a?.billingTo === "Perusahaan"
                    )?.id || ""
                  : found?.position?.allowances?.find(
                      (a) => a?.name === "JKK" && a?.billingTo === "Karyawan"
                    )?.id || "",

                jkm: found
                  ? selectedValueBPU?.includes("Tagih Ke Perusahaan")
                    ? found?.position?.allowances?.find(
                        (a) =>
                          a?.name === "JKM" && a?.billingTo === "Perusahaan"
                      )?.value || "0"
                    : found?.position?.allowances?.find(
                        (a) => a?.name === "JKM" && a?.billingTo === "Karyawan"
                      )?.value || "0"
                  : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                  ? salaryJobs
                    ? `${+jkm / 2}`
                    : "0"
                  : salaryJobs
                  ? jkm
                  : "0",

                jkmID: selectedValueBPU?.includes("Tagih Ke Perusahaan")
                  ? found?.position?.allowances?.find(
                      (a) => a?.name === "JKM" && a?.billingTo === "Perusahaan"
                    )?.id || ""
                  : found?.position?.allowances?.find(
                      (a) => a?.name === "JKM" && a?.billingTo === "Karyawan"
                    )?.id || "",

                jht: found
                  ? selectedValueBPU?.includes("Tagih Ke Perusahaan")
                    ? found?.position?.allowances?.find(
                        (a) =>
                          a?.name === "JHT" && a?.billingTo === "Perusahaan"
                      )?.value || "0"
                    : found?.position?.allowances?.find(
                        (a) => a?.name === "JHT" && a?.billingTo === "Karyawan"
                      )?.value || "0"
                  : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                  ? salaryJobs
                    ? `${+jht / 2}`
                    : "0"
                  : salaryJobs
                  ? jht
                  : "0",

                jhtID: selectedValueBPU?.includes("Tagih Ke Perusahaan")
                  ? found?.position?.allowances?.find(
                      (a) => a?.name === "JHT" && a?.billingTo === "Perusahaan"
                    )?.id || ""
                  : found?.position?.allowances?.find(
                      (a) => a?.name === "JHT" && a?.billingTo === "Karyawan"
                    )?.id || "",

                jkk1: found
                  ? found?.position?.allowances?.find(
                      (a) => a?.name === "JKK" && a?.billingTo === "Karyawan"
                    )?.value || "0"
                  : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                  ? salaryJobs
                    ? `${+jkk / 2}`
                    : "0"
                  : "0",
                jkkID1:
                  found?.position?.allowances?.find(
                    (a) => a?.name === "JKK" && a?.billingTo === "Karyawan"
                  )?.id || "",
                jkm1: found
                  ? found?.position?.allowances?.find(
                      (a) => a?.name === "JKM" && a?.billingTo === "Karyawan"
                    )?.value || "0"
                  : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                  ? salaryJobs
                    ? `${+jkm / 2}`
                    : "0"
                  : "0",
                jkmID1:
                  found?.position?.allowances?.find(
                    (a) => a?.name === "JKM" && a?.billingTo === "Karyawan"
                  )?.id || "",
                jht1: found
                  ? found?.position?.allowances?.find(
                      (a) => a?.name === "JHT" && a?.billingTo === "Karyawan"
                    )?.value || "0"
                  : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                  ? salaryJobs
                    ? `${+jht / 2}`
                    : "0"
                  : "0",
                jhtID1:
                  found?.position?.allowances?.find(
                    (a) => a?.name === "JHT" && a?.billingTo === "Karyawan"
                  )?.id || "",

                isJkkManual: found ? true : false,
                isJkmManual: found ? true : false,
                isJhtManual: found ? true : false,
                isJkk1Manual: found ? true : false,
                isJkm1Manual: found ? true : false,
                isJht1Manual: found ? true : false,
                expectedDate: found?.position?.expectedFulfillmentDate || "",
              };
            }),
          };
        });
  }, [
    defaultDelegationsForm,
    defaultFifthNew?.position?.list,
    syncData?.cities,
    syncData?.qualifications,
    selectedValueBPU,
    // BPURangeSalary,
  ]);

  const [delegationsForm, setDelegationsForm] = useState(
    defaultDelegationsForm?.length ? defaultDelegationsForm : []
  );

  useEffect(() => {
    setDelegationsForm(defaultDelegationsForm);
  }, [defaultDelegationsForm]);

  useEffect(() => {
    setPositionsForm(defaultPositionsForm);
  }, [defaultPositionsForm, setPositionsForm]);

  const defaultDelegations = useMemo(() => {
    const bmArr = [];
    const amArr = [];
    const admArr = [];
    const roArr = [];

    const users = syncData?.users;

    const defaultDeployment = defaultFourth?.deployment;

    for (let i = 0; i < defaultDeployment?.length; i++) {
      const perlocationArr = defaultDeployment[i];
      const deploymentPerLocation = perlocationArr?.users;

      for (let j = 0; j < deploymentPerLocation?.length; j++) {
        const perUser = deploymentPerLocation[j];
        if (
          users?.businessManagers?.find(
            (obj) => obj?.employeeId === perUser?.employeeId
          )
        ) {
          bmArr?.push({
            ...perUser,
            locationId: perlocationArr?.code,
          });
        }
        if (
          users?.accountManagerOrPIC?.find(
            (obj) => obj?.employeeId === perUser?.employeeId
          )
        ) {
          amArr?.push({
            ...perUser,
            locationId: perlocationArr?.code,
          });
        }
        if (
          users?.accountOfficerOrAdmin?.find(
            (obj) => obj?.employeeId === perUser?.employeeId
          )
        ) {
          admArr?.push({
            ...perUser,
            locationId: perlocationArr?.code,
          });
        }
        if (
          users?.recruitmentOfficers?.find(
            (obj) => obj?.employeeId === perUser?.employeeId
          )
        ) {
          roArr?.push({
            ...perUser,
            locationId: perlocationArr?.code,
          });
        }
      }
    }
    return {
      businessManagers: bmArr,
      accountManagers: amArr,
      admins: admArr,
      recruiters: roArr,
    };
  }, [defaultFourth?.deployment, syncData?.users]);

  const [delegations, setDelegations] = useState({
    businessManagers: defaultDelegations?.businessManagers || [],
    accountManagers: defaultDelegations?.accountManagers || [],
    admins: defaultDelegations?.admins || [],
    recruiters: defaultDelegations?.recruiters || [],
  });

  const [manPowerForm, setManPowerForm] = useState(
    defaultManPowerForm?.length > 0 ? defaultManPowerForm : []
  );

  const fixedDelegations = useMemo(() => {
    const arr = [];

    const businessManagers = delegations?.businessManagers;
    for (let i = 0; i < businessManagers?.length; i++) {
      const bm = businessManagers[i];
      if (manPowerForm?.find((obj) => obj?.location === bm?.locationId)) {
        arr.push({
          delegationID: bm?.delegationID,
          employeeId: bm?.employeeId,
          cityCode: bm?.locationId,
        });
      }
    }

    const accountManagers = delegations?.accountManagers;
    for (let i = 0; i < accountManagers?.length; i++) {
      const am = accountManagers[i];
      if (manPowerForm?.find((obj) => obj?.location === am?.locationId)) {
        arr.push({
          delegationID: am?.delegationID,
          employeeId: am?.employeeId,
          cityCode: am?.locationId,
          isPIC: am?.isPIC === false ? "NO" : "YES",
        });
      }
    }

    const admins = delegations?.admins;
    for (let i = 0; i < admins?.length; i++) {
      const admin = admins[i];
      if (manPowerForm?.find((obj) => obj?.location === admin?.locationId)) {
        arr.push({
          delegationID: admin?.delegationID,
          employeeId: admin?.employeeId,
          cityCode: admin?.locationId,
          isPIC: admin?.isPIC === false ? "NO" : "YES",
        });
      }
    }

    const recruiters = delegations?.recruiters;
    for (let i = 0; i < recruiters?.length; i++) {
      const ro = recruiters[i];
      if (manPowerForm?.find((obj) => obj?.location === ro?.locationId)) {
        arr.push({
          delegationID: ro?.delegationID,
          employeeId: ro?.employeeId,
          cityCode: ro?.locationId,
          isPIC: ro?.isPIC === false ? "NO" : "YES",
        });
      }
    }

    return arr;
    // eslint-disable-next-line
  }, [delegations, manPowerForm, trigger]);

  const optionsFetcher = useMemo(() => {
    return {
      first: () => {
        const arr = [];

        const contractTypeWorker = [];
        const personalWorkEquipment = [];
        const allowance = [];
        const areaWorkEquipment = [];

        const qualifications = [];

        for (let i = 0; i < syncData?.options.length; i++) {
          const option = syncData?.options[i];

          if (option?.type === "contractTypeWorker") {
            contractTypeWorker.push({
              id: option.id,
              name: option?.value,
            });
          } else if (option?.type === "Personal Work Equipment") {
            personalWorkEquipment.push({
              name: option?.value,
              value: "",
            });
          } else if (option?.type === "Allowance") {
            allowance.push({
              name: option?.value,
              value: "",
            });
          } else if (option?.type === "Area Work Equipment") {
            areaWorkEquipment.push({
              name: option?.value,
              value: "",
            });
          }
        }

        for (let i = 0; i < syncData?.qualifications.length; i++) {
          const qualification = syncData?.qualifications[i];

          qualifications.push({
            name: qualification?.value,
            denom: qualification.denom,
            value: null,
          });
        }

        arr.push({
          contractTypeWorker: contractTypeWorker,
          personalWorkEquipment: personalWorkEquipment,
          allowance: allowance,
          areaWorkEquipment: areaWorkEquipment,
          qualifications: qualifications,
        });

        return arr[0];
      },
    };
  }, [syncData]);

  const renewTrigger = useCallback(() => {
    setTrigger(Math.random());
  }, []);

  const debounceTrigger = useMemo(
    () => debounce(renewTrigger, 500),
    [renewTrigger]
  );

  const renewDefault = useCallback(() => {
    if (!isLoadingFourth) {
      sessionStorage.setItem(
        "manPowerFormAddEditProceed",
        JSON.stringify(manPowerForm)
      );
      sessionStorage.setItem(
        "manPowerFormArrEditProceed",
        JSON.stringify(manPowerFormArr)
      );
      sessionStorage.setItem(
        "delegationsFormEditProceed",
        JSON.stringify(delegationsForm)
      );

      // sessionStorage.setItem("remarkEditProceed", JSON.stringify(remark));
      sessionStorage.setItem("selectedValueBPUEditProceed", selectedValueBPU);

      sessionStorage.setItem(
        "delegationsEditProceed",
        JSON.stringify(delegations)
      );
    }

    if (!isLoadingFifthNew) {
      sessionStorage.setItem(
        "positionsFormEditProceed",
        JSON.stringify(positionsForm) || null
      );
    }

    sessionStorage.setItem("stepsEditProceed", JSON.stringify(steps));

    sessionStorage.setItem(
      "selectedButtonProceedBPJSEdit",
      JSON.stringify(selectedButtonBPJS)
    );

    sessionStorage.setItem(
      "isLoadingFourthProceed",
      isLoadingFourth ? "YES" : "NO"
    );
    sessionStorage.setItem(
      "isLoadingFifth2Proceed",
      isLoadingFifthNew ? "YES" : "NO"
    );

    sessionStorage.setItem(
      "defaultFourthProceed",
      JSON.stringify(defaultFourth)
    );
    sessionStorage.setItem(
      "defaultFifthNewProceed",
      JSON.stringify(defaultFifthNew)
    );

    // sessionStorage.setItem('orderFiles', JSON.stringify(fixedFiles))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // contractForm,
    // workSchemeForm,
    // additionalForm,
    manPowerForm,
    manPowerFormArr,
    delegationsForm,
    positionsForm,
    // remark,
    // TOP,
    // manfee,
    delegations,
    steps,
    trigger,
    // selectedButtonBPJS,
    selectedValueBPU,
    // contracts,
    // withBPJS,
    // isLoadingFirst,
    // isLoadingSecond,
    // isLoadingThird,
    isLoadingFourth,
    isLoadingFifthNew,
    defaultFourth,
  ]);

  const debounceDefault = useMemo(
    () => debounce(renewDefault, 500),
    [renewDefault]
  );

  useEffect(() => {
    debounceDefault();
  }, [debounceDefault, trigger]);

  const mappedDelegationsForm = useMemo(() => {
    return delegationsForm
      ?.map((obj) => ({
        code: obj?.cityCode,
        delegations: obj?.deployments
          ?.map((d) =>
            d?.delegations?.map((u) => ({
              ...u,
              position: d?.role,
            }))
          )
          ?.flat()
          ?.filter((u) => u?.isPicked),
        hasDelegationID: obj?.deployments?.some((d) =>
          d?.delegations?.some((u) => u?.delegationID)
        ),
      }))
      .sort((a, b) =>
        a.hasDelegationID === b.hasDelegationID ? 0 : a.hasDelegationID ? -1 : 1
      );
  }, [delegationsForm]);

  // console.log(positionsForm, "test");

  const mappedDeletedDelegationsForm = useMemo(() => {
    return delegationsForm?.map((obj) => {
      return {
        code: obj?.cityCode,
        delegations: obj?.deployments
          ?.map((d) =>
            d?.delegations?.map((u) => {
              return {
                ...u,
                position: d?.role,
              };
            })
          )
          ?.flat()
          ?.filter((u) => !u?.isPicked)
          ?.filter((u) => u?.delegationID),
      };
    });
  }, [delegationsForm]);

  const handleRenewPositionsForm = useCallback(() => {
    const newPositionsForm = positionsForm.slice(0);

    for (let i = 0; i < newPositionsForm?.length; i++) {
      const perPosition = newPositionsForm[i];
      const newCitiesData = delegationsForm?.map((d) => {
        const found = positionsForm
          ?.find(
            (obj) =>
              obj?.positionName?.toUpperCase() ===
              perPosition?.positionName?.toUpperCase()
          )
          ?.citiesData?.find((obj) => obj?.cityCode === d?.cityCode);

        const salaryJobs = found?.salaryValue || d?.umk || 4500000;

        const { jkk, jkm, jht } = BPURangeSalary(
          salaryJobs,
          found?.salaryDenom || "Bulanan"
        );
        return {
          id: found?.id || makeRandomString(5),
          isApplied: found?.isApplied || false,
          preventDelete: found?.preventDelete || false,
          preventEdit: found?.preventEdit || false,
          positionID: found?.positionID || null,
          cityCode: found?.cityCode || d?.cityCode,
          cityName: found?.cityName || d?.cityName,
          umk: found?.umk || d?.umk,
          amount: found?.amount || 1,
          salaryValue: salaryJobs,
          salaryDenom: found?.salaryDenom || "Bulanan",
          personalEquipments: found?.personalEquipments || [],
          allowances: found?.allowances || [],
          jkkID: found?.jkkID || "",
          jkk: found?.jkk
            ? found?.jkk
            : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
            ? salaryJobs
              ? `${+jkk / 2}`
              : "0"
            : salaryJobs
            ? jkk
            : "0",

          jkmID: found?.jkmID || "",
          jkm: found?.jkm
            ? found?.jkm
            : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
            ? salaryJobs
              ? `${+jkm / 2}`
              : "0"
            : salaryJobs
            ? jkm
            : "0",

          jhtID: found?.jhtID || "",
          jht: found?.jht
            ? found?.jht
            : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
            ? salaryJobs
              ? `${+jht / 2}`
              : "0"
            : salaryJobs
            ? jht
            : "0",
          isJkkManual: found?.isJkkManual ? found?.isJkkManual : false,
          isJhtManual: found?.isJhtManual ? found?.isJhtManual : false,
          isJkmManual: found?.isJkmManual ? found?.isJkmManual : false,
          jkkID1: found?.jkkID1 || "",
          jkk1: found?.jkk1
            ? found?.jkk1
            : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
            ? salaryJobs
              ? `${+jkk / 2}`
              : "0"
            : "0",

          jkmID1: found?.jkmID1 || "",
          jkm1: found?.jkm1
            ? found?.jkm1
            : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
            ? salaryJobs
              ? `${+jkm / 2}`
              : "0"
            : "0",
          jhtID1: found?.jhtID1 || "",
          jht1: found?.jht1
            ? found?.jht1
            : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
            ? salaryJobs
              ? `${+jht / 2}`
              : "0"
            : "0",

          isJkk1Manual: found?.isJkk1Manual ? found?.isJkk1Manual : false,
          isJht1Manual: found?.isJht1Manual ? found?.isJht1Manual : false,
          isJkm1Manual: found?.isJkm1Manual ? found?.isJkm1Manual : false,
          expectedDate: found?.expectedDate || "",
        };
      });
      perPosition.citiesData = newCitiesData;
    }
    // setPositionsForm(newPositionsForm)
  }, [delegationsForm, positionsForm, selectedValueBPU]);

  useEffect(() => {
    if (
      !isLoadingFifthNew &&
      positionsForm?.length &&
      delegationsForm?.length &&
      positionsForm[0]?.citiesData?.length !== delegationsForm?.length
    ) {
      handleRenewPositionsForm();
    }
  }, [
    delegationsForm?.length,
    handleRenewPositionsForm,
    isLoadingFifthNew,
    positionsForm,
    positionsForm?.length,
  ]);

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  };

  const alertFound = useMemo(() => {
    let errors = [];
    switch (currentStep) {
      case 1:
        if (!delegationsForm?.length) {
          errors?.push({
            validateCutOffAttendance: true,
            remark: "Daftar Lokasi",
            name: "Daftar Lokasi",
          });
        } else {
          const dArr3 = delegationsForm
            ?.map((obj) =>
              obj?.deployments[3]?.delegations?.map((u) => {
                return {
                  ...u,
                  cityName: obj?.cityName,
                };
              })
            )
            ?.flat();

          if (!dArr3?.find((u) => u?.isPicked)) {
            errors?.push({
              validateRO: true,
              remark: "RECRUITMENT OFFICER",
              name: `Data Recruitment Officer`,
              cityName: delegationsForm[0]?.cityName,
              cityCode: delegationsForm[0]?.cityCode,
            });
          } else {
            const dArr = delegationsForm?.map((obj) => {
              return {
                cityCode: obj?.cityCode,
                cityName: obj?.cityName,
                users: obj?.deployments[0]?.delegations?.map((u) => {
                  return {
                    ...u,
                    cityName: obj?.cityName,
                  };
                }),
              };
            });

            for (let i = 0; i < dArr?.length; i++) {
              const d = dArr[i];

              // console.log(d, "test");

              const found = !d?.users?.find((u) => u?.isPicked);

              if (found) {
                // return `Harap pilih business manager untuk lokasi ${d[0]?.cityName}`;
                errors?.push({
                  [`${i}`]: true,
                  remark: "BUSINESS MANAGER",
                  name: `Data Business Manager ${d?.cityName}`,
                  cityName: d?.cityName,
                  cityCode: d?.cityCode,
                });
              }
            }
          }
        }

        break;

      case 2:
        if (!positionsForm?.length) {
          errors?.push({
            validatePositionsForm: true,
            remark: "Daftar Posisi",
            name: "Daftar Posisi",
          });
        } else {
          const flatPositions = positionsForm
            ?.map((obj) =>
              obj?.citiesData
                ?.filter((f) => f?.isApplied)
                ?.map((c) => {
                  return {
                    ...c,
                    positionName: obj?.positionName,
                  };
                })
            )
            ?.flat();

          for (let i = 0; i < delegationsForm?.length; i++) {
            if (
              !flatPositions?.find(
                (p) => p?.cityCode === delegationsForm[i].cityCode
              )
            ) {
              errors?.push({
                [`${i}`]: true,
                remark: "Daftar Posisi",
                name: `Belum ada posisi diaktifkan untuk kota ${delegationsForm[i]?.cityName}`,
                cityId: positionsForm[0]?.citiesData?.find(
                  (c) => c?.cityCode === delegationsForm[i].cityCode
                )
                  ? positionsForm[0]?.citiesData?.find(
                      (c) => c?.cityCode === delegationsForm[i].cityCode
                    )?.id
                  : "",
                positionId: positionsForm[0]?.id,
              });
            }

            for (let i = 0; i < positionsForm.length; i++) {
              // if (!positionsForm[i]?.positionName) {
              //   return `Salah satu nama posisi belum diisi`;
              // }
              // if (
              //   positionsForm[i]?.qualifications?.find(
              //     (obj) => !obj?.value || !obj?.name
              //   )
              // ) {
              //   return `Kualifikasi untuk ${
              //     positionsForm[i]?.positionName || "salah satu posisi"
              //   } belum diisi`;
              // }
              // if (!positionsForm[i]?.citiesData?.find((c) => c?.isApplied)) {
              //   return `Posisi ${positionsForm[i]?.positionName} belum diaktifkan ke salah satu kota`;
              // }
              const appliedCities = positionsForm[i]?.citiesData?.filter(
                (c) => c?.isApplied
              );

              for (let j = 0; j < appliedCities?.length; j++) {
                const isDuplicate = errors?.some(
                  (e) =>
                    e.cityId === appliedCities[j]?.id &&
                    e.positionId === positionsForm[i]?.id
                );

                if (
                  appliedCities[j]?.salaryValue === "" ||
                  appliedCities[j]?.salaryValue === null
                ) {
                  if (!isDuplicate) {
                    errors.push({
                      [`${j}`]: true,
                      remark: "Salary",
                      name: `Nominal salary ${positionsForm[i]?.positionName} ${appliedCities[j]?.cityName} belum diisi`,
                      cityId: appliedCities[j]?.id,
                      positionId: positionsForm[i]?.id,
                    });
                  }
                }

                if (
                  appliedCities[j]?.salaryDenom === "" ||
                  appliedCities[j]?.salaryDenom === null
                ) {
                  if (!isDuplicate) {
                    errors?.push({
                      [`${j}`]: true,
                      remark: "SalaryDenom",
                      name: `Tempo salary ${positionsForm[i]?.positionName} ${appliedCities[j]?.cityName} belum diisi`,
                      cityId: appliedCities[j]?.id,
                      positionId: positionsForm[i]?.id,
                    });
                  }
                }

                if (appliedCities[j]?.personalEquipments?.length > 0) {
                  for (
                    let l = 0;
                    l < appliedCities[j]?.personalEquipments?.length;
                    l++
                  ) {
                    const personalEquipment =
                      appliedCities[j]?.personalEquipments[l];
                    if (!personalEquipment?.value) {
                      if (!isDuplicate) {
                        errors.push({
                          [`${l}`]: true,
                          remark: `Personal Equipment ${personalEquipment?.name}`,
                          name: `Personal Equipment ${personalEquipment?.name} posisi ${positionsForm[i]?.positionName} ${appliedCities[j]?.cityName} belum diisi`,
                          cityId: appliedCities[j]?.id,
                          positionId: positionsForm[i]?.id,
                        });
                      }
                    }
                  }
                }

                if (appliedCities[j]?.allowances?.length > 0) {
                  for (
                    let k = 0;
                    k < appliedCities[j]?.allowances?.length;
                    k++
                  ) {
                    const allowance = appliedCities[j]?.allowances[k];
                    if (!allowance?.value) {
                      if (!isDuplicate) {
                        errors.push({
                          [`${k}`]: true,
                          remark: `Allowances ${allowance?.name}`,
                          name: `Allowances ${allowance?.name} posisi ${positionsForm[i]?.positionName} ${appliedCities[j]?.cityName} belum diisi`,
                          cityId: appliedCities[j]?.id,
                          positionId: positionsForm[i]?.id,
                        });
                      }
                      // return `Allowances ${allowance?.name} posisi ${positionsForm[i]?.positionName} ${appliedCities[j]?.cityName} belum diisi`;
                    }
                  }
                }

                if (!appliedCities[j]?.expectedDate) {
                  if (!isDuplicate) {
                    errors?.push({
                      [`${j}`]: true,
                      remark: "date",
                      name: `Expected fulfillment date ${positionsForm[i]?.positionName} ${appliedCities[j]?.cityName} belum diisi`,
                      cityId: appliedCities[j]?.id,
                      positionId: positionsForm[i]?.id,
                    });
                  }
                }
              }
            }
          }
        }

        // const noRODedicatedFound = positionsForm?.find(
        //   (obj) => !obj?.dedicatedROId || !obj?.dedicatedROName
        // );
        // if (noRODedicatedFound) {
        //   return `Mohon untuk memilih Recruitment Officer Dedicated untuk posisi ${noRODedicatedFound?.positionName}`;
        // }
        // const noQualificationFound = positionsForm?.find(
        //   (obj) => !obj?.qualifications?.length
        // );
        // if (noQualificationFound) {
        //   return `Mohon untuk memasukkan kualifikasi untuk posisi ${noQualificationFound?.positionName}`;
        // }
        break;

      default:
        return errors;
    }

    return errors;
  }, [currentStep, delegationsForm, positionsForm, selectedValueBPU]);

  // console.log(alertFound, delegationsForm, positionsForm);
  const onNext = useCallback(
    (isDone) => {
      const newSteps = [...steps];
      if (isDone) {
        newSteps[currentStep - 1].isDone = true;
        setSteps(newSteps);
        setCurrentStep(currentStep + 1);
        scrollingTop();
      }
    },
    [currentStep, setCurrentStep, setSteps, steps]
  );
  const [errorDeployment, setErrorDeployment] = useState(false);

  const handleOnNext = useCallback(() => {
    if (alertFound?.length > 0) {
      setAlertMessage(alertFound);
      if (
        currentStep === 1 &&
        alertFound?.filter((el) => el?.remark === "RECRUITMENT OFFICER")
          ?.length > 0
      ) {
        setErrorDeployment(true);
        setActiveCityID(delegationsForm[0]?.cityCode);
      }
    } else {
      setAlertMessage([]);
      onNext(true);
      setErrorDeployment(false);
    }
    scrollingTop();
  }, [
    alertFound,
    onNext,
    setAlertMessage,
    currentStep,
    setActiveCityID,
    delegationsForm,
  ]);

  const activeStep = useMemo(() => {
    switch (currentStep) {
      case 1:
        return (
          <DeploymentStep
            delegationsForm={delegationsForm}
            setDelegationsForm={setDelegationsForm}
            defaultDeployments={defaultDeploymentsData}
            cities={syncData?.cities}
            headerHeight={headerHeight}
            alertFound={alertMessage}
            errorDeployment={errorDeployment}
            setErrorDeployment={setErrorDeployment}
            activeCityID={activeCityID}
            setActiveCityID={setActiveCityID}
          />
          // <FourthStep
          //   form={manPowerForm}
          //   setForm={setManPowerForm}
          //   options={optionsFetcher?.fourth()}
          //   cities={syncData?.cities}
          //   remark={remark}
          //   setRemark={setRemark}
          //   trigger={trigger}
          //   setTrigger={setTrigger}
          //   isDone={isDoneChecker?.fourth()}
          //   onPrevious={onPrevious}
          //   onNext={() => onNext(isDoneChecker?.fourth())}
          //   debounceTrigger={debounceTrigger}
          //   selectedValueBPU={selectedValueBPU}
          // />
          // <NewFourth
          //   formArr={manPowerFormArr}
          //   setFormArr={setManPowerFormArr}
          //   cities={syncData?.cities}
          //   remark={remark}
          //   setRemark={setRemark}
          //   defaultDeployments={defaultDeploymentsData}
          //   contractTypeOptions={syncData?.options?.filter(obj => obj?.type === "contractTypeWorker")?.map(obj => obj?.value)}
          //   salaryDenomOptions={["Harian", "Mingguan", "Bulanan"]}
          //   personalEquipmentOptions={syncData?.options?.filter(obj => obj?.type === 'Personal Work Equipment')?.map(obj => obj?.value)}
          //   allowanceOptions={syncData?.options?.filter(obj => obj?.type === "Allowance")?.map(obj => obj?.value)}
          //   qualificationOptions={syncData?.qualifications?.map(obj => {
          //     return {
          //       // id: makeRandomString(5),
          //       name: obj?.value,
          //       denom: obj?.denom,
          //       value: ''
          //     }
          //   })}
          //   areaEquipmentOptions={syncData?.options?.filter(obj => obj?.type === 'Area Work Equipment')?.map(obj => obj?.value)}
          //   onNext={() => onNext(true)}
          //   onPrevious={onPrevious}
          //   isDone={isDoneChecker?.fourth()}
          //   selectedValueBPU={selectedValueBPU}
          // />
          // <FourthRevamp
          //   onPrevious={onPrevious}
          //   onNext={handleOnNext}
          //   delegationsForm={delegationsForm}
          //   setDelegationsForm={setDelegationsForm}
          //   positionsForm={positionsForm}
          //   setPositionsForm={setPositionsForm}
          //   remark={remark}
          //   setRemark={setRemark}
          //   defaultDeployments={defaultDeploymentsData}
          //   cities={syncData?.cities}
          //   alertMessage={alertMessage}
          //   contractTypeOptions={syncData?.options
          //     ?.filter((obj) => obj?.type === "contractTypeWorker")
          //     ?.map((obj) => obj?.value)}
          //   personalEquipmentOptions={syncData?.options
          //     ?.filter((obj) => obj?.type === "Personal Work Equipment")
          //     ?.map((obj) => obj?.value)}
          //   allowanceOptions={syncData?.options
          //     ?.filter((obj) => obj?.type === "Allowance")
          //     ?.map((obj) => obj?.value)}
          //   qualificationOptions={syncData?.qualifications?.map((obj) => {
          //     return {
          //       // id: makeRandomString(5),
          //       name: obj?.value,
          //       denom: obj?.denom,
          //       value: "",
          //     };
          //   })}
          //   areaEquipmentOptions={syncData?.options
          //     ?.filter((obj) => obj?.type === "Area Work Equipment")
          //     ?.map((obj) => obj?.value)}
          //   selectedButtonBPJS={selectedButtonBPJS}
          //   selectedValueBPU={selectedValueBPU}
          //   scrollingTop={scrollingTop}
          // />
        );
      case 2:
        return (
          <PositionStep
            activeExactPositionID={activeExactPositionID}
            setActiveExactPositionID={setActiveExactPositionID}
            activePositionID={activePositionID}
            setActivePositionID={setActivePositionID}
            delegationsForm={delegationsForm}
            setDelegationsForm={setDelegationsForm}
            positionsForm={positionsForm}
            setPositionsForm={setPositionsForm}
            headerHeight={headerHeight}
            alertMessage={alertMessage}
            qualificationOptions={syncData?.qualifications?.map((obj) => {
              return {
                // id: makeRandomString(5),
                name: obj?.value,
                denom: obj?.denom,
                value: "",
              };
            })}
            remark={null}
            setRemark={() => {}}
            cities={syncData?.cities}
            personalEquipmentOptions={syncData?.options
              ?.filter((obj) => obj?.type === "Personal Work Equipment")
              ?.map((obj) => obj?.value)}
            allowanceOptions={syncData?.options
              ?.filter((obj) => obj?.type === "Allowance")
              ?.map((obj) => obj?.value)}
            selectedButtonBPJS={selectedButtonBPJS}
            selectedValueBPU={selectedValueBPU}
            scrollingTop={scrollingTop}
            isProcced
          />

          // <FourthStep
          //   form={manPowerForm}
          //   setForm={setManPowerForm}
          //   options={optionsFetcher?.fourth()}
          //   cities={syncData?.cities}
          //   remark={remark}
          //   setRemark={setRemark}
          //   trigger={trigger}
          //   setTrigger={setTrigger}
          //   isDone={isDoneChecker?.fourth()}
          //   onPrevious={onPrevious}
          //   onNext={() => onNext(isDoneChecker?.fourth())}
          //   debounceTrigger={debounceTrigger}
          //   selectedValueBPU={selectedValueBPU}
          // />
          // <NewFourth
          //   formArr={manPowerFormArr}
          //   setFormArr={setManPowerFormArr}
          //   isDone={fourthStepDoneIndicator}
          //   cities={cities}
          //   remark={remark}
          //   setRemark={setRemark}
          //   defaultDeployments={defaultDeploymentsData}
          //   contractTypeOptions={options?.filter(obj => obj?.type === "contractTypeWorker")?.map(obj => obj?.value)}
          //   salaryDenomOptions={["Harian", "Mingguan", "Bulanan"]}
          //   personalEquipmentOptions={options?.filter(obj => obj?.type === 'Personal Work Equipment')?.map(obj => obj?.value)}
          //   allowanceOptions={options?.filter(obj => obj?.type === "Allowance")?.map(obj => obj?.value)}
          //   qualificationOptions={qualifications?.map(obj => {
          //     return {
          //       // id: makeRandomString(5),
          //       name: obj?.value,
          //       denom: obj?.denom,
          //       value: ''
          //     }
          //   })}
          //   areaEquipmentOptions={options?.filter(obj => obj?.type === 'Area Work Equipment')?.map(obj => obj?.value)}
          //   onNext={() => onNext(true)}
          //   onPrevious={onPrevious}
          //   selectedValueBPU={selectedValueBPU}
          // />
          // <FourthRevamp
          //   onPrevious={onPrevious}
          //   onNext={handleOnNext}
          //   delegationsForm={delegationsForm}
          //   setDelegationsForm={setDelegationsForm}
          //   positionsForm={positionsForm}
          //   setPositionsForm={setPositionsForm}
          //   remark={remark}
          //   setRemark={setRemark}
          //   defaultDeployments={defaultDeploymentsData}
          //   cities={cities}
          //   alertMessage={alertMessage}
          //   contractTypeOptions={options
          //     ?.filter((obj) => obj?.type === "contractTypeWorker")
          //     ?.map((obj) => obj?.value)}
          //   personalEquipmentOptions={options
          //     ?.filter((obj) => obj?.type === "Personal Work Equipment")
          //     ?.map((obj) => obj?.value)}
          //   allowanceOptions={options
          //     ?.filter((obj) => obj?.type === "Allowance")
          //     ?.map((obj) => obj?.value)}
          //   qualificationOptions={qualifications?.map((obj) => {
          //     return {
          //       // id: makeRandomString(5),
          //       name: obj?.value,
          //       denom: obj?.denom,
          //       value: "",
          //     };
          //   })}
          //   areaEquipmentOptions={options
          //     ?.filter((obj) => obj?.type === "Area Work Equipment")
          //     ?.map((obj) => obj?.value)}
          //   selectedButtonBPJS={selectedButtonBPJS}
          //   selectedValueBPU={selectedValueBPU}
          //   scrollingTop={scrollingTop}
          // />
        );

      default:
        <DeploymentStep
          delegationsForm={delegationsForm}
          setDelegationsForm={setDelegationsForm}
          defaultDeployments={defaultDeploymentsData}
          cities={syncData?.cities}
          headerHeight={headerHeight}
          alertFound={alertMessage}
          errorDeployment={errorDeployment}
          setErrorDeployment={setErrorDeployment}
          activeCityID={activeCityID}
          setActiveCityID={setActiveCityID}
        />;
    }
  }, [
    currentStep,
    alertMessage,
    optionsFetcher,
    handleOnNext,
    query,
    selectedValueBPU,
    delegationsForm,
    defaultDeploymentsData,
    syncData?.cities,
    syncData?.qualifications,
    syncData?.options,
    headerHeight,
    positionsForm,
    debounceTrigger,
    submitting,
    error,
    onNext,
    errorDeployment,
    activeCityID,
    setActiveCityID,
    activePositionID,
    setActivePositionID,
    activeExactPositionID,
    setActiveExactPositionID,
    setPositionsForm,
  ]);

  const { width } = useWindowSize();

  const onPrevious = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep, setCurrentStep]);

  const clearStorage = () => {
    sessionStorage.clear();
  };

  const handleSubmit = async (e) => {
    try {
      if (alertFound?.length > 0) {
        setAlertMessage(alertFound);
      } else {
        e.preventDefault();
        setSubmitting(true);
        const formData = new FormData();

        formData?.append("clientCode", query?.get("c"));
        formData.append(
          "employeementType",
          defaultFourth?.employeementType ||
            defaultFifthNew?.employeementType ||
            ""
        );

        for (let i = 0; i < mappedDelegationsForm.length; i++) {
          const dataPerCity = mappedDelegationsForm[i];
          formData?.append(`orderLocations[${i}][code]`, dataPerCity?.code);

          for (let j = 0; j < dataPerCity?.delegations?.length; j++) {
            const perDelegation = dataPerCity?.delegations[j];

            formData?.append(
              `orderLocations[${i}][delegations][${j}][delegationID]`,
              perDelegation?.delegationID || ""
            );
            formData?.append(
              `orderLocations[${i}][delegations][${j}][position]`,
              perDelegation?.position
            );
            formData?.append(
              `orderLocations[${i}][delegations][${j}][employeeId]`,
              perDelegation?.id
            );
            formData?.append(
              `orderLocations[${i}][delegations][${j}][isPIC]`,
              perDelegation?.isPIC ? "YES" : "NO"
            );
            formData?.append(
              `orderLocations[${i}][delegations][${j}][action]`,
              perDelegation?.delegationID ? "UPDATE" : ""
            );
          }
          if (mappedDeletedDelegationsForm[i]?.delegations?.length) {
            for (
              let j = 0;
              j < mappedDeletedDelegationsForm[i]?.delegations?.length;
              j++
            ) {
              const perDelegation =
                mappedDeletedDelegationsForm[i]?.delegations[j];

              formData?.append(
                `orderLocations[${i}][delegations][${
                  j + dataPerCity?.delegations?.length
                }][delegationID]`,
                perDelegation?.delegationID || ""
              );
              formData?.append(
                `orderLocations[${i}][delegations][${
                  j + dataPerCity?.delegations?.length
                }][position]`,
                perDelegation?.position
              );
              formData?.append(
                `orderLocations[${i}][delegations][${
                  j + dataPerCity?.delegations?.length
                }][employeeId]`,
                perDelegation?.id
              );
              formData?.append(
                `orderLocations[${i}][delegations][${
                  j + dataPerCity?.delegations?.length
                }][isPIC]`,
                perDelegation?.isPIC ? "YES" : "NO"
              );
              formData?.append(
                `orderLocations[${i}][delegations][${
                  j + dataPerCity?.delegations?.length
                }][action]`,
                perDelegation?.delegationID ? "DELETE" : ""
              );
            }
          }
        }

        for (let i = 0; i < positionsForm?.length; i++) {
          const perPositionData = positionsForm[i];

          formData?.append(`jobs[${i}][title]`, perPositionData?.positionName);
          formData?.append(
            `jobs[${i}][dedicatedRO]`,
            perPositionData?.dedicatedROId
          );

          for (let j = 0; j < perPositionData?.qualifications?.length; j++) {
            const perQualification = perPositionData?.qualifications[j];

            formData?.append(
              `jobs[${i}][qualifications][${j}][name]`,
              perQualification?.name
            );
            formData?.append(
              `jobs[${i}][qualifications][${j}][value]`,
              perQualification?.value
            );
            formData?.append(
              `jobs[${i}][qualifications][${j}][action]`,
              perQualification?.qualificationID ? "UPDATE" : ""
            );
          }

          const deletedQualificationsArr =
            defaultPositionsForm
              ?.find(
                (obj) => obj?.positionName === perPositionData?.positionName
              )
              ?.qualifications?.filter((obj) =>
                perPositionData?.qualifications?.every(
                  (f) => f?.qualificationID !== obj?.qualificationID
                )
              ) || [];

          for (let j = 0; j < deletedQualificationsArr?.length; j++) {
            const perQualification = deletedQualificationsArr[j];

            formData?.append(
              `jobs[${i}][qualifications][${
                j + perPositionData?.qualifications?.length
              }][name]`,
              perQualification?.name
            );
            formData?.append(
              `jobs[${i}][qualifications][${
                j + perPositionData?.qualifications?.length
              }][value]`,
              perQualification?.value
            );
            formData?.append(
              `jobs[${i}][qualifications][${
                j + perPositionData?.qualifications?.length
              }][action]`,
              perQualification?.qualificationID ? "DELETE" : ""
            );
          }

          const appliedCitiesData = perPositionData?.citiesData
            ?.filter((obj) => obj?.isApplied)
            ?.sort((a, b) => {
              if (!a.positionID) return 1; // Pindahkan objek tanpa positionID ke urutan terakhir
              if (!b.positionID) return -1; // Pindahkan objek dengan positionID di depan
              return 0; // Pertahankan urutan lainnya
            });

          for (let j = 0; j < appliedCitiesData?.length; j++) {
            const perCityData = appliedCitiesData[j];

            formData?.append(
              `jobs[${i}][locations][${j}][positionID]`,
              perCityData?.positionID || ""
            );
            formData?.append(
              `jobs[${i}][locations][${j}][code]`,
              perCityData?.cityCode
            );
            formData?.append(
              `jobs[${i}][locations][${j}][salary]`,
              `${perCityData?.salaryValue}::${perCityData?.salaryDenom}`
            );
            formData?.append(
              `jobs[${i}][locations][${j}][fulfilmentDate]`,
              moment(perCityData?.expectedDate)?.format("YYYY-MM-DD")
            );
            formData?.append(
              `jobs[${i}][locations][${j}][demand]`,
              perCityData?.amount
            );

            for (let k = 0; k < perCityData?.personalEquipments?.length; k++) {
              const perEquipment = perCityData?.personalEquipments[k];
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${k}][equipmentID]`,
                perEquipment?.equipmentID || ""
              );
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${k}][name]`,
                perEquipment?.name
              );
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${k}][price]`,
                perEquipment?.value
              );
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${k}][action]`,
                perEquipment?.equipmentID ? "UPDATE" : ""
              );
            }

            const deletedPersonalEquipmentsArr =
              defaultPositionsForm
                ?.find(
                  (obj) => obj?.positionName === perPositionData?.positionName
                )
                ?.citiesData?.find(
                  (obj) => obj?.positionID === perCityData?.positionID
                )
                ?.personalEquipments?.filter((obj) =>
                  perCityData?.personalEquipments?.every(
                    (f) => f?.equipmentID !== obj?.equipmentID
                  )
                ) || [];

            // console.log(deletedPersonalEquipmentsArr, " ini test");

            for (let k = 0; k < deletedPersonalEquipmentsArr?.length; k++) {
              const perEquipment = deletedPersonalEquipmentsArr[k];
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${
                  k + perCityData?.personalEquipments?.length
                }][equipmentID]`,
                perEquipment?.equipmentID || ""
              );
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${
                  k + perCityData?.personalEquipments?.length
                }][name]`,
                perEquipment?.name
              );
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${
                  k + perCityData?.personalEquipments?.length
                }][price]`,
                perEquipment?.value
              );
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${
                  k + perCityData?.personalEquipments?.length
                }][action]`,
                perEquipment?.equipmentID ? "DELETE" : ""
              );
            }

            for (let k = 0; k < perCityData?.allowances?.length; k++) {
              const perAllowance = perCityData?.allowances[k];
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${k}][allowanceID]`,
                perAllowance?.allowanceID || ""
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${k}][name]`,
                perAllowance?.name
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${k}][value]`,
                perAllowance?.value
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${k}][denom]`,
                perAllowance?.denom || "Bulanan"
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${k}][action]`,
                perAllowance?.allowanceID ? "UPDATE" : ""
              );
            }

            const deletedAllowancesArr =
              defaultPositionsForm
                ?.find(
                  (obj) => obj?.positionName === perPositionData?.positionName
                )
                ?.citiesData?.find(
                  (obj) => obj?.positionID === perCityData?.positionID
                )
                ?.allowances?.filter((obj) =>
                  perCityData?.allowances?.every(
                    (f) => f?.allowanceID !== obj?.allowanceID
                  )
                ) || [];

            for (let k = 0; k < deletedAllowancesArr?.length; k++) {
              const perAllowance = deletedAllowancesArr[k];
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  k + perCityData?.allowances?.length
                }][allowanceID]`,
                perAllowance?.allowanceID || ""
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  k + perCityData?.allowances?.length
                }][name]`,
                perAllowance?.name
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  k + perCityData?.allowances?.length
                }][value]`,
                perAllowance?.value
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  k + perCityData?.allowances?.length
                }][denom]`,
                perAllowance?.denom
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  k + perCityData?.allowances?.length
                }][action]`,
                perAllowance?.allowanceID ? "DELETE" : ""
              );
            }

            if (selectedButtonBPJS === "BPU") {
              if (
                selectedValueBPU === "Tagih Ke Perusahaan" ||
                selectedValueBPU === "Tagih Ke Karyawan"
              ) {
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][allowanceID]`,
                  perCityData?.jkkID || ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][name]`,
                  "JKK"
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][value]`,
                  perCityData?.jkk || 0
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][action]`,
                  perCityData?.jkkID ? "UPDATE" : ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][type]`,
                  "BPU"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][billingTo]`,
                  selectedValueBPU === "Tagih Ke Karyawan"
                    ? "Karyawan"
                    : "Perusahaan"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][allowanceID]`,
                  perCityData?.jkmID || ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][name]`,
                  "JKM"
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][value]`,
                  perCityData?.jkm || 0
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][action]`,
                  perCityData?.jkmID ? "UPDATE" : ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][type]`,
                  "BPU"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][billingTo]`,
                  selectedValueBPU === "Tagih Ke Karyawan"
                    ? "Karyawan"
                    : "Perusahaan"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][allowanceID]`,
                  perCityData?.jhtID || ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][name]`,
                  "JHT"
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][value]`,
                  perCityData?.jht || 0
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][action]`,
                  perCityData?.jhtID ? "UPDATE" : ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][type]`,
                  "BPU"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][billingTo]`,
                  selectedValueBPU === "Tagih Ke Karyawan"
                    ? "Karyawan"
                    : "Perusahaan"
                );
              } else if (
                selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
              ) {
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][allowanceID]`,
                  perCityData?.jkkID || ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][name]`,
                  "JKK"
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][value]`,
                  perCityData?.jkk || 0
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][action]`,
                  perCityData?.jkkID ? "UPDATE" : ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][type]`,
                  "BPU"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][billingTo]`,
                  "Perusahaan"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][allowanceID]`,
                  perCityData?.jkmID || ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][name]`,
                  "JKM"
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][value]`,
                  perCityData?.jkm || 0
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][action]`,
                  perCityData?.jkmID ? "UPDATE" : ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][type]`,
                  "BPU"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][billingTo]`,
                  "Perusahaan"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][allowanceID]`,
                  perCityData?.jhtID || ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][name]`,
                  "JHT"
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][value]`,
                  perCityData?.jht || 0
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][action]`,
                  perCityData?.jhtID ? "UPDATE" : ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][type]`,
                  "BPU"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][billingTo]`,
                  "Perusahaan"
                );

                // next

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    3
                  }][allowanceID]`,
                  perCityData?.jkkID1 || ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    3
                  }][name]`,
                  "JKK"
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    3
                  }][value]`,
                  perCityData?.jkk1 || 0
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    3
                  }][action]`,
                  perCityData?.jkkID1 ? "UPDATE" : ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    3
                  }][type]`,
                  "BPU"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    3
                  }][billingTo]`,
                  "Karyawan"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    4
                  }][allowanceID]`,
                  perCityData?.jkmID1 || ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    4
                  }][name]`,
                  "JKM"
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    4
                  }][value]`,
                  perCityData?.jkm1 || 0
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    4
                  }][action]`,
                  perCityData?.jkmID1 ? "UPDATE" : ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    4
                  }][type]`,
                  "BPU"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    4
                  }][billingTo]`,
                  "Karyawan"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    5
                  }][allowanceID]`,
                  perCityData?.jhtID1 || ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    5
                  }][name]`,
                  "JHT"
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    5
                  }][value]`,
                  perCityData?.jht1 || 0
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    5
                  }][action]`,
                  perCityData?.jhtID1 ? "UPDATE" : ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    5
                  }][type]`,
                  "BPU"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    5
                  }][billingTo]`,
                  "Karyawan"
                );
              }
            }
          }
        }

        await editOrderAndSendFLB(formData, query.get("i"));
        setSubmitting(false);
        setIsSuccess(true);
        setAlertMessage([]);
      }
    } catch (err) {
      setSubmitting(false);
      setError({
        code: err?.response?.data?.code,
        message: err?.response?.data?.error,
      });
    }
  };

  const templates = useMemo(() => {
    return [
      {
        id: 1,
        icon: "building-office-2",
        color: "#1571DE",
        name: "Klien",
        value: query?.get("n") || null,
      },
      {
        id: 2,
        icon: "file",
        color: "#1571DE",
        name: "Nomor dokumen",
        value:
          defaultFourth?.additional?.periodeKerjasama?.docType ||
          defaultFourth?.additional?.periodeKerjasama?.docNumber
            ? `${
                defaultFourth?.additional?.periodeKerjasama?.docType || "-"
              } • ${
                defaultFourth?.additional?.periodeKerjasama?.docNumber || "-"
              }`
            : null,
      },
    ];
  }, [query, defaultFourth]);

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      {width <= 900 && (
        <div className={Styles.headerResponsive}>
          <div className={Styles.topRight}>
            <span>Service Client</span>
            <div
              onClick={() => {
                setViewDetails(true);
              }}
            >
              <span>View Detail</span>
              <Icon icon={"eye-visible"} size={20} color={"#1571DE"} />
            </div>
          </div>

          <div className={Styles.bottomRight}>
            {templates?.map((el, idx) => {
              return (
                <div key={idx}>
                  <div className={Styles.icon}>
                    <Icon
                      icon={`${el?.icon}`}
                      color={`${el?.color}`}
                      size={20}
                    />
                  </div>

                  <div className={Styles.titleBox}>
                    <span>{el?.name}</span>
                    <span> {el?.value || "-"}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {activeState?.isLoading ? (
        <LoadingAnimationPage
          height={`calc(100vh - ${headerHeight}px - 76px)`}
        />
      ) : (
        <div className={Styles.content}>{activeStep}</div>
      )}

      {error && (
        <ErrorModal
          error={error?.message || "-"}
          handleDone={() => setError(null)}
          onBack={() => setError(null)}
        />
      )}

      {(submitting || isSuccess) && (
        <SendFLBModal
          // error={error}
          show={submitting || isSuccess}
          type={submitting ? "submit" : isSuccess ? "success" : "failure"}
          onNext={() => {
            navigate(-1);
            clearStorage();
          }}
          onClose={() => {}}
        />
      )}

      {showSideBar && (
        <SideBarErrors
          handleClose={() => setShowSideBar(false)}
          data={alertMessage}
          setActiveCityID={setActiveCityID}
          activePositionID={activePositionID}
          setActivePositionID={setActivePositionID}
          activeExactPositionID={activeExactPositionID}
          setActiveExactPositionID={setActiveExactPositionID}
          positionsForm={positionsForm}
          setPositionsForm={setPositionsForm}
        />
      )}

      <div
        className={`${Styles.buttonsWrapper} ${
          +currentStep !== 1 && +currentStep !== 7 ? Styles.height : ""
        }`}
      >
        <div className={Styles.innerButtonsWrapper}>
          {currentStep != 1 && (
            <button className={Styles.previous} onClick={onPrevious}>
              <Icon icon={"arrow-left-back"} size={20} />
              <span>
                {`Mundur ke Step ${+currentStep - 1}: ${previousButtonText}`}
              </span>
            </button>
          )}
          {currentStep != 2 && (
            <button className={Styles.next} onClick={handleOnNext}>
              <span>
                {`Lanjut ke Step ${+currentStep + 1}: ${nextButtonText}`}
              </span>
              <Icon icon={"arrow-right-next"} size={20} />
            </button>
          )}
          {currentStep === 2 && (
            <button className={Styles.next} onClick={handleSubmit}>
              <span>Simpan</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

// const LocationSection = ({ locationList = [] }) => {
//   return (
//     <div className={`${Styles.location} ${Styles.section}`}>
//       <h3 className={Styles.sTitle}>Location</h3>
//       <div className={`${Styles.locContent} ${Styles.grid}`}>
//         {locationList?.map((each, i) => (
//           <div key={i}>
//             <div>
//               <Icon icon={"company-outline"} size={24} />
//             </div>
//             <span>{each}</span>
//             <img src={Images.LOC_DECOR} alt="" />
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };
