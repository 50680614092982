import Icon from "@Atom/Icon";
import ModalWrapper from "@Atom/ModalWrapper";
import { fileBaseUrl } from "@Config/api";
import { makeRandomString } from "@Helpers/makeRandomString";
import useWindowSize from "@Hooks/useWindowSize";
import DetailRemarkModal from "@Molecule/_modal/DetailRemarkModal";
import FLBHistoryModal from "@Molecule/_modal/FLBHistoryModal";
import UpdateContractModal from "@Molecule/_modal/UpdateContractModal";
import CustomTable from "@Molecule/CustomTable";
import Images from "@Theme/Images";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";

export default function FirstStep({
  form,
  setForm,
  options,
  setContractType,
  setTrigger,
  isDone,
  onNext,
  contracts = [],
  setContracts = () => {},
  flbData = {},
  alertMessage = [],
}) {
  const [showAddNewContracts, setShowAddNewContracts] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);
  const [showRemark, setShowRemark] = useState(null);

  const [showLog, setShowLog] = useState(false);

  const { width } = useWindowSize();

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  const onChange = (type) => {
    setContractType(type);
    setTrigger(Math.random());
    setForm({
      contract: type?.title,
    });
  };

  const columns = [
    {
      name: "docNumber",
      label: "Document Number",
    },
    {
      name: "docType",
      label: "Document Type",
    },
    {
      name: "uploadedAt",
      label: "Upload Date & Time",
      renderData: (row) => (
        <span>
          {moment(row?.uploadedAt)?.format("LLL")?.replaceAll(" pukul", ",")}
        </span>
      ),
    },
    {
      name: "startDate",
      label: "Start Date",
      renderData: (row) => (
        <span>
          {moment(row?.startDate)?.format("LLL")?.replaceAll(" pukul", ",")}
        </span>
      ),
    },
    {
      name: "endDate",
      label: "End Date",
      renderData: (row) => (
        <span>
          {moment(row?.endDate)?.format("LLL")?.replaceAll(" pukul", ",")}
        </span>
      ),
    },
    {
      name: "attachment",
      label: "Attachment",
      renderData: (row) => (
        <span
          className={`${Styles.seeFile} ${!row?.file && Styles.disabled}`}
          onClick={() => {
            if (row?.file) {
              window
                .open(
                  row?.file instanceof File
                    ? URL.createObjectURL(row?.file)
                    : fileBaseUrl + row?.file,
                  "_blank"
                )
                .focus();
            }
          }}
        >
          Lihat File
        </span>
      ),
    },
    {
      name: "remark",
      label: "Catatan",
      renderData: (row) => (
        <div
          className={`${Styles.remarkRow} ${!row?.remark && Styles.disabled}`}
          onClick={() => {
            if (row?.remark) {
              setShowRemark(row);
            }
          }}
        >
          {row?.remark ? "Lihat Catatan" : "-"}
        </div>
      ),
    },
    {
      name: "status",
      label: "Status",
      renderData: (row) => (
        <span
          className={`${Styles.status} ${
            row?.status ? Styles[row?.status?.toLowerCase()] : ""
          }`}
        >
          {row?.status || "-"}
        </span>
      ),
    },
    {
      name: "",
      label: "",
      renderData: (row) => (
        <div className={Styles.rowActions}>
          <button
            className={Styles.deleteButton}
            onClick={() => {
              setSelectedContract(row);
              setShowAddNewContracts(true);
            }}
          >
            <Icon icon={"edit"} size={20} color={"#1571DE"} />
          </button>
          {!row?.contractID && (
            <button
              className={Styles.deleteButton}
              onClick={() =>
                setContracts(contracts?.filter((obj) => obj?.id !== row?.id))
              }
            >
              <Icon icon={"trash"} size={20} color={"#FF3E13"} />
            </button>
          )}
        </div>
      ),
    },
  ];

  const lastUpdate = useMemo(() => {
    return new Date(flbData?.logDate);
  }, [flbData?.logDate]);

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      {flbData?.logDate && (
        <div className={Styles.info}>
          <img
            alt=""
            src={Images.BIG_C}
            className={`${Styles.big} ${Styles.c1}`}
          />
          <img
            alt=""
            src={Images.BIG_C}
            className={`${Styles.big} ${Styles.c2}`}
          />
          <img
            alt=""
            src={Images.BIG_C}
            className={`${Styles.small} ${Styles.c3}`}
          />
          {width > 768 && (
            <div className={Styles.logo}>
              <Icon icon={"information-outline"} size={20} />
            </div>
          )}
          <div className={Styles.desc}>
            <span>Informasi Detail FLB</span>
            <p>{`Data FLB yang ditampilkan sekarang masih menggunakan data FLB sebelumnya. Karena perubahan terakhir pada ${moment(
              lastUpdate
            )
              ?.format("DD MMMM YYYY, h:mm")
              ?.replace(",", " pukul")} belum disetujui oleh HCBP.`}</p>
            <button onClick={() => setShowLog(true)}>
              Lihat Perubahan yang Masih Menunggu Persetujuan
            </button>
          </div>
        </div>
      )}

      <div className={Styles.header}>
        <h3>Choose Contract Type</h3>
        <span id="Tipe Kontrak">Pilih tipe kontrak sesuai kebutuhan anda</span>
      </div>
      <div className={Styles.content}>
        {options?.map((type, it) => {
          const isType = Boolean(type?.title === form?.contract);
          return (
            <div
              key={it}
              onClick={() => onChange(type)}
              className={`${Styles.card} ${isType && Styles.active} ${
                alertMessage?.length > 0 &&
                alertMessage?.find(
                  (el) =>
                    el?.remark === "Tipe Kontrak" &&
                    el.validateContract === true
                ) &&
                !form?.contract &&
                Styles.borderRed
              }`}
            >
              <div
                className={`${Styles.IconWrapper} ${
                  isType ? Styles.selected : Styles.notSelected
                }`}
              >
                <Icon
                  icon="check-circle"
                  size={25}
                  className={Styles?.CheckIcon}
                />
              </div>
              <div className={Styles.cardHeader}>
                <h4>{type?.title}</h4>
                <span>{type?.label}</span>
              </div>
              <div className={Styles.cardContent}>
                {type?.rules?.map((rule, ir) => {
                  return (
                    <div key={ir} className={Styles.rule}>
                      <img src={Images.CHECK_MARK} alt="check" />
                      <p>{rule?.desc}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>

      <div
        id="Dokumen Legal"
        className={`${Styles.contracts} ${
          alertMessage?.length > 0 &&
          alertMessage?.find(
            (el) =>
              el?.remark === "Dokumen Legal" && el?.validateContracts === true
          ) &&
          !contracts?.length
            ? Styles.errorBorder
            : ""
        }`}
      >
        <div className={Styles.cHeader}>
          <h3>Dokumen Legal</h3>
          {!!contracts?.length && (
            <button
              className={Styles.contractButton}
              onClick={() => setShowAddNewContracts(true)}
            >
              Update Dokumen
            </button>
          )}
        </div>
        <div className={Styles.table}>
          <CustomTable
            redBorder={
              alertMessage?.includes("Mohon untuk memasukkan attachment") ||
              alertMessage?.includes(
                "Mohon untuk memasukkan document number"
              ) ||
              alertMessage?.includes("Mohon untuk memasukkan document type")
            }
            noPagination
            data={contracts}
            columns={columns}
            oddRowClassName={Styles.odd}
            customEmptyHandler={
              <div className={Styles.empty}>
                <img src={Images.TIME_MANAGEMENT} alt="" />
                <div>
                  <span>Belum Ada Dokumen Ditambahkan</span>
                  <p>
                    Anda belum menambahkan dokumen legal untuk kebutuhan orderan
                    klien.
                  </p>
                </div>
                <button
                  className={Styles.contractButton}
                  onClick={() => setShowAddNewContracts(true)}
                >
                  Contract Update
                </button>
              </div>
            }
          />
        </div>
      </div>
      {/* <div className={Styles.buttonWrapper}>
        <button onClick={onNext}>Next</button>
      </div> */}

      {showAddNewContracts && (
        <UpdateContractModal
          show={showAddNewContracts}
          defaultValue={selectedContract}
          onClose={() => {
            setShowAddNewContracts(false);
            setSelectedContract(null);
          }}
          onAdd={({
            docType,
            docNumber,
            startDate,
            endDate,
            file,
            preview,
            remark,
          }) => {
            // console.log(preview, 'ppp')
            if (!selectedContract) {
              setContracts([
                ...contracts,
                {
                  id: makeRandomString(5),
                  docType,
                  docNumber,
                  startDate,
                  endDate,
                  file,
                  uploadedAt: new Date(),
                  preview,
                  remark,
                },
              ]);
            } else {
              setContracts(
                contracts?.map((obj) => {
                  if (obj?.id === selectedContract?.id) {
                    return {
                      ...obj,
                      docType,
                      docNumber,
                      startDate,
                      endDate,
                      file,
                      preview,
                      remark,
                    };
                  }
                  return obj;
                })
              );
            }
          }}
        />
      )}

      <ModalWrapper show={showRemark} handleClose={() => {}}>
        <DetailRemarkModal
          data={showRemark}
          handleClose={() => setShowRemark(null)}
        />
      </ModalWrapper>

      {showLog && (
        <FLBHistoryModal
          selectedFLB={flbData}
          onClose={() => setShowLog(false)}
          isEditScheme
        />
      )}
    </div>
  );
}
