/*eslint-disable import/no-anonymous-default-export */

export default {
  KERJA365_LIGHT: require("@Assets/Images/kerja365-light.png"),
  AVA_DEFAULT: require("@Assets/Images/ava-default.png"),
  ICON365_LIGHT: require("@Assets/Images/icon365-light.png"),
  PERMATA_LOGO: require("@Assets/Images/permata-logo.png"),
  CHECK_MARK: require("@Assets/Images/check-mark.png"),
  CLIENT_HEADER: require("@Assets/Images/client-header.png"),
  DEFAULT_COMPANY_PROFILE: require("@Assets/Images/default-company-profile.png"),
  JOB_OFFER_ONLINE: require("@Assets/Images/job-offer-online.png"),
  TIME_MANAGEMENT: require("@Assets/Images/time-management.png"),
  WAVE: require("@Assets/Images/wave.png"),
  FINISHED: require("@Assets/Images/finished.png"),
  HIRING_BANNER: require("@Assets/Images/hiring-banner.png"),
  BACKGROUND_LINEAR_GRADIENT: require("@Assets/Images/background-linear-gradient.png"),
  POSITION: require("@Assets/Images/position.png"),
  NOTE: require("@Assets/Images/note.png"),
  COMING_SOON: require("@Assets/Images/coming-soon.png"),
  OPENING_BANNER_ILLUSTRATION: require("@Assets/Images/opening-banner-illustration.png"),
  BENEFIT_IMAGE_1: require("@Assets/Images/BENEFIT_IMAGE_1.png"),
  BENEFIT_IMAGE_2: require("@Assets/Images/BENEFIT_IMAGE_2.png"),
  BENEFIT_IMAGE_3: require("@Assets/Images/BENEFIT_IMAGE_3.png"),
  MAN_POWER_ILLUSTRATION: require("@Assets/Images/MAN_POWER_ILLUSTRATION.png"),
  IT_ILLUSTRATION: require("@Assets/Images/IT_ILLUSTRATION.png"),
  PAYING_AGENT_ILLUSTRATION: require("@Assets/Images/PAYING_AGENT_ILLUSTRATION.png"),
  FREELANCE_ILLUSTRATION: require("@Assets/Images/FREELANCE_ILLUSTRATION.png"),
  AUTH_BG: require("@Assets/Images/auth-bg-2.png"),
  AGREEMENT_ICON: require("@Assets/Images/AGREEMENT_ICON.png"),
  BANKING: require("@Assets/Images/BANKING.png"),
  BUSSINESS: require("@Assets/Images/BUSSINESS.png"),
  CONSTRUCTION: require("@Assets/Images/CONSTRUCTION.png"),
  EASY_PLATFORM_ICON: require("@Assets/Images/EASY_PLATFORM_ICON.png"),
  ENERGY: require("@Assets/Images/ENERGY.png"),
  EXPERIENCE_ICON: require("@Assets/Images/EXPERIENCE_ICON.png"),
  HEALTH: require("@Assets/Images/HEALTH.png"),
  HINT_DOT_IMAGE: require("@Assets/Images/HINT_DOT.png"),
  INSURANCE: require("@Assets/Images/INSURANCE.png"),
  IT: require("@Assets/Images/IT.png"),
  JOB_AVAILABLE_ICON: require("@Assets/Images/JOB_AVAILABLE_ICON.png"),
  MACHINE: require("@Assets/Images/MACHINE.png"),
  MEDIA: require("@Assets/Images/MEDIA.png"),
  PRODUCT: require("@Assets/Images/PRODUCT.png"),
  PROFESSIONAL_TALENTS_ICON: require("@Assets/Images/PROFESSIONAL_TALENTS_ICON.png"),
  PROPERTY: require("@Assets/Images/PROPERTY.png"),
  REGISTRATION_ICON: require("@Assets/Images/REGISTRATION_ICON.png"),
  SIMULATION_ICON: require("@Assets/Images/SIMULATION_ICON.png"),
  TELECOMMUNICATION: require("@Assets/Images/TELECOMMUNICATION.png"),
  VERIFICATION_ICON: require("@Assets/Images/VERIFICATION_ICON.png"),
  DEDIKASI_BANGSA_IMAGE: require("@Assets/Images/DEDIKASI_BANGSA_IMAGE.png"),
  OPTIMALISASI_IMAGE: require("@Assets/Images/OPTIMALISASI_IMAGE.png"),
  PENGEMBANGAN_IMAGE: require("@Assets/Images/PENGEMBANGAN_IMAGE.png"),
  PERSON_HOLDING_TARGET_BOARD_IMAGE: require("@Assets/Images/PERSON_HOLDING_TARGET_BOARD_IMAGE.png"),
  TARAF_HIDUP_IMAGE: require("@Assets/Images/TARAF_HIDUP_IMAGE.png"),
  GET_ON_APPSTORE_IMAGE: require("@Assets/Images/GET_ON_APPSTORE_IMAGE.png"),
  GET_ON_GOOGLEPLAY_IMAGE: require("@Assets/Images/GET_ON_GOOGLEPLAY_IMAGE.png"),
  PERSONAL_AD_IMAGE: require("@Assets/Images/PERSONAL_AD_IMAGE.png"),
  KERJA365_WHITE: require("@Assets/Images/kerja365-white.png"),
  WHATSAPP_LOGO: require("@Assets/Images/whatsapp-logo.png"),
  PAYDAY: require("@Assets/Images/payday.png"),
  LIST: require("@Assets/Images/list.png"),
  NOTE_BOARD: require("@Assets/Images/note-board.png"),
  CHECK_LIST: require("@Assets/Images/check-list.png"),
  JOB_SEEKERS: require("@Assets/Images/job-seekers.png"),
  BOTTOM_LEFT_ARROW: require("@Assets/Images/BOTTOM_LEFT_ARROW.png"),
  BOTTOM_RIGHT_ARROW: require("@Assets/Images/BOTTOM_RIGHT_ARROW.png"),
  BOTTOM_ARROW: require("@Assets/Images/BOTTOM_ARROW.png"),
  HEAD_HUNTER: require("@Assets/Images/HEAD_HUNTER.png"),
  JOB_VACANCY: require("@Assets/Images/JOB_VACANCY.png"),
  OUTSOURCING: require("@Assets/Images/OUTSOURCING.png"),
  PERSONAL_SKILLS: require("@Assets/Images/PERSONAL_SKILLS.png"),
  PHONE_LAYOUT: require("@Assets/Images/PHONE_LAYOUT.png"),
  TOP_LEFT_ARROW: require("@Assets/Images/TOP_LEFT_ARROW.png"),
  TOP_RIGHT_ARROW: require("@Assets/Images/TOP_RIGHT_ARROW.png"),
  NEWS: require("@Assets/Images/NEWS.png"),
  CIRCLE_DIVIDER: require("@Assets/Images/CIRCLE_DIVIDER.png"),
  STEP_1: require("@Assets/Images/STEP_1.png"),
  STEP_2: require("@Assets/Images/STEP_2.png"),
  STEP_3: require("@Assets/Images/STEP_3.png"),
  VERTICAL_DIVIDER: require("@Assets/Images/VERTICAL_DIVIDER.png"),
  RED_LIST: require("@Assets/Images/red-list.png"),
  CIRCLE_TICK: require("@Assets/Images/circle-tick.png"),
  INITIAL_LOGO: require("@Assets/Images/initial-logo.png"),
  APPLIED_INACTIVE: require("@Assets/Images/applied-inactive.png"),
  APPLIED_ACTIVE: require("@Assets/Images/applied-active.png"),
  OFFERING_INACTIVE: require("@Assets/Images/offering-inactive.png"),
  OFFERING_ACTIVE: require("@Assets/Images/offering-active.png"),
  NOTE_SHEET: require("@Assets/Images/note-sheet.png"),
  FOLDER: require("@Assets/Images/folder.png"),
  LOCK_1: require("@Assets/Images/lock-1.png"),
  LOCK_2: require("@Assets/Images/lock-2.png"),
  LOCK_3: require("@Assets/Images/lock-3.png"),
  INVITE_USER: require("@Assets/Images/invite-user.png"),
  INVITE_JOB: require("@Assets/Images/invite-job.png"),
  CANDIDATE_FORM_BANNER: require("@Assets/Images/candidate-form-zero.png"),
  FAMILY_BANNER: require("@Assets/Images/family-banner.png"),
  EDUCATION_BANNER: require("@Assets/Images/education-banner.png"),
  JOB_BANNER: require("@Assets/Images/job-banner.png"),
  PERFORMANCE_BANNER: require("@Assets/Images/performance-banner.png"),
  DOCUMENT_BANNER: require("@Assets/Images/document-banner.png"),
  KONTRAK: require("@Assets/Images/kontrak.png"),
  ELLIPSIS_HORIZONTAL_CIRCLE: require("@Assets/Images/ellipsis-horizontal-circle.png"),
  LOG_ICON: require("@Assets/Images/log-icon.png"),
  LOGO_404: require("@Assets/Images/404.png"),
  PDF_BACKGROUND: require("@Assets/Images/pdf-bg.png"),
  PERMATA_LOGO_FULL: require("@Assets/Images/permata-logo-full.png"),
  PDF_CONTRACT_FOOTER: require("@Assets/Images/pdf-contract-footer.png"),
  ID_PHOTO_EXAMPLE: require("@Assets/Images/id-photo-example.png"),
  TEXT_LOGO: require("@Assets/Images/text-logo.png"),
  DOCUMENTS_FOLDER: require("@Assets/Images/documents-folder.png"),
  POST_IT_ICON: require("@Assets/Images/post-it.png"),
  BAG_BLACK: require("@Assets/Images/bag-black.png"),
  BAG_BLUE: require("@Assets/Images/bag-blue.png"),
  RESULT_ACCEPTED_ICON: require("@Assets/Images/result-accepted.png"),
  RESULT_REJECTED_ICON: require("@Assets/Images/result-rejected.png"),
  DEADLINE_ICON: require("@Assets/Images/deadline_icon.png"),
  APPLE_WHITE_ICON: require("@Assets/Images/apple_white_icon.png"),
  ANDROID_WHITE_ICON: require("@Assets/Images/android-white-icon.png"),
  PLAYSTORE_OUTLINED_WHITE: require("@Assets/Images/playstore_outlined_white.png"),
  RESUME_ICON: require("@Assets/Images/resume_icon.png"),
  TEMPLATE_IPHONE: require("@Assets/Images/template_iphone.png"),
  LOGO_PERMATA_FOOTER: require("@Assets/Images/permata-logo-2.png"),
  MOBILE_APP_SCREEN_1: require("@Assets/Images/mobile-app-screen-1.png"),
  MOBILE_APP_SCREEN_2: require("@Assets/Images/mobile-app-screen-2.png"),
  MOBILE_APP_SCREEN_3: require("@Assets/Images/mobile-app-screen-3.png"),
  INDONESIA_GLOBE: require("@Assets/Images/indonesia-globe.png"),
  GOOGLE_PLAY_WHITE_ICON: require("@Assets/Images/google-play-white-icon.png"),
  JOB_NOT_FOUND: require("@Assets/Images/job-not-found.png"),
  DOUBLE_QUOTE: require("@Assets/Images/double-quote.png"),
  SHOWCASE_IMAGE: require("@Assets/Images/showcase-image.png"),
  contract_logo: require("@Assets/Images/contract_logo.png"),
  contract_logo_gtc: require("@Assets/Images/contract_logo_gtc.png"),
  contract_address: require("@Assets/Images/contract_address.png"),
  footer_old: require("@Assets/Images/footer _old.jpg"),
  footer_red: require("@Assets/Images/footer_red.jpg"),
  footer: require("@Assets/Images/footer.jpg"),
  INFORMATION_SOLID: require("@Assets/Images/information-solid.png"),
  K365_DARKER: require("@Assets/Images/k365-darker.png"),
  PP_DEFAULT: require("@Assets/Images/pp-default.png"),
  DOUBLE_WAVE: require("@Assets/Images/double-wave.png"),
  MWS_LOGO: require("@Assets/Images/mws-logo.png"),
  KERJA365_TEXTWHITE: require("@Assets/Images/logo-365-textwhite.png"),
  BG_TUTORIAL: require("@Assets/Images/bg-tutorial.png"),
  CORRECT_SELFIE: require("@Assets/Images/correct-selfie.png"),
  INCORRECT_SELFIE: require("@Assets/Images/incorrect-selfie.png"),
  CORRECT_DOCUMENT: require("@Assets/Images/correct-document.png"),
  INCORRECT_DOCUMENT: require("@Assets/Images/incorrect-document.png"),
  COMPLETED: require("@Assets/Images/completed.png"),
  ROTATE: require("@Assets/Images/rotate.png"),
  CANDIDATE_SEARCH: require("@Assets/Images/candidate-search.png"),
  LOADER: require("@Assets/Images/loader.png"),
  LOADER_V2: require("@Assets/Images/loader-v2.png"),
  ERROR_STATUS: require("@Assets/Images/error-status.png"),
  lampiran_delami: require("@Assets/Images/lampiran_delami.png"),
  lampiran_delami_1: require("@Assets/Images/lampiran_delami_1.png"),
  lampiran_delami_2: require("@Assets/Images/lampiran_delami_2.png"),
  EMPTY_PAGEL: require("@Assets/Images/empty-page .png"),
  LOCATION_ICON: require("@Assets/Images/seacrh-location.png"),
  AMICO: require("@Assets/Images/amico.png"),
  AMICO2: require("@Assets/Images/amico-2.png"),
  SL_BG: require("@Assets/Images/sl-bg.png"),
  FINISHED_V2: require("@Assets/Images/finished-v2.png"),
  FILE_AND_FOLDER: require("@Assets/Images/file-and-folder.png"),
  ERROR_PORTAL: require("@Assets/Images/error-portal.png"),
  CONCEPT_OF_UNKNOWN_THINGS: require("@Assets/Images/concept_of_unknown_things.png"),
  BUILDING: require("@Assets/Images/building.png"),
  SORRY: require("@Assets/Images/sorry.png"),
  FLB_APPROVED: require("@Assets/Images/flb-approved.png"),
  FLB_REJECTED: require("@Assets/Images/flb-rejected.png"),
  FLB_WAITING: require("@Assets/Images/flb-waiting.png"),
  NO_DATA: require("@Assets/Images/no-data.png"),
  CHARACTER_INJECT: require(`@Assets/Images/character-1--inject-1.png`),
  PANA: require(`@Assets/Images/pana.png`),
  CONTACT_PLACEHOLDER: require(`@Assets/Images/contact-placeholder.png`),
  ADD_POSITION_LOGO: require(`@Assets/Images/add-position-logo.png`),
  APPROVED_LOGO: require(`@Assets/Images/approved-hc.png`),
  WAITING_LOGO: require(`@Assets/Images/waiting-hc.png`),
  LOC_DECOR: require(`@Assets/Images/loc-decor.png`),
  FILE_NULL: require(`@Assets/Images/file-null.png`),
  AMICO3: require(`@Assets/Images/amico-3.png`),
  STEPPER_1: require(`@Assets/Images/stepper-1.png`),
  STEPPER_2: require(`@Assets/Images/stepper-2.png`),
  STEPPER_3: require(`@Assets/Images/stepper-3.png`),
  STEPPER_ACTIVE_1: require(`@Assets/Images/stepper-active-1.png`),
  STEPPER_ACTIVE_2: require(`@Assets/Images/stepper-active-2.png`),
  STEPPER_ACTIVE_3: require(`@Assets/Images/stepper-active-3.png`),
  POLYGON_13: require(`@Assets/Images/Polygon-13.png`),
  FILE_SEARCHING: require(`@Assets/Images/rafiki.png`),
  BLOG_AND_ARTICLE: require(`@Assets/Images/blog-and-article.png`),
  COMPANY_OUTLINE: require(`@Assets/Images/company-outline.png`),
  BRIEFCASE_OUTLINE: require(`@Assets/Images/briefcase-outline.png`),
  HALF_EYE: require(`@Assets/Images/half-eye.png`),
  PRODUCT_QUALITY: require(`@Assets/Images/product-quality.png`),
  LINE_401: require(`@Assets/Images/Line-401.png`),
  CLOCK_BACK: require(`@Assets/Images/clock-back.png`),
  EXPIRED_LOGO: require(`@Assets/Images/expired-logo.png`),
  BIG_C: require(`@Assets/Images/big-c.png`),
  BLOG_AND_ARTICLE_2: require(`@Assets/Images/blog-and-article.png`),
  RAFIKI_2: require(`@Assets/Images/rafiki-2.png`),
  HIGH_BUILDING: require(`@Assets/Images/high-building.png`),
  GROUP_3456: require(`@Assets/Images/group-3456.png`),
  TUTORIAL_SIGN_SELFIE: require(`@Assets/Images/tutorial-sign-selfie.png`),
  TUTORIAL_SIGN_IDCARD: require(`@Assets/Images/tutorial-sign-idCard.png`),
  RAFIKI_MOBILE: require(`@Assets/Images/rafiki-mobile.png`),
  FOLDER_WARNING:require(`@Assets/Images/folder-warning.png`),
  DRAFT_LOGO: require(`@Assets/Images/draft-hc.png`)
};
