import SchemeEditFormNavigation from "@Organism/Manager/Client/WorkSchemes/SchemeEditForm/Navigation";
import SchemeProceedFormLayout from "@Organism/Manager/Client/WorkSchemes/SchemeProceed";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { orderSync } from "@Services/manager/client";
import { getPipelineOrderDetails } from "@Services/manager/pipeline";
import { Suspense, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Await, defer, useLoaderData } from "react-router-dom";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import useQuery from "@Hooks/useQuery";
import SchemeDetailModal from "@Molecule/_modal/SchemeDetailModal";
import LoadingSkeleton from "../../AddNewOrder/Skeleton";

export async function loader(route) {
  const params = new URLSearchParams(route?.request?.url);
  const id = params?.get("i");
  // const code = params.get("c") || '';

  const packageOrderSyncPromise = orderSync("SERVICE");
  const packagePipelineOrderDetailPromise = getPipelineOrderDetails(id);
  // const packageClientContactPromise = getAllContactsByClientCode(code);

  return defer({
    packageOrderSync: packageOrderSyncPromise,
    packageOrderDetails: packagePipelineOrderDetailPromise,
    // packageClientContacts: packageClientContactPromise,
  });
}

export default function SchemeProceedForm() {
  const { packageOrderSync, packageOrderDetails } = useLoaderData();
  const query = useQuery();

  const [alertMessage, setAlertMessage] = useState([]);
  const defaultStep = sessionStorage.getItem("orderStepServices");
  const [currentStep, setCurrentStep] = useState(
    defaultStep ? +defaultStep : 1
  );
  const [positionsForm, setPositionsForm] = useState([]);
  const [activeCityID, setActiveCityID] = useState("");
  const [activePositionID, setActivePositionID] = useState("");
  const [activeExactPositionID, setActiveExactPositionID] = useState("");
  const [showSideBar, setShowSideBar] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("orderStepServices", currentStep);
  }, [currentStep]);

  const [headerHeight, setHeaderHeight] = useState();
  const headerRef = useRef();

  const [defaultFourth, setDefaultFourth] = useState(
    sessionStorage.getItem("defaultFourthProceed")
      ? JSON?.parse(sessionStorage?.getItem("defaultFourthProceed"))
      : null
  );

  const getHeight = () => {
    const newHeight = headerRef?.current?.clientHeight;
    setHeaderHeight(newHeight);
  };

  useEffect(() => {
    getHeight();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getHeight);
  }, []);

  const [stepsOptions, setStepsOptions] = useState([
    {
      id: 1,
      label: "Deployment",
      isDone: true,
    },
    {
      id: 2,
      label: "Position",
      isDone: true,
      // warning: true,
    },
  ]);

  const handleClickStep = (step) => {
    if (currentStep > step) {
      setCurrentStep(step);
    }
  };

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Scheme Edit Form</title>
      </Helmet>

      <div className={Styles.headerWrapper} ref={headerRef}>
        <div className={Styles.navigationWrapper}>
          <SchemeEditFormNavigation
            stepsOptions={stepsOptions}
            currentStep={currentStep}
            // contractType={contractType}
            handleClickStep={handleClickStep}
            isProceed
            defaultFourth={defaultFourth}
            setViewDetails={setViewDetails}
          />
        </div>
        {alertMessage?.length > 0 ? (
          <div className={Styles.infoWrapper}>
            <div className={Styles.left}>
              <div className={Styles.icon}>
                <Icon icon={"alert-solid"} size={20} color={"#F5610D"} />
              </div>

              <span>
                Terdapat data yang belum diisi, silahkan lengkapi data dibawah
                untuk dapat melanjutkan ke step selanjutnya
              </span>
            </div>

            <div
              className={Styles.buttonRight}
              onClick={() => {
                if (alertMessage?.length > 1) {
                  setShowSideBar(true);
                } else {
                  if (
                    alertMessage[0]?.name?.includes("Data Business Manager") ||
                    alertMessage[0]?.name?.includes("Data Recruitment Officer")
                  ) {
                    setActiveCityID(alertMessage[0]?.cityCode);
                  }

                  if (
                    alertMessage[0]?.name?.includes(
                      "Belum ada posisi diaktifkan"
                    ) ||
                    alertMessage[0]?.name?.includes("Tempo salary") ||
                    alertMessage[0]?.name?.includes("Nominal salary") ||
                    alertMessage[0]?.name?.includes(
                      "Expected fulfillment date"
                    ) ||
                    alertMessage[0]?.name?.includes("Personal Equipment") ||
                    alertMessage[0]?.name?.includes("Allowances")
                  ) {
                    setActivePositionID(alertMessage[0]?.positionId);
                    setActiveExactPositionID(
                      alertMessage[0]?.positionId +
                        "-" +
                        alertMessage[0]?.cityId
                    );
                    setPositionsForm(
                      positionsForm?.map((obj) =>
                        obj?.id === alertMessage[0]?.positionId
                          ? {
                              ...obj,
                              isExpanded: true,
                            }
                          : {
                              ...obj,
                              isExpanded: false,
                            }
                      )
                    );
                  }

                  const element = document.getElementById(
                    `${alertMessage[0]?.remark}`
                  );
                  if (element) {
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "start",
                    });
                  }
                }
              }}
            >
              <span>
                {alertMessage?.length === 1
                  ? "Lengkapi Data"
                  : "Lihat Daftar Data Kosong"}
              </span>
              <div>
                <Icon icon={"arrow-up-right"} size={20} color={"#1571DE"} />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className={Styles.layoutWrapper}>
        <Suspense fallback={<LoadingSkeleton />}>
          <Await
            resolve={packageOrderSync}
            errorElement={
              <div className={Styles.errorWrapper}>
                <AsyncErrorBoundary />
              </div>
            }
          >
            {(packageOrderSync) => (
              <Suspense
                fallback={
                  <div className={Styles.loadingWrapper}>
                    <LoadingSkeleton />
                  </div>
                }
              >
                <Await
                  resolve={packageOrderDetails}
                  errorElement={
                    <div className={Styles.errorWrapper}>
                      <AsyncErrorBoundary />
                    </div>
                  }
                >
                  {(packageOrderDetails) => (
                    <SchemeProceedFormLayout
                      syncData={packageOrderSync?.response}
                      defaultOrderForm={packageOrderDetails?.response}
                      steps={stepsOptions}
                      setSteps={setStepsOptions}
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      headerHeight={headerHeight}
                      showSideBar={showSideBar}
                      setShowSideBar={setShowSideBar}
                      alertMessage={alertMessage}
                      setAlertMessage={setAlertMessage}
                      previousButtonText={
                        currentStep !== 1
                          ? stepsOptions[+currentStep - 2]?.label
                          : ""
                      }
                      nextButtonText={
                        currentStep !== 2
                          ? stepsOptions[+currentStep]?.label
                          : ""
                      }
                      activeCityID={activeCityID}
                      setActiveCityID={setActiveCityID}
                      activePositionID={activePositionID}
                      setActivePositionID={setActivePositionID}
                      activeExactPositionID={activeExactPositionID}
                      setActiveExactPositionID={setActiveExactPositionID}
                      positionsForm={positionsForm}
                      setPositionsForm={setPositionsForm}
                      defaultFourth={defaultFourth}
                      setDefaultFourth={setDefaultFourth}
                      setViewDetails={setViewDetails}
                    />
                  )}
                </Await>
              </Suspense>
            )}
          </Await>
        </Suspense>
      </div>

      {viewDetails && (
        <SchemeDetailModal
          setShowDetailFlb={setViewDetails}
          onBack={() => setViewDetails(false)}
          handleDone={() => setViewDetails(false)}
          showDetailFlb={query?.get("i")}
          isBM
        />
      )}
    </div>
  );
}
