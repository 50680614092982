/* eslint-disable no-unused-vars */
import ReasonRejectModal from "@Molecule/_modal/ReasonRejectModal";
import SendingModal from "@Molecule/_modal/SendingModal";
import { rejectJoinCandidate } from "@Services/support/candidate";
import Images from "@Theme/Images";
import { useEffect, useMemo, useReducer, useState } from "react";
import Styles from "./style.module.scss";
import moment from "moment";
import WA_ICON from "@Assets/Images/whatsapp-logo.png";
import Icon from "@Atom/Icon";
import { IDR } from "@Helpers/IDR";
import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import ModalWrapper from "@Atom/ModalWrapper";
import OTPConfirmationModal from "@Molecule/_modal/OTPConfirmationModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useQuery from "@Hooks/useQuery";
import { sendOTP } from "@Services/officer/recruitment";
import LoadingModal from "@Molecule/_modal/LoadingModal";

export default function Zero({
  setCurrentStep,
  disableClick,
  offeringData,
  refetch,
  type,
  isPhoneVerified,
  setIsPhoneVerified,
}) {
  const [rejectModal, setRejectModal] = useState(false);
  const [values, setValues] = useState({
    remark: "",
  });

  const query = useQuery();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isSent, setIsent] = useState(false);
  const [isloadingUpdate, setIsloadingUpdate] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [trigger, setTrigger] = useState(null);
  const [error, setError] = useState(null);

  const onRejectCandidate = async (e) => {
    e.preventDefault();
    try {
      setIsloadingUpdate(true);
      setIsProcessing(true);
      setRejectModal(false);
      await rejectJoinCandidate({
        candidateID: offeringData?.id,
        cancellationNote: values.remark,
      });
      setIsent(true);
      setIsloadingUpdate(false);
      refetch();
      // setTrigger(makeRandomString(5));
      // refetchData();
    } catch (error) {
      console.log(error);
      setError(error?.response?.data?.error || "Something went wrong");
      setIsloadingUpdate(false);
    }
  };

  const handleDone = () => {
    setIsProcessing(false);
    setTrigger(Math.random());
    setValues({
      remark: "",
    });
    refetch();
  };

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const grossSalary = useMemo(() => {
    const allowances =
      offeringData?.CandidateOffering?.CandidateAllowanceOfferings;
    let total = 0;
    for (let i = 0; i < allowances?.length; i++) {
      const a = allowances[i];
      if (a?.denom?.toLowerCase() === "harian") {
        total += +a?.value * 20;
      } else if (a?.denom?.toLowerCase() === "mingguan") {
        total += +a?.value * 4;
      } else {
        total += +a?.value;
      }
    }
    return total;
  }, [offeringData?.CandidateOffering?.CandidateAllowanceOfferings]);

  const [agreed, setAgreed] = useState(false);
  const [notesMode, setNotesMode] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log(offeringData?.phoneNumber);

  const handleSendOTP = async () => {
    try {
      setLoading(true);
      const { response } = await sendOTP(offeringData?.phoneNumber);
      navigate(`${pathname}?h=${response?.hash}&v=true`, { replace: true });
      setLoading(false);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error || "something went wrong";
      setLoading(false);
      setError(errorMessage);
      console.log("error sending OTP", errorMessage);
    }
  };

  const toggleOfferingNotesMode = () => {
    setIsPhoneVerified(true);
    navigate(`${pathname}?h=&v=false`, {
      replace: true,
    });
    setNotesMode(true);
  };

  // const toggleNotesMode = () => {
  // setNotesMode(!notesMode);
  // forceUpdate(); // Memicu re-rendering
  // };

  const [showOffering, setShowOffering] = useState(false);

  if (notesMode) {
    return (
      <div className={Styles.notesContainer}>
        <div className={Styles.header}>
          <img src={Images.KERJA365_LIGHT} alt="" />
          <div className={Styles.title}>
            <h3>Beberapa Hal yang Perlu Anda Pahami Sebelum Mengisi Form.</h3>
            <span>
              Silahkan pahami panduan pengisian form yang baik dan benar dibawah
              ini, Kami harap anda dapat mematuhi seluruh panduan yang telah
              kami berikan.
            </span>
          </div>
        </div>

        <div className={Styles.content}>
          <div className={Styles.each}>
            <span>Upload Foto</span>
            <p>
              Foto wajib foto terbaru dengan menggunakan pakaian rapi dan sopan.
            </p>
            <div className={Styles.box}>
              <div>
                <img src={Images.CORRECT_SELFIE} alt="" />
                <Icon icon={"check-circle"} size={52} color="#27BB83" />
              </div>
              <div>
                <img src={Images.INCORRECT_SELFIE} alt="" />
                <Icon icon={"cross-circle"} size={52} color={"#FF3E13"} />
              </div>
            </div>
          </div>
          <div className={Styles.each}>
            <span>Upload Dokumen yang Layak</span>
            <p>
              Foto wajib foto terbaru dengan menggunakan pakaian rapi dan sopan.
            </p>
            <div className={Styles.box}>
              <div>
                <img src={Images.CORRECT_DOCUMENT} alt="" />
                <Icon icon={"check-circle"} size={52} color="#27BB83" />
              </div>
              <div>
                <img src={Images.INCORRECT_DOCUMENT} alt="" />
                <Icon icon={"cross-circle"} size={52} color={"#FF3E13"} />
              </div>
            </div>
          </div>
        </div>

        <button className={Styles.button} onClick={() => setCurrentStep(1)}>
          Lanjut Pengisian Form
        </button>
      </div>
    );
  }

  // console.log(offeringData?.tag);

  return (
    <div className={Styles.container}>
      <div className={Styles.left}>
        <div className={Styles.topWrapper}>
          <div className={Styles.logoWrapper}>
            <img src={Images.INITIAL_LOGO} alt="logo" />
          </div>
          <span>PT Permata Indo Sejahtera</span>
        </div>
        <div className={Styles.box}>
          <img src={Images.CANDIDATE_FORM_BANNER} alt="banner" />
          <div className={Styles.textLeft}>
            <div className={Styles.nameOffer}>
              <h3>Selamat!</h3>
              <span className={Styles.nameCandidate}>{offeringData?.name}</span>
            </div>
            <div className={Styles.descBox}>
              <span className={Styles.desc}>
                kamu telah diterima untuk bergabung bersama PT Permata Indo
                Sejahtera untuk posisi
              </span>
              <div className={Styles.position}>
                {offeringData?.ExpectedPosition?.name} 🎉
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.right}>
        <div className={Styles.header}>
          <h3>Surat Penawaran Kerja</h3>
          <p>
            {!disableClick
              ? `Selamat anda telah lolos dan diterima untuk bisa bekerja sebagai posisi ${offeringData?.ExpectedPosition?.name}. Silahkan baca dengan seksama tawaran yang kami berikan dan lanjut pengisian form data diri anda untuk melanjutkan proses rekrutmen.`
              : "Pengisian data dan dokumen anda telah selesai, pastikan jika data yang anda kirim telah sesuai dan jika terdapat kesalahan silahkan perbaiki sebelum batas waktu yang ditentutkan."}
          </p>
        </div>

        {!disableClick ? (
          <div className={Styles.content}>
            <div className={Styles.contractDate}>
              <span className={Styles.H4}>Tanggal Kontrak</span>
              <span className={Styles.p}>
                Dengan ini kami mengharapkan untuk anda dapat bergabung pada
                tanggal dibawah ini:
              </span>
              <div className={Styles.dateCard}>
                <Icon icon={"calendar-v2"} size={24} />
                <div className={Styles.range}>
                  <span>Tanggal Kontrak</span>
                  {type?.contractType?.toLowerCase() === "pkwt" ? (
                    <span>{`${moment(
                      new Date(offeringData?.CandidateOffering?.startDate)
                    )?.format("LL")} - ${moment(
                      new Date(offeringData?.CandidateOffering?.endDate)
                    )?.format("LL")}`}</span>
                  ) : (
                    <div className={Styles.pkmTextDate}>
                      Kontrak akan dimulai pada tanggal{" "}
                      {`${moment(
                        new Date(offeringData?.CandidateOffering?.startDate)
                      )?.format("LL")}`}{" "}
                      sampai waktu yang telah disepakati
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={Styles.offering}>
              <span className={Styles.H4}>Penawaran</span>
              <span className={Styles.p}>
                Berikut adalah{" "}
                {type?.contractType?.toLowerCase() === "pkwt"
                  ? "penawaran gaji"
                  : "rincian penghasilan"}{" "}
                serta tunjangan yang kami berikan kepada anda, silahkan baca dan
                dipahami secara seksama.
              </span>
              <div className={Styles.detailsCard}>
                <span>
                  {type?.contractType?.toLowerCase() === "pkwt"
                    ? "Rincian Gaji"
                    : "Rincian Penghasilan"}
                </span>
                <div className={Styles.cardHeader}>
                  <span>Rincian</span>
                  <span>Total amount(Rp)</span>
                </div>
                <div className={Styles.cardList}>
                  {offeringData?.CandidateOffering?.CandidateAllowanceOfferings?.map(
                    (a, aI) => (
                      <div key={aI} className={Styles.each}>
                        <div>
                          <Icon icon={"arrow-right"} size={20} />
                          <span>
                            {a?.label === "GAJI POKOK" &&
                            type?.contractType?.toLowerCase() === "pkwt"
                              ? "Gaji Pokok"
                              : a?.label === "GAJI POKOK" &&
                                type?.contractType?.toLowerCase() === "pkm"
                              ? "Bantuan Operasional"
                              : a?.label}
                          </span>
                          <span>{`/${a?.denom}`}</span>
                        </div>
                        <span>{IDR.format(a?.value)}</span>
                      </div>
                    )
                  )}
                </div>
                {type?.contractType?.toLowerCase() === "pkwt" ? (
                  <div>
                    <div className={Styles.divider} />
                    <div className={Styles.cardFooter}>
                      <span>Total Gaji (Gross)</span>
                      <span>{IDR.format(grossSalary)}</span>
                    </div>
                  </div>
                ) : null}

                <div></div>
              </div>
            </div>
            <div className={Styles.closure}>
              <span className={Styles.H4}>
                Yuk! lanjut ke pengisian form untuk mempercepat proses rekrutmen
                kamu
              </span>

              {/* <span className={Styles.p}>
            {offeringData?.cancellationNote === "EXPIRED OFFER"
              ? "Silahkan lamar posisi terkait yang mungkin kamu sukai."
              : offeringData?.cancellationNote !== null &&
                offeringData?.cancellationNote !== "EXPIRED OFFER"
                ? "Anda sudah tidak bisa join kembali"
                : "Klik tombol dibawah ini untuk melanjutkan ke pengisian formulir."}
          </span> */}

              <div className={Styles.timeExpOfferingCandidate}>
                <Icon icon={"time"} size={20} />
                <span>
                  {offeringData?.cancellationNote
                    ? offeringData?.cancellationNote?.includes("EXPIRED")
                      ? "Pengisian form sudah melewati batas waktu"
                      : offeringData?.cancellationNote
                    : `Batas waktu pengisian form sampai tanggal ${moment(
                        new Date(offeringData?.offerExpirationDate)
                      ).format("LL")} jam ${moment(
                        new Date(offeringData?.offerExpirationDate)
                      ).format("HH:mm A")}`}
                </span>
              </div>
              {!offeringData?.cancellationNote && (
                <div className={Styles.agreed}>
                  <CheckboxJobPosting
                    checked={agreed}
                    onChange={() => setAgreed(!agreed)}
                  />
                  <span>
                    Dengan ini saya menyetujui penawaran yang diberikan dan
                    memutuskan untuk melanjutkan proses berikutnya.
                  </span>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={Styles.finishBox}>
            {type?.type?.toLowerCase() === "normal" ? (
              <div className={Styles.showOfferingCard}>
                <div
                  className={Styles.button}
                  onClick={() => setShowOffering(!showOffering)}
                >
                  <Icon icon={"file"} size={24} />
                  <span>Lihat Detail Penawaran Kerja</span>
                  <Icon
                    icon={showOffering ? "arrow-up" : "arrow-down"}
                    size={24}
                  />
                </div>
                {showOffering && (
                  <div className={Styles.content}>
                    <div className={Styles.contractDate}>
                      <span className={Styles.H4}>Tanggal Kontrak</span>
                      <span className={Styles.p}>
                        Dengan ini kami mengharapkan untuk anda dapat bergabung
                        pada tanggal dibawah ini:
                      </span>
                      <div className={Styles.dateCard}>
                        <Icon icon={"calendar-v2"} size={24} />
                        <div className={Styles.range}>
                          <span>Tanggal Kontrak</span>
                          {type?.contractType?.toLowerCase() === "pkwt" ? (
                            <span>{`${moment(
                              new Date(
                                offeringData?.CandidateOffering?.startDate
                              )
                            )?.format("LL")} - ${moment(
                              new Date(offeringData?.CandidateOffering?.endDate)
                            )?.format("LL")}`}</span>
                          ) : (
                            <div className={Styles.pkmTextDate}>
                              Kontrak akan dimulai pada tanggal{" "}
                              {`${moment(
                                new Date(
                                  offeringData?.CandidateOffering?.startDate
                                )
                              )?.format("LL")}`}{" "}
                              sampai waktu yang telah disepakati
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={Styles.offering}>
                      <span className={Styles.H4}>Penawaran</span>
                      <span className={Styles.p}>
                        Berikut adalah{" "}
                        {type?.contractType?.toLowerCase() === "pkwt"
                          ? "penawaran gaji"
                          : "rincian penghasilan"}{" "}
                        serta tunjangan yang kami berikan kepada anda, silahkan
                        baca dan dipahami secara seksama.
                      </span>
                      <div className={Styles.detailsCard}>
                        <span>
                          {type?.contractType?.toLowerCase() === "pkwt"
                            ? "Rincian Gaji"
                            : "Rincian Penghasilan"}
                        </span>
                        <div className={Styles.cardHeader}>
                          <span>Rincian</span>
                          <span>Total amount(Rp)</span>
                        </div>
                        <div className={Styles.cardList}>
                          {offeringData?.CandidateOffering?.CandidateAllowanceOfferings?.map(
                            (a, aI) => (
                              <div key={aI} className={Styles.each}>
                                <div>
                                  <Icon icon={"arrow-right"} size={20} />
                                  <span>
                                    {a?.label === "GAJI POKOK" &&
                                    type?.contractType?.toLowerCase() === "pkwt"
                                      ? "Gaji Pokok"
                                      : a?.label === "GAJI POKOK" &&
                                        type?.contractType?.toLowerCase() ===
                                          "pkm"
                                      ? "Bantuan Operasional"
                                      : a?.label}
                                  </span>
                                  <span>{`/${a?.denom}`}</span>
                                </div>
                                <span>{IDR.format(a?.value)}</span>
                              </div>
                            )
                          )}
                        </div>
                        {type?.contractType?.toLowerCase() === "pkwt" ? (
                          <div>
                            {" "}
                            <div className={Styles.divider} />
                            <div className={Styles.cardFooter}>
                              <span>
                                {type?.contractType?.toLowerCase() === "pkwt"
                                  ? "Total Gaji"
                                  : "Total Penghasilan"}{" "}
                                (Gross)
                              </span>
                              <span>{IDR.format(grossSalary)}</span>
                            </div>
                          </div>
                        ) : null}

                        <div></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : null}

            {/* {offeringData?.status === "BS-PRE-CONTRACT" ||
            offeringData?.status === "BS-CONTRACT" ? null : (
              <div className={Styles.buttonDecline}>
                <button
                  className={`${Styles.buttonNext}`}
                  // disabled={}
                  onClick={() => setNotesMode(true)}
                >
                  Perbaiki Data
                </button>
              </div>
            )} */}
          </div>
        )}

        <div className={Styles.buttonBox}>
          {/* format('h:mma') */}
          {offeringData?.cancellationNote ? (
            <div
              className={Styles.buttonContact}
              onClick={() => window.open("https://wa.me/6282310997063")}
            >
              <div className={Styles.icon}>
                <img src={WA_ICON} height={21.33} width={21.33} alt="" />
              </div>
              Contact Us
            </div>
          ) : (
            <div style={{ width: "100%" }}>
              {offeringData?.status === "BS-PRE-CONTRACT" ||
              offeringData?.status ===
                "BS-CONTRACT" ? null : offeringData?.status ===
                "BS-REGISTERED" ? (
                <button
                  className={`${Styles.buttonNext}`}
                  // disabled={disableClick || !agreed}
                  // onClick={toggleNotesMode}
                  onClick={handleSendOTP}
                >
                  Perbaiki Data
                </button>
              ) : (
                <button
                  className={`${Styles.buttonNext}`}
                  disabled={disableClick || !agreed}
                  // onClick={toggleNotesMode}
                  onClick={handleSendOTP}
                >
                  Selanjutnya
                </button>
              )}
            </div>
          )}

          {disableClick || offeringData.cancellationNote ? null : (
            <div
              className={Styles.buttonReject}
              onClick={() => setRejectModal(true)}
            >
              Maaf Saya Belum Bisa Join
            </div>
          )}
        </div>

        {rejectModal && (
          <ReasonRejectModal
            onClickCancel={() => setRejectModal(false)}
            setValues={setValues}
            value={values.remark}
            handleChangeInput={handleChangeInput}
            onClickBack={() => setRejectModal(false)}
            onRejectCandidate={onRejectCandidate}
          />
        )}

        {isProcessing && (
          <SendingModal
            handleDone={handleDone}
            isDone={isSent}
            show={isProcessing}
            error={error}
            setShow={setIsProcessing}
            setError={setError}
          />
        )}

        {query.get("v") === "true" ? (
          <ModalWrapper show={query.get("v") === "true"} forceCenter>
            <div
              style={{
                visibility: query.get("v") === "true" ? "visible" : "hidden",
              }}
            >
              <OTPConfirmationModal
                handleSubmit={toggleOfferingNotesMode}
                data={offeringData}
                errorApi={error}
                loading={loading}
              />
            </div>
          </ModalWrapper>
        ) : (
          loading && <LoadingModal />
        )}
      </div>
    </div>
  );
}

// disabled={disableClick}
