/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import Icon from "@Atom/Icon";
import UseOutsideClick from "@Hooks/useOutsideClick";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SelectModal from "./SelectModal";
import ViewDetailModal from "./ViewDetailModal";
import Styles from "./style.module.scss";
import CurrencyInput from "react-currency-input-field";
import FormErrorMessage from "@Atom/FormErrorMessage";
import { CostEstimation } from "@Helpers/costEstimation";

let IDR = new Intl.NumberFormat("id-ID", {
  style: "currency",
  currency: "IDR",
  minimumFractionDigits: 0,
});

const getSalaryInMonth = (periodStr, baseSalary) => {
  switch (periodStr) {
    case "Harian":
      return +baseSalary * 20;
    case "Mingguan":
      return +baseSalary * 4;
    default:
      return +baseSalary;
  }
};

const getPeriodText = (periodStr) => {
  switch (periodStr) {
    case "Harian":
      return "Harian x 20";
    case "Mingguan":
      return "Mingguan x 4";
    default:
      return "Bulanan";
  }
};

const TABLE_HEAD = [
  { id: "title", label: "Man power", align: "left", disablePadding: true },
  { id: "demand", label: "Total", align: "center", disablePadding: false },
  { id: "salary", label: "Salary", align: "right", disablePadding: false },
  {
    id: "salaryInMonth",
    label: "Salary in Month",
    align: "right",
    disablePadding: false,
  },
  {
    id: "personalEquipments",
    numeric: true,
    label: "Personal Work Equipment",
    align: "right",
    disablePadding: false,
  },
  {
    id: "allowance",
    numeric: true,
    label: "Allowance",
    align: "right",
    disablePadding: false,
  },
  {
    id: "bpjs",
    numeric: true,
    label: "BPJS",
    align: "right",
    disablePadding: false,
  },
  {
    id: "totalCost",
    numeric: true,
    label: "Total Cost",
    align: "right",
    disablePadding: true,
  },
];
const SUMMARY_TABLE_HEAD = [
  { id: "cityName", label: "Kota", align: "left", disablePadding: true },
  { id: "subTotal", label: "Sub Total", align: "right", disablePadding: false },
  {
    id: "areaWorkEquipments",
    numeric: true,
    label: "Area Work Equipment",
    align: "right",
    disablePadding: false,
  },
  {
    id: "total",
    numeric: true,
    label: "Total Biaya",
    align: "right",
    disablePadding: false,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, firstColMinWidth, isSummary } = props;
  const tableHead = isSummary ? SUMMARY_TABLE_HEAD : TABLE_HEAD;
  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#fff",
        },
      }}
    >
      <TableRow>
        {tableHead.map((headCell, index) => (
          <TableCell
            key={index}
            align={headCell.align ? headCell?.align : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={
              index === 0
                ? `${Styles.stickyLeft} ${Styles.tableHeadCell}`
                : index === TABLE_HEAD.length - 1
                ? `${Styles.stickyRight} ${Styles.HideOnMobile} ${Styles.tableHeadCell}`
                : `${Styles.HideOnMobile} ${Styles.tableHeadCell}`
            }
            sx={{ minWidth: index === 0 ? firstColMinWidth : "" }}
          >
            <span className={Styles.HeadcellLabel}>{headCell.label}</span>
          </TableCell>
        ))}
        <TableCell
          key={Math.random()}
          padding={"normal"}
          className={`${Styles.showOnMobile}`}
          align="right"
        >
          <span>Information</span>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export function DataTable({
  locationName,
  token,
  each,
  form,
  getPersonalEquipmentsNominal,
  getAllowancesNominal,
  getCleanAllowance,
  getBpjsNominal,
  getJPNominal,
  getKSNominal,
  IDR,
  areaWorkEquipmentsNominal,
  manfee,
  handleClickSee,
  getSubTotalPerArea,
  getTotalCostPerArea,
  getAllowancesBPUNominal,
  getAllowancesNominalWithBPU,
  selectedValueBPU,
  showThr,
  showUak,
  getUAK,
  getTHR,
  setSubTotal,
  fixedBpjsCut,
  bpjsPercentage,
  jpPercentage,
  ksPercentage,
  manfeeMode,
  manfeePercentage,
  fixedManfee,
  setCurrentSubTotal = () => {},
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");

  const umk = useMemo(() => {
    return each?.umk;
  }, [each]);

  const subTotalPerArea = getSubTotalPerArea;

  const endTotalPerArea = getTotalCostPerArea(
    each,
    umk,
    true,
    getSubTotalPerArea,
    manfee,
    manfeeMode,
    manfeePercentage,
    fixedManfee,
    selectedValueBPU,
    showUak,
    showThr,
    bpjsPercentage,
    jpPercentage,
    ksPercentage,
    fixedBpjsCut
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  const tableMinWidth = useMemo(() => {
    let width;

    if (windowSize[0] < 768) {
      width = "100%";
    } else {
      width = "1000px";
    }
    return width;
  }, [windowSize]);

  const firstColMinWidth = useMemo(() => {
    let width;

    if (windowSize[0] < 768) {
      width = "100%";
    } else {
      width = "200px";
    }
    return width;
  }, [windowSize]);

  let subTotal = useMemo(() => {
    return 0;
  }, []);

  useEffect(() => {
    setCurrentSubTotal(subTotal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTotal]);

  return (
    <div className={Styles.each}>
      <h4>{locationName}</h4>
      <div className={Styles.tableWrapper}>
        <TableContainer className={Styles.tableContainer}>
          <Table className={Styles.table} sx={{ minWidth: tableMinWidth }}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              values={form}
              firstColMinWidth={firstColMinWidth}
            />

            <TableBody>
              {stableSort(each?.jobs, getComparator(order, orderBy))?.map(
                (eachD, iD) => {
                  const personalEquipments = getPersonalEquipmentsNominal(
                    eachD?.personalEquipments
                  );
                  const getUak = getUAK(eachD?.allowances);
                  const getThr = getTHR(eachD?.allowances);
                  const allowances = getAllowancesNominal(
                    eachD?.allowances,
                    showThr,
                    showUak
                  );

                  const cleanAllowance =
                    !showThr && !showUak
                      ? getCleanAllowance(
                          eachD?.allowances?.filter((el) => !el?.type)
                        )
                      : !showThr
                      ? getCleanAllowance(
                          eachD?.allowances?.filter((el) => !el?.type)
                        ) + getUAK(eachD?.allowances?.filter((el) => !el?.type))
                      : !showUak
                      ? getCleanAllowance(
                          eachD?.allowances?.filter((el) => !el?.type)
                        ) + getTHR(eachD?.allowances?.filter((el) => !el?.type))
                      : getCleanAllowance(
                          eachD?.allowances?.filter((el) => !el?.type)
                        ) +
                        getTHR(eachD?.allowances?.filter((el) => !el?.type)) +
                        getUAK(eachD?.allowances?.filter((el) => !el?.type));

                  const allowancesTotalCost =
                    !showThr && !showUak
                      ? getCleanAllowance(eachD?.allowances)
                      : !showThr
                      ? getCleanAllowance(eachD?.allowances) +
                        getUAK(eachD?.allowances)
                      : !showUak
                      ? getCleanAllowance(eachD?.allowances) +
                        getTHR(eachD?.allowances)
                      : getCleanAllowance(eachD?.allowances) +
                        getTHR(eachD?.allowances) +
                        getUAK(eachD?.allowances);

                  const bpjsNominal = getBpjsNominal(
                    getSalaryInMonth(eachD?.salaryPeriod, eachD?.salary),
                    umk,
                    cleanAllowance,
                    fixedBpjsCut,
                    bpjsPercentage
                  );
                  const jpNominal = getJPNominal(
                    getSalaryInMonth(eachD?.salaryPeriod, eachD?.salary),
                    umk,
                    cleanAllowance,
                    fixedBpjsCut,
                    jpPercentage
                  );
                  const ksNominal = getKSNominal(
                    getSalaryInMonth(eachD?.salaryPeriod, eachD?.salary),
                    umk,
                    cleanAllowance,
                    fixedBpjsCut,
                    ksPercentage
                  );
                  const BPUNominal =
                    getAllowancesBPUNominal(eachD?.allowances) + ksNominal;
                  const AllowancesWithoutBPU = getAllowancesNominalWithBPU(
                    eachD?.allowances,
                    showThr,
                    showUak
                  );
                  const totalCost =
                    (Number(
                      getSalaryInMonth(eachD?.salaryPeriod, eachD?.salary)
                    ) +
                      Number(personalEquipments) +
                      Number(allowancesTotalCost) +
                      Number(jpNominal) +
                      Number(ksNominal) +
                      Number(bpjsNominal)) *
                    eachD?.demand;

                  subTotal += totalCost;
                  setSubTotal(subTotal);

                  return (
                    <TableRow
                      key={iD}
                      className={`${Styles.tableRow} ${
                        iD === each?.jobs?.length - 1 && Styles.lastRow
                      }`}
                    >
                      <TableCell
                        padding="none"
                        className={`${Styles.tableCell} ${Styles.stickyLeft}`}
                      >
                        {eachD?.title}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={`${Styles.tableCell} ${Styles.HideOnMobile}`}
                      >
                        {eachD?.demand}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={`${Styles.tableCell} ${Styles.HideOnMobile}`}
                      >
                        <div className={Styles.salary}>
                          <span>{IDR.format(eachD?.salary)}</span>
                          <span>{getPeriodText(`${eachD?.salaryPeriod}`)}</span>
                        </div>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={`${Styles.tableCell} ${Styles.HideOnMobile}`}
                      >
                        <span>
                          {" "}
                          {IDR.format(
                            getSalaryInMonth(eachD?.salaryPeriod, eachD?.salary)
                          )}
                        </span>
                      </TableCell>
                      <TableCell
                        align="right"
                        className={`${Styles.tableCell} ${Styles.HideOnMobile}`}
                      >
                        {IDR.format(personalEquipments)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={`${Styles.tableCell} ${Styles.HideOnMobile}`}
                      >
                        {selectedValueBPU?.includes("Tagih Ke Perusahaan")
                          ? IDR.format(AllowancesWithoutBPU)
                          : IDR.format(allowances)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={`${Styles.tableCell} ${Styles.HideOnMobile}`}
                      >
                        {selectedValueBPU?.includes("Tagih Ke Perusahaan")
                          ? IDR.format(BPUNominal)
                          : IDR.format(bpjsNominal + ksNominal + jpNominal)}
                      </TableCell>
                      <TableCell
                        align="right"
                        padding="none"
                        className={`${Styles.tableCell} ${Styles.HideOnMobile} ${Styles.stickyRight}`}
                      >
                        {IDR.format(totalCost)}
                      </TableCell>
                      <TableCell
                        align="right"
                        className={`${Styles.tableCell} ${Styles.showOnMobile}`}
                      >
                        <button
                          onClick={() =>
                            handleClickSee(
                              eachD,
                              personalEquipments,
                              allowances,
                              bpjsNominal + +jpNominal + ksNominal,
                              totalCost,
                              areaWorkEquipmentsNominal,
                              manfee,
                              cleanAllowance,
                              eachD?.salary,
                              getUak,
                              getThr
                            )
                          }
                        >
                          See More
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={Styles.summary}>
        <div className={Styles.boxSum}>
          <span>Sub Total</span>
          <span>{IDR.format(subTotal)}</span>
        </div>
        <div className={Styles.boxSum}>
          <span>Area Work Equipment</span>
          <span>{IDR.format(areaWorkEquipmentsNominal)}</span>
        </div>
        {/* <div className={Styles.boxSum}>
          <span>
            Management Fee {manfeeMode === "%" && `(${manfeePercentage}%)`}
          </span>
          <span>{IDR.format(manfeePerArea)}</span>
        </div> */}
        <div className={`${Styles.boxSum} ${Styles.last}`}>
          <span>Total Biaya</span>
          <span>{IDR.format(subTotal)}</span>
          {/* <span>{IDR.format(endTotalPerArea)}</span> */}
        </div>
      </div>
    </div>
  );
}

export const SummaryTable = ({
  data = [],
  subTotal = 0,
  manfeeMode,
  manfeePercentage,
  manfeeNominal,
  pph,
  setCost = () => {},
  noTaxes,
}) => {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const tableMinWidth = useMemo(() => {
    let width;

    if (windowSize[0] < 768) {
      width = "100%";
    } else {
      width = "1000px";
    }
    return width;
  }, [windowSize]);

  const ppnAmount = useMemo(() => {
    return Math.ceil((11 * (+subTotal + +manfeeNominal)) / 100);
  }, [manfeeNominal, subTotal]);
  const pphAmount = useMemo(() => {
    return Math.ceil(
      (2 * ((pph?.includes("total") ? +subTotal : 0) + +manfeeNominal)) / 100
    );
  }, [manfeeNominal, pph, subTotal]);
  const endTotal = useMemo(() => {
    return +subTotal + +manfeeNominal + ppnAmount - pphAmount;
  }, [manfeeNominal, pphAmount, ppnAmount, subTotal]);

  useEffect(() => {
    setCost(endTotal);
  }, [endTotal, setCost]);
  return (
    <div className={Styles.each}>
      <h4>Sub Total</h4>
      <div className={Styles.tableWrapper}>
        <TableContainer className={Styles.tableContainer}>
          <Table className={Styles.table} sx={{ minWidth: tableMinWidth }}>
            <EnhancedTableHead
              isSummary
              // order={order}
              // orderBy={orderBy}
              // onRequestSort={handleRequestSort}
              // values={form}
              // firstColMinWidth={firstColMinWidth}
            />

            <TableBody>
              {data?.map((eachD, iD) => (
                <TableRow
                  key={iD}
                  className={`${Styles.tableRow} 
               `}
                >
                  {SUMMARY_TABLE_HEAD?.map((h, hI) => (
                    <TableCell key={hI} align={h?.align}>
                      {eachD[h?.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {noTaxes ? (
        <div className={Styles.summary}>
          <div className={Styles.boxSum}>
            <span>Sub Total</span>
            <span>{IDR.format(subTotal)}</span>
          </div>
          <div className={Styles.boxSum}>
            <span>Manfee</span>
            <span>{IDR.format(manfeeNominal)}</span>
          </div>

          <div className={`${Styles.boxSum} ${Styles.last}`}>
            <span>Total Biaya</span>
            <span>{IDR.format(+subTotal + +manfeeNominal)}</span>
          </div>
        </div>
      ) : (
        <div className={Styles.summary}>
          <div className={Styles.boxSum}>
            <span>Sub Total</span>
            <span>{IDR.format(subTotal)}</span>
          </div>
          <div className={Styles.boxSum}>
            <span>Manfee</span>
            <span>{IDR.format(manfeeNominal)}</span>
          </div>

          <div className={`${Styles.boxSum} ${Styles.last}`}>
            <span>Total Biaya</span>
            <span>{IDR.format(+subTotal + +manfeeNominal)}</span>
          </div>
          <div className={`${Styles.boxSum} ${Styles.bold}`}>
            <span>PPN 11%</span>
            <span>{IDR.format(ppnAmount)}</span>
          </div>
          <div className={`${Styles.boxSum} ${Styles.bold}`}>
            <span>PPH 2%</span>
            <span>{IDR.format(pphAmount)}</span>
          </div>
          <div className={`${Styles.boxSum} ${Styles.last}`}>
            <span>Grand Total</span>
            <span>{IDR.format(endTotal)}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default function FifthStep(props) {
  const {
    onSubmit = () => {},
    // onPrevious,
    // onNext,
    form,
    workSchemeForm,
    cities,
    manfee,
    setManfee,
    TOP = "",
    setTOP = () => {},
    fixedManfee,
    fixedBpjsCut,
    selectedValueBPU,
    isSubmitting,
    submittingType,
    isSubmittingDraft,
    error,
    isDone,
    noTaxes = false,
    pph,
    alertMessage = [],
  } = props;

  // console.log(form, "bpu");

  const {
    // getTotalSalaryPerArea,
    // getTotalUmkPerArea,
    getBpjsNominal,
    getJPNominal,
    getKSNominal,
    getPersonalEquipmentsNominal,
    // getPersonalEquipmentsNominalPerArea,
    // AllowanecesWithoutUAKandTHR,
    getUAK,
    getTHR,
    getAllowancesNominal,
    getAllowancesBPUNominal,
    getAllowancesNominalWithBPU,
    getAllowancesNominalWithoutTHR,
    // getAllowancesNominalPerAreaWithoutTHRWithoutBPU,
    // getAllowancesNominalPerAreaBPUOnly,
    // getTotalBpjsNominalPerArea,
    getEachManfeeGetter,
    // getAllowancesNominalPerAreaTHRWithoutBPU,
    // getAllowancesNominalPerAreaUAKWithoutBPU,
    getManfeeGetter,
    getAreaWorkEquipmentsNominal,
    getTotalCostPerArea,
  } = CostEstimation();

  const [manfeeMode, setManfeeMode] = useState("Rp");
  const [manfeePercentage, setManfeePercentage] = useState(
    Number(manfee.split("%")[0]) || 0
  );
  const [cost, setCost] = useState(0);

  const [manfeeRupiah, setManfeeRupiah] = useState(
    !manfee.includes("%") ? Number(manfee) : 0
  );

  // console.log(form, 'test');

  const [selectedManPower, setSelectedManPower] = useState(null);
  const [selectedAreaWorkEquipments, setSelectedAreaWorkEquipments] =
    useState(null);
  const [
    selectedPersonalWorkEquipmentNominal,
    setSelectedPersonalWorkEquipmenNominal,
  ] = useState(null);
  const [selectedAllowanceNominal, setSelectedAllowanceNominal] =
    useState(null);
  const [selectedBpjsNominal, setSelectedBpjsNominal] = useState(null);
  const [selectedTotalCost, setSelectedTotalCost] = useState(null);
  const [selectedManfee, setSelectedManfee] = useState(null);
  const [selectedCleanAllowance, setSelectedCleanAllowance] = useState(null);
  const [selectedSalary, setSelectedSalary] = useState(null);
  const [selectedUAK, setSelectedUAK] = useState(null);
  const [selectedTHR, setSelectedTHR] = useState(null);
  const [getSubTotalPerArea, setSubTotal] = useState(0);

  const [subTotals, setSubTotals] = useState([...Array(form?.length)]);

  const [umkArr, setUmkArr] = useState(Array(form?.length).fill(""));
  const [costList, setCostList] = useState(Array(form?.length).fill(""));

  const handleClickSee = (
    data,
    pEquipments,
    allowance,
    bpjs,
    totalCost,
    aWEquipment,
    manFee,
    cleanAllowance,
    salary,
    getUak,
    getThr
  ) => {
    setSelectedManPower(data);
    setSelectedPersonalWorkEquipmenNominal(pEquipments);
    setSelectedAllowanceNominal(allowance);
    setSelectedBpjsNominal(bpjs);
    setSelectedTotalCost(totalCost);
    setSelectedAreaWorkEquipments(aWEquipment);
    setSelectedManfee(manFee);
    setSelectedCleanAllowance(cleanAllowance);
    setSelectedSalary(salary);
    setSelectedUAK(getUak);
    setSelectedTHR(getThr);
  };

  const boxRef = useRef();
  const boxOutsideClick = UseOutsideClick(boxRef);

  const showThr = useMemo(() => {
    if (workSchemeForm?.others?.thr?.toLowerCase()?.includes("bulanan")) {
      return true;
    }
    return false;
  }, [workSchemeForm]);

  const showUak = useMemo(() => {
    if (workSchemeForm?.others?.uak?.toLowerCase()?.includes("bulanan")) {
      return true;
    }
    return false;
  }, [workSchemeForm]);

  useEffect(() => {
    if (selectedManPower !== null) {
      if (boxOutsideClick === false) {
        setSelectedManPower(null);
      }
    }
  }, [boxOutsideClick, selectedManPower]);

  const bpjsPercentage = useMemo(() => {
    let percentage = 0;

    if (
      workSchemeForm?.bpjs?.jkk &&
      workSchemeForm?.bpjs?.jkk?.includes("perusahaan")
    ) {
      const jkkPercentage = workSchemeForm?.bpjs?.jkk
        .split("perusahaan")[0]
        .split("%")[0];
      const fixedJkkPercentage = jkkPercentage.replace(",", ".");
      percentage += Number(fixedJkkPercentage);
    }

    if (
      workSchemeForm?.bpjs?.jkm &&
      workSchemeForm?.bpjs?.jkm?.includes("perusahaan")
    ) {
      const jkmPercentage = workSchemeForm?.bpjs?.jkm
        .split("perusahaan")[0]
        .split("%")[0];
      const fixedJkmPercentage = jkmPercentage.replace(",", ".");
      percentage += Number(fixedJkmPercentage);
    }

    if (
      workSchemeForm?.bpjs?.jht &&
      workSchemeForm?.bpjs?.jht?.includes("perusahaan")
    ) {
      const jhtPercentage = workSchemeForm?.bpjs?.jht
        .split("perusahaan")[0]
        .split("%")[0];
      const fixedJhtPercentage = jhtPercentage.replace(",", ".");
      percentage += Number(fixedJhtPercentage);
    }

    return percentage;
  }, [workSchemeForm]);

  const jpPercentage = useMemo(() => {
    let percentage = 0;
    if (
      workSchemeForm?.bpjs?.jp &&
      workSchemeForm?.bpjs?.jp?.includes("perusahaan")
    ) {
      const jPercentage = workSchemeForm?.bpjs?.jp
        .split("perusahaan")[0]
        .split("%")[0];
      const fixedJpPercentage = jPercentage.replace(",", ".");
      percentage += Number(fixedJpPercentage);
    }
    return percentage;
  }, [workSchemeForm?.bpjs?.jp]);

  const ksPercentage = useMemo(() => {
    let percentage = 0;
    if (
      workSchemeForm?.bpjs?.health &&
      workSchemeForm?.bpjs?.health?.includes("perusahaan")
    ) {
      const healthPercentage = workSchemeForm?.bpjs?.health
        .split("perusahaan")[0]
        .split("%")[0];
      const fixedHealthPercentage = healthPercentage.replace(",", ".");
      percentage += Number(fixedHealthPercentage);
    }
    return percentage;
  }, [workSchemeForm?.bpjs?.health]);

  const getLocationName = (locationCode) => {
    return cities?.find((c) => c.code === locationCode)?.name;
  };

  // const [generalCost, setGeneralCost] = useState(0)

  const [showSelect, setShowSelect] = useState(false);

  useEffect(() => {
    if (showSelect !== false) {
      if (boxOutsideClick === false) {
        setShowSelect(false);
      }
    }
  }, [boxOutsideClick, showSelect]);

  const handleModeChange = (e) => {
    setManfeePercentage(0);
    setManfeeRupiah(0);
    if (e.target.value === "%") {
      setManfee("0%");
    } else {
      setManfee("0");
    }
    setManfeeMode(e.target.value);
  };

  const handlePercentageChange = (e) => {
    if (e.target.value >= 0 && e.target.value <= 100) {
      setManfeePercentage(e.target.value);
      setManfee(`${e.target.value}%`);
    }
  };
  const handleChangeManfeeRupiah = (e) => {
    const targetValue = e.target.value;
    const firstParsing = targetValue.replace(/[^\d.]/gi, "");
    const parsedValue = firstParsing?.replaceAll(",", "")?.replaceAll(".", "");
    setManfeeRupiah(parsedValue);
    setManfee(parsedValue);
  };

  const isNoData = useMemo(() => {
    if (form?.length === 0) {
      return true;
    }
    return false;
  }, [form]);

  useEffect(() => {
    if (manfee.includes("%")) {
      setManfeeMode("%");
    } else {
      setManfeeMode("Rp");
    }
  }, [manfee]);

  useEffect(() => {
    if (manfeePercentage > 100) {
      setManfeePercentage(0);
    }
  }, [manfeePercentage]);

  useEffect(() => {
    if (manfeeMode === "%") {
      setManfeeRupiah(0);
    }
  }, [manfeeMode]);

  const tempManfeeNominal = useMemo(() => {
    return manfeeMode === "Rp"
      ? manfeeRupiah
      : form
          ?.map((f) =>
            Math.ceil(
              (manfeePercentage *
                getManfeeGetter(
                  f,
                  f?.umk,
                  fixedManfee,
                  selectedValueBPU,
                  showUak,
                  showThr,
                  bpjsPercentage,
                  jpPercentage,
                  ksPercentage,
                  fixedBpjsCut
                )) /
                100
            )
          )
          .reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
          }, 0);
  }, [
    bpjsPercentage,
    fixedBpjsCut,
    fixedManfee,
    form,
    getManfeeGetter,
    jpPercentage,
    ksPercentage,
    manfeeMode,
    manfeePercentage,
    manfeeRupiah,
    selectedValueBPU,
    showThr,
    showUak,
  ]);

  const tempSubTotal = useMemo(() => {
    return subTotals?.reduce((accumulator, currentValue) => {
      return +accumulator + +currentValue;
    }, 0);
  }, [subTotals]);

  const ppnAmount = useMemo(() => {
    return Math.ceil((11 * (+tempSubTotal + +tempManfeeNominal)) / 100);
  }, [tempManfeeNominal, tempSubTotal]);
  const pphAmount = useMemo(() => {
    return Math.ceil(
      (2 *
        ((pph?.includes("total") ? +tempSubTotal : 0) + +tempManfeeNominal)) /
        100
    );
  }, [pph, tempManfeeNominal, tempSubTotal]);

  const generalCost = useMemo(() => {
    let cost = 0;
    for (let i = 0; i < form.length; i++) {
      const perArea = getTotalCostPerArea(
        form[i],
        form[i]?.umk,
        false,
        subTotals[i],
        manfee,
        manfeeMode,
        manfeePercentage,
        fixedManfee,
        selectedValueBPU,
        showUak,
        showThr,
        bpjsPercentage,
        jpPercentage,
        ksPercentage,
        fixedBpjsCut
      );
      cost += Math.ceil(Number(perArea));
    }

    return cost;
  }, [
    form,
    getTotalCostPerArea,
    subTotals,
    manfee,
    manfeeMode,
    manfeePercentage,
    fixedManfee,
    selectedValueBPU,
    showUak,
    showThr,
    bpjsPercentage,
    jpPercentage,
    ksPercentage,
    fixedBpjsCut,
  ]);

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <h3>Cost Estimation Detail</h3>
      <div className={Styles.content}>
        {form?.map((each, i) => {
          const locationName = getLocationName(each?.location);
          const areaWorkEquipmentsNominal = getAreaWorkEquipmentsNominal(each);
          const manfeePerArea =
            manfeeMode === "Rp"
              ? manfee
              : Math.ceil(
                  (manfeePercentage *
                    getManfeeGetter(
                      each,
                      each?.umk,
                      fixedManfee,
                      selectedValueBPU,
                      showUak,
                      showThr,
                      bpjsPercentage,
                      jpPercentage,
                      ksPercentage,
                      fixedBpjsCut
                    )) /
                    100
                );

          const endTotalPerArea = getTotalCostPerArea(
            each,
            umkArr[i],
            false,
            getSubTotalPerArea,
            manfee,
            manfeeMode,
            manfeePercentage,
            fixedManfee,
            selectedValueBPU,
            showUak,
            showThr,
            bpjsPercentage,
            jpPercentage,
            ksPercentage,
            fixedBpjsCut
          );
          return (
            <DataTable
              key={i}
              index={i}
              locationName={locationName}
              each={each}
              form={form}
              getPersonalEquipmentsNominal={getPersonalEquipmentsNominal}
              getAllowancesNominal={getAllowancesNominal}
              getCleanAllowance={getAllowancesNominalWithoutTHR}
              getBpjsNominal={getBpjsNominal}
              getJPNominal={getJPNominal}
              getKSNominal={getKSNominal}
              IDR={IDR}
              areaWorkEquipmentsNominal={areaWorkEquipmentsNominal}
              manfee={manfee}
              handleClickSee={handleClickSee}
              // manfeeMode={manfeeMode}
              manfeePerArea={manfeePerArea}
              // manfeePercentage={manfeePercentage}
              endTotalPerArea={endTotalPerArea}
              getSubTotalPerArea={getSubTotalPerArea}
              umkArr={umkArr}
              setUmkArr={setUmkArr}
              getTotalCostPerArea={getTotalCostPerArea}
              costList={costList}
              setCostList={setCostList}
              generalCost={generalCost}
              getEachManfeeGetter={getEachManfeeGetter}
              selectedValueBPU={selectedValueBPU}
              getAllowancesBPUNominal={getAllowancesBPUNominal}
              getAllowancesNominalWithBPU={getAllowancesNominalWithBPU}
              showThr={showThr}
              showUak={showUak}
              getUAK={getUAK}
              getTHR={getTHR}
              setSubTotal={setSubTotal}
              fixedBpjsCut={fixedBpjsCut}
              bpjsPercentage={bpjsPercentage}
              jpPercentage={jpPercentage}
              ksPercentage={ksPercentage}
              manfeeMode={manfeeMode}
              manfeePercentage={manfeePercentage}
              fixedManfee={fixedManfee}
              setCurrentSubTotal={(sub) => {
                // const newSubs = [...subTotals]
                subTotals[i] = sub;
                // setSubTotals(newSubs)
              }}
            />
          );
        })}

        {isNoData && <div className={Styles.noData}>No Data Yet</div>}

        <SummaryTable
          noTaxes={noTaxes}
          pph={pph}
          setCost={setCost}
          data={form?.map((f, fI) => {
            return {
              cityName: getLocationName(f?.location),
              // subTotal: IDR.format(getSubTotalPerArea),
              subTotal: IDR.format(subTotals[fI] || 0),
              areaWorkEquipments: IDR.format(getAreaWorkEquipmentsNominal(f)),
              total: IDR.format(
                getTotalCostPerArea(
                  f,
                  f?.umk,
                  true,
                  subTotals[fI] || 0,
                  manfee,
                  manfeeMode,
                  manfeePercentage,
                  fixedManfee,
                  selectedValueBPU,
                  showUak,
                  showThr,
                  bpjsPercentage,
                  jpPercentage,
                  ksPercentage,
                  fixedBpjsCut
                )
              ),
            };
          })}
          // subTotal={getSubTotalPerArea}
          subTotal={subTotals?.reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
          }, 0)}
          manfeeMode={manfeeMode}
          manfeeNominal={
            manfeeMode === "Rp"
              ? manfeeRupiah
              : form
                  ?.map((f) =>
                    Math.ceil(
                      (manfeePercentage *
                        getManfeeGetter(
                          f,
                          f?.umk,
                          fixedManfee,
                          selectedValueBPU,
                          showUak,
                          showThr,
                          bpjsPercentage,
                          jpPercentage,
                          ksPercentage,
                          fixedBpjsCut
                        )) /
                        100
                    )
                  )
                  .reduce((accumulator, currentValue) => {
                    return accumulator + currentValue;
                  }, 0)
          }
        />
      </div>
      {!!error && (
        <div style={{ paddingTop: "16px" }}>
          <FormErrorMessage
            message={error?.message || "Something went wrong"}
          />
        </div>
      )}

      {/* <div className={Styles.buttonWrapper}>
        <button onClick={onPrevious}>Back</button>
        <button onClick={onNext}>Next Step</button>
      </div> */}

      {/* <div className={Styles.buttonsWrapper}>
        <div className={Styles.left}>
          <button onClick={onPrevious} className={Styles.prev}>
            Back
          </button>
        </div>
        <div className={Styles.right}>
          <button
            className={Styles.saveToDraft}
            onClick={(e) => onSubmit(e, "draft")}
            disabled={
              isSubmittingDraft ||
              isSubmitting ||
              !TOP ||
              (manfeeMode === "%" &&
                (!manfeePercentage || manfeePercentage == 0)) ||
              (manfeeMode === "Rp" && (!manfeeRupiah || manfeeRupiah == 0))
            }
          >
            {isSubmittingDraft ? "Saving as Draft..." : "Save as Draft"}
          </button>
          <button
            disabled={
              isSubmittingDraft ||
              isSubmitting ||
              !TOP ||
              (manfeeMode === "%" &&
                (!manfeePercentage || manfeePercentage == 0)) ||
              (manfeeMode === "Rp" && (!manfeeRupiah || manfeeRupiah == 0))
            }
            onClick={(e) => onSubmit(e, "send")}
            className={Styles.submit}
          >
            {isSubmitting ? "Sending..." : "Send FLB"}
          </button>
        </div>
      </div> */}
      <div className={Styles.footer} id="set">
        <div className={Styles.innerFooter}>
          <div className={Styles.top}>
            <div className={Styles.TOPSetter}>
              <div className={Styles.descWrapper}>
                <span>1</span>
                <p>
                  Set TOP<span className={Styles.asterisk}>&#x2a;</span>
                </p>
              </div>
              <div
                className={`${Styles.inputWrapper} ${
                  (alertMessage?.length > 0 &&
                    alertMessage?.find((el) => el?.name === "Set TOP") &&
                    +TOP === 0) ||
                  (alertMessage?.length > 0 &&
                    alertMessage?.find((el) => el?.name === "Set TOP") &&
                    !TOP)
                    ? Styles.redBorder
                    : ""
                }`}
              >
                <input
                  value={TOP || ""}
                  onChange={(e) => setTOP(e.target.value)}
                  type="number"
                  className={`${Styles.TOPInput}`}
                />
                <div className={Styles.adornment}>Days</div>
              </div>
            </div>
            <div className={Styles.manfeeSetter}>
              <div className={Styles.descWrapper}>
                <span>2</span>
                <p>
                  Set Manfee<span className={Styles.asterisk}>&#x2a;</span>
                </p>
              </div>

              <div
                className={`${Styles.inputSelectWrapper} ${
                  manfeeMode === "%"
                    ? (alertMessage?.length > 0 &&
                        alertMessage?.find((el) => el?.name === "Manfee") &&
                        !manfeePercentage) ||
                      (alertMessage?.length > 0 &&
                        alertMessage?.find((el) => el?.name === "Manfee") &&
                        manfeePercentage === "0%")
                      ? Styles.redBorder
                      : ""
                    : (alertMessage?.length > 0 &&
                        alertMessage?.find((el) => el?.name === "Manfee") &&
                        !manfeeRupiah) ||
                      (alertMessage?.length > 0 &&
                        alertMessage?.find((el) => el?.name === "Manfee") &&
                        +manfeeRupiah === 0)
                    ? Styles.redBorder
                    : ""
                }`}
              >
                <select
                  value={manfeeMode}
                  onChange={handleModeChange}
                  name="manfee"
                >
                  <option value="%">Percentage</option>
                  <option value="Rp">Rupiah</option>
                </select>
                <div>
                  {manfeeMode === "%" ? (
                    <input
                      type="number"
                      value={manfeePercentage}
                      onChange={handlePercentageChange}
                      min={0}
                      max={100}
                    />
                  ) : (
                    <CurrencyInput
                      value={manfeeRupiah}
                      allowDecimals={false}
                      onChange={handleChangeManfeeRupiah}
                      decimalSeparator=","
                      groupSeparator="."
                    />
                  )}
                  <span
                    className={`${Styles.percentageAdornment} ${
                      manfeeMode === "%" ? Styles.on : Styles.off
                    }`}
                  >
                    %
                  </span>
                </div>
              </div>
              <div className={`${Styles.inputSelectModal}`}>
                <div onClick={() => setShowSelect(true)}>
                  <span>{manfeeMode === "%" ? "Percentage" : "Rupiah"}</span>
                  <Icon icon="arrow-down" size={18} />
                </div>
                <div>
                  {manfeeMode === "%" ? (
                    <input
                      type="number"
                      value={manfeePercentage}
                      onChange={handlePercentageChange}
                      min={0}
                      max={100}
                    />
                  ) : (
                    <CurrencyInput
                      value={manfeeRupiah}
                      allowDecimals={false}
                      onChange={handleChangeManfeeRupiah}
                      decimalSeparator=","
                      groupSeparator="."
                    />
                  )}
                  <span
                    className={`${Styles.percentageAdornment} ${
                      manfeeMode === "%" ? Styles.on : Styles.off
                    }`}
                  >
                    %
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.bottom}>
            <div>
              <Icon icon="money" size={19} className={Styles.moneyIcon} />
              <span>Total Cost</span>
            </div>
            <div>{IDR.format(noTaxes ? generalCost : +cost)}</div>
          </div>
        </div>
      </div>
      <div
        className={`${Styles.modalWrapper} ${
          selectedManPower ? Styles.onShow : Styles.onHide
        }`}
      >
        <ViewDetailModal
          data={selectedManPower}
          setData={setSelectedManPower}
          personalEquipments={selectedPersonalWorkEquipmentNominal}
          allowance={selectedAllowanceNominal}
          bpjs={selectedBpjsNominal}
          totalCost={selectedTotalCost}
          manfee={selectedManfee}
          equipments={selectedAreaWorkEquipments}
          manfeeMode={manfeeMode}
          manfeePercentage={manfeePercentage}
          cleanAllowance={selectedCleanAllowance}
          salary={selectedSalary}
          getEachManfeeGetter={getEachManfeeGetter}
          thr={selectedTHR}
          uak={selectedUAK}
          fixedManfees={fixedManfee}
          showUak={showUak}
          showThr={showThr}
        />
      </div>
      <div
        className={`${Styles.modalWrapper} ${
          showSelect ? Styles.onShow : Styles.onHide
        }`}
      >
        <SelectModal setShow={setShowSelect} setManfeeMode={setManfeeMode} />
      </div>
      <div
        ref={boxRef}
        className={`${Styles.overlay} ${
          selectedManPower || showSelect ? Styles.visible : ""
        }`}
      />
    </div>
  );
}
