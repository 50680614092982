import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";
import CustomBreadCrumb from "@Molecule/CustomBreadCrumb";
import useQuery from "@Hooks/useQuery";
import Stepper from "@Molecule/Stepper";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { Skeleton } from "@mui/material";

export default function SchemeEditFormNavigation({
  stepsOptions,
  currentStep,
  contractType,
  handleClickStep = () => {},
  isProceed,
  alertMessage = "",
  defaultFourth = null,
  setViewDetails = () => {},
}) {
  const navigate = useNavigate();
  const query = useQuery();
  const name = query.get("n");
  // const code = query.get('c')
  const maxLength = 12;
  const maxLengthClient = 29;

  const handleClickBack = () => {
    // navigate(`/clients/orders?n=${name}&c=${code}`);
    sessionStorage?.clear();
    navigate(-1);
  };
  const handleNavigateToList = () => {
    // navigate(`/clients/orders?n=${name}&c=${code}`);
    navigate(-1);
  };

  const handleNavigateToDetails = () => {
    // navigate(`/clients/details?n=${name}&c=${code}`);
    navigate(-1);
  };

  const templates = useMemo(() => {
    return [
      {
        id: 1,
        icon: "building-office-2",
        color: "#1571DE",
        name: "Klien",
        value: query?.get("n") || null,
      },
      {
        id: 2,
        icon: "file",
        color: "#1571DE",
        name: "Nomor dokumen",
        value:
          defaultFourth?.additional?.periodeKerjasama?.docType ||
          defaultFourth?.additional?.periodeKerjasama?.docNumber
            ? `${
                defaultFourth?.additional?.periodeKerjasama?.docType || "-"
              } • ${
                defaultFourth?.additional?.periodeKerjasama?.docNumber || "-"
              }`
            : null,
      },
    ];
  }, [query, defaultFourth]);

  if (isProceed) {
    return (
      <div className={`${Styles.container} ${Styles.proceed}`}>
        <div className={Styles.leftProceed}>
          <div className={Styles.topWrapper}>
            <div className={Styles.breadcrumbWrapper}>
              <button onClick={handleClickBack}>
                <Icon
                  icon="arrow-left-rounded-primary"
                  color="#1571DE"
                  size={20}
                />
              </button>
              <CustomBreadCrumb>
                <span
                  onClick={handleNavigateToList}
                  className={Styles.breadcrumbText}
                >
                  Service Client
                </span>
                <span
                  onClick={handleNavigateToDetails}
                  className={Styles.breadcrumbText}
                >
                  {name
                    ? name.length > maxLength
                      ? name.substring(0, maxLength)?.toUpperCase() + "..."
                      : name?.toUpperCase()
                    : "-"}
                </span>
                <span className={Styles.breadcrumbTextBold}>
                  FLB Delegation
                </span>
              </CustomBreadCrumb>
            </div>
          </div>

          <div className={Styles.stepper}>
            <Stepper
              type="filled"
              options={stepsOptions}
              currentStep={currentStep}
              onClick={handleClickStep}
              noDoneIcon
              noWhiteSpace
              // disableClick
            />
          </div>
        </div>

        <div className={Styles.right}>
          <div className={Styles.topRight}>
            <span>Service Client</span>
            <div onClick={() => setViewDetails(true)}>
              <span>View Detail</span>
              <Icon icon={"eye-visible"} size={20} color={"#1571DE"} />
            </div>
          </div>

          <div className={Styles.bottomRight}>
            {templates?.map((el, idx) => {
              return (
                <div key={idx}>
                  <div className={Styles.icon}>
                    <Icon
                      icon={`${el?.icon}`}
                      color={`${el?.color}`}
                      size={20}
                    />
                  </div>

                  <div className={Styles.titleBox}>
                    <span>{el?.name}</span>

                    <span>
                      {" "}
                      {el?.value
                        ? el?.value.length > maxLengthClient
                          ? el?.value
                              .substring(0, maxLengthClient)
                              ?.toUpperCase() + "..."
                          : el?.value?.toUpperCase()
                        : "-"}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.topWrapper}>
        <div className={Styles.breadcrumbWrapper}>
          <button onClick={handleClickBack}>
            <Icon icon="arrow-left-rounded-primary" color="#1571DE" size={20} />
          </button>
          <CustomBreadCrumb>
            <span
              onClick={handleNavigateToList}
              className={Styles.breadcrumbText}
            >
              Job Orders
            </span>
            <span
              onClick={handleNavigateToDetails}
              className={Styles.breadcrumbText}
            >
              {name}
            </span>
            <span onClick={handleClickBack} className={Styles.breadcrumbText}>
              Order
            </span>
            <span className={Styles.breadcrumbTextBold}>Edit</span>
          </CustomBreadCrumb>
        </div>
        {contractType && (
          <div className={Styles.typeWrapper}>
            <Icon icon="file" size={27} className={Styles.contractIcon} />
            <div className={Styles.descWrapper}>
              <span>{contractType?.type}</span>
              <span>{contractType?.label}</span>
            </div>
          </div>
        )}
      </div>
      <div className={Styles.stepperWrapper}>
        <Stepper
          type="filled"
          options={stepsOptions}
          currentStep={currentStep}
          onClick={handleClickStep}
          noDoneIcon
          noWhiteSpace
          // disableClick
        />
      </div>
      {alertMessage ? (
        <div className={Styles.infoWrapper}>
          <Icon icon={"alert-solid"} size={20} color={"#F5610D"} />
          <span>{alertMessage}</span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
