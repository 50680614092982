import Icon from "@Atom/Icon";
import Styles from "./styles.module.scss";

export default function NotifErrorDeployment({ handleClose, alertFound }) {
  return (
    <div className={Styles.container}>
      <div className={Styles.icon}>
        <Icon icon={"information-outline"} size={47} color={"#FFFFFF"} />
      </div>
      <span>RO Belum Dipilih</span>
      <span>
        Silahkan lakukan penambahan minimal 1 data RO di kota manapun.
      </span>

      <span onClick={handleClose}>Tutup</span>
    </div>
  );
}
