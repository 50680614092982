/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import useQuery from "@Hooks/useQuery";
import {
  editOrderAndSendFLB,
  editOrderAsDraft,
} from "@Services/manager/client";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import FifthStep from "./FifthStep";
import FirstStep from "./FirstStep";
// import FourthStep from "./FourthStep";
import Icon from "@Atom/Icon";
import { base64ToFile } from "@Helpers/base64ToFile";
import { fileToBase64 } from "@Helpers/convertFileToBase64";
import { makeRandomString } from "@Helpers/makeRandomString";
import SendFLBModal from "@Molecule/_modal/SendFLBModal";
import DeploymentStep from "@Organism/Manager/Client/AddOrder/Deployment";
import NewSixth from "@Organism/Manager/Client/AddOrder/NewSixth";
import PositionStep from "@Organism/Manager/Client/AddOrder/Position";
import { getPipelineOrderDetails } from "@Services/manager/pipeline";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import Styles from "./style.module.scss";
import LoadingAnimationPage from "@Organism/LoadingAnimationPage";
import { BPURangeSalary } from "@Helpers/BPURangeSalary";
import useWindowSize from "@Hooks/useWindowSize";
import ErrorModal from "@Molecule/_modal/ErrorModal";
import SideBarErrors from "@Pages/Auth/Manager/Client/AddNewOrder/SideBarErrors";

export default function SchemeEditFormLayout({
  syncData,
  defaultOrderForm,
  steps,
  setSteps,
  currentStep,
  setCurrentStep,
  contractType,
  setContractType,
  alertMessage = "",
  setAlertMessage = () => {},
  clientContacts = [],
  headerHeight = 0,
  previousButtonText = "",
  nextButtonText = "",
  activeCityID,
  setActiveCityID,
  activePositionID,
  setActivePositionID,
  activeExactPositionID,
  setActiveExactPositionID,
  positionsForm,
  setPositionsForm,
  showSideBar,
  setShowSideBar,
}) {
  // eslint-disable-next-line no-unused-vars
  const [trigger, setTrigger] = useState(null);
  const navigate = useNavigate();
  const query = useQuery();
  const [submittingType, setSubmittingType] = useState(null);
  const [submitting, setSubmitting] = useState(null);
  const [submittingDraft, setSubmittingDraft] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSuccessDraft, setIsSuccessDraft] = useState(false);
  const [error, setError] = useState(null);

  const [withBPJS, setWithBPJS] = useState(
    sessionStorage.getItem("withBPJSEdit") === "NO" ? false : true
  );

  const [isLoadingFirst, setIsLoadingFirst] = useState(
    sessionStorage?.getItem("isLoadingFirst") === "NO" ? false : true
  );
  const [isLoadingSecond, setIsLoadingSecond] = useState(
    sessionStorage?.getItem("isLoadingSecond") === "NO" ? false : true
  );
  const [isLoadingThird, setIsLoadingThird] = useState(
    sessionStorage?.getItem("isLoadingThird") === "NO" ? false : true
  );
  const [isLoadingFourth, setIsLoadingFourth] = useState(
    sessionStorage?.getItem("isLoadingFourth") === "NO" ? false : true
  );
  const [isLoadingFifth, setIsLoadingFifth] = useState(
    sessionStorage?.getItem("isLoadingFifth") === "NO" ? false : true
  );
  const [isLoadingFifthNew, setIsLoadingFifthNew] = useState(
    sessionStorage?.getItem("isLoadingFifth2") === "NO" ? false : true
  );

  const [defaultFirst, setDefaultFirst] = useState(
    sessionStorage.getItem("defaultFirst")
      ? JSON?.parse(sessionStorage?.getItem("defaultFirst"))
      : null
  );
  const [defaultSecond, setDefaultSecond] = useState(
    sessionStorage.getItem("defaultSecond")
      ? JSON?.parse(sessionStorage.getItem("defaultSecond"))
      : null
  );
  const [defaultThird, setDefaultThird] = useState(
    sessionStorage.getItem("defaultThird")
      ? JSON?.parse(sessionStorage?.getItem("defaultThird"))
      : null
  );
  const [defaultFourth, setDefaultFourth] = useState(
    sessionStorage.getItem("defaultFourth")
      ? JSON?.parse(sessionStorage?.getItem("defaultFourth"))
      : null
  );
  const [defaultFifthNew, setDefaultFifthNew] = useState(
    sessionStorage.getItem("defaultFifthNew")
      ? JSON?.parse(sessionStorage?.getItem("defaultFifthNew"))
      : null
  );
  const [defaultFifth, setDefaultFifth] = useState(
    sessionStorage.getItem("defaultFifth")
      ? JSON?.parse(sessionStorage?.getItem("defaultFifth"))
      : null
  );

  const activeState = useMemo(() => {
    switch (+currentStep) {
      case 1:
        return {
          isLoading: isLoadingFirst,
          setIsLoading: setIsLoadingFirst,
          defaultDetails: defaultFirst,
          setDefaultDetails: setDefaultFirst,
          step: "one",
        };
      case 2:
        return {
          isLoading: isLoadingSecond,
          setIsLoading: setIsLoadingSecond,
          defaultDetails: defaultSecond,
          setDefaultDetails: setDefaultSecond,
          step: "two",
        };
      case 3:
        return {
          isLoading: isLoadingThird,
          setIsLoading: setIsLoadingThird,
          defaultDetails: defaultThird,
          setDefaultDetails: setDefaultThird,
          step: "three",
        };
      case 4:
        return {
          isLoading: isLoadingFourth,
          setIsLoading: setIsLoadingFourth,
          defaultDetails: defaultFourth,
          setDefaultDetails: setDefaultFourth,
          step: "four",
        };
      case 5:
        return {
          isLoading: isLoadingFifthNew,
          setIsLoading: setIsLoadingFifthNew,
          defaultDetails: defaultFifthNew,
          setDefaultDetails: setDefaultFifthNew,
          step: "five",
        };
      case 6:
        return {
          isLoading: isLoadingFifth,
          setIsLoading: setIsLoadingFifth,
          defaultDetails: defaultFifth,
          setDefaultDetails: setDefaultFifth,
          step: "six",
        };
      default:
        return {
          isLoading: isLoadingFirst,
          setIsLoading: setIsLoadingFirst,
          defaultDetails: defaultFirst,
          setDefaultDetails: setDefaultFirst,
          step: "one",
        };
    }
  }, [
    currentStep,
    defaultFifth,
    defaultFifthNew,
    defaultFirst,
    defaultFourth,
    defaultSecond,
    defaultThird,
    isLoadingFifth,
    isLoadingFifthNew,
    isLoadingFirst,
    isLoadingFourth,
    isLoadingSecond,
    isLoadingThird,
  ]);

  const fetchDetail = useCallback(async () => {
    if (activeState?.isLoading) {
      try {
        const { response } = await getPipelineOrderDetails(
          query.get("i"),
          activeState?.step
        );
        activeState?.setDefaultDetails(response);
        activeState?.setIsLoading(false);
        setTrigger(makeRandomString(5));
      } catch (err) {
        console.log(err, "ini err");
      }
    }
  }, [activeState, query]);

  useEffect(() => {
    fetchDetail();
  }, [fetchDetail]);

  // useEffect(() => {
  //   if (error) {
  //     setTimeout(() => {
  //       setError(null);
  //     }, 5000);
  //   }
  // }, [error, setError]);

  const clientCode = query.get("c");

  // DEFAULT FORMS

  const defaultContractForm = useMemo(() => {
    const storageContract = sessionStorage?.getItem("contractFormEdit")
      ? JSON.parse(sessionStorage?.getItem("contractFormEdit"))
      : null;
    return {
      contract: storageContract?.contract
        ? storageContract?.contract
        : defaultFirst?.contractType?.contractType?.includes("Existing")
        ? null
        : defaultOrderForm?.contractType?.contractType,
    };
  }, [
    defaultFirst?.contractType?.contractType,
    defaultOrderForm?.contractType?.contractType,
  ]);

  const isCutOffManual = useMemo(() => {
    if (
      defaultSecond?.workScheme?.PotonganBPJSTKO === "UMK" ||
      defaultSecond?.workScheme?.PotonganBPJSTKO === "Gaji" ||
      defaultSecond?.workScheme?.PotonganBPJSTKO === "Gaji+tunjangan" ||
      defaultSecond?.workScheme?.PotonganBPJSTKO === "null" ||
      !defaultSecond?.workScheme?.PotonganBPJSTKO
    ) {
      return false;
    }
    return true;
  }, [defaultSecond?.workScheme?.PotonganBPJSTKO]);

  const defaultWorkSchemeForm = useMemo(() => {
    const storageForm = sessionStorage?.getItem("workSchemeFormEdit");
    const isLoaded = storageForm
      ? JSON.parse(storageForm)?.isLoaded === "YES"
      : false;
    const workSchemeDefaults = defaultSecond?.workScheme;
    const manfeeArr = workSchemeDefaults?.PengenaanManfee?.split("::")?.map(
      (each) => {
        return { name: each };
      }
    );

    const tkoBanksArr = workSchemeDefaults?.RekeningWajibTKO?.split("::");
    if (isLoaded) {
      return JSON.parse(storageForm);
    } else {
      return {
        tkoContract: defaultSecond?.employeementType || null,
        taxs: {
          pph21:
            workSchemeDefaults?.PPH21 && workSchemeDefaults?.PPH21 !== "null"
              ? workSchemeDefaults?.PPH21
              : null,
          pph23:
            workSchemeDefaults?.PPH23 && workSchemeDefaults?.PPH23 !== "null"
              ? workSchemeDefaults?.PPH23
              : null,
          ppn:
            workSchemeDefaults?.PPN && workSchemeDefaults?.PPN !== "null"
              ? workSchemeDefaults?.PPN
              : null,
        },
        bpjs: {
          registration:
            workSchemeDefaults?.PendaftaranBPJS &&
            workSchemeDefaults?.PendaftaranBPJS !== "null"
              ? workSchemeDefaults?.PendaftaranBPJS
              : null,
          paymentProof:
            workSchemeDefaults?.BuktiPendaftaranBPJS &&
            workSchemeDefaults?.BuktiPendaftaranBPJS !== "null"
              ? workSchemeDefaults?.BuktiPendaftaranBPJS
              : null,
          jkkOn:
            workSchemeDefaults?.JKK && workSchemeDefaults?.JKK !== "null"
              ? true
              : false,
          jkk:
            workSchemeDefaults?.JKK && workSchemeDefaults?.JKK !== "null"
              ? workSchemeDefaults?.JKK
              : null,
          jkmOn:
            workSchemeDefaults?.JKM && workSchemeDefaults?.JKM !== "null"
              ? true
              : false,
          jkm:
            workSchemeDefaults?.JKM && workSchemeDefaults?.JKM !== "null"
              ? workSchemeDefaults?.JKM
              : null,
          jhtOn:
            workSchemeDefaults?.JHT && workSchemeDefaults?.JHT !== "null"
              ? true
              : false,
          jht:
            workSchemeDefaults?.JHT && workSchemeDefaults?.JHT !== "null"
              ? workSchemeDefaults?.JHT
              : null,
          healthOn:
            workSchemeDefaults?.KS && workSchemeDefaults?.KS !== "null"
              ? true
              : false,
          health:
            workSchemeDefaults?.KS && workSchemeDefaults?.KS !== "null"
              ? workSchemeDefaults?.KS
              : null,
          akhdkOn:
            workSchemeDefaults?.AKDHK && workSchemeDefaults?.AKDHK !== "null"
              ? true
              : false,
          akhdk:
            workSchemeDefaults?.AKDHK && workSchemeDefaults?.AKDHK !== "null"
              ? workSchemeDefaults?.AKDHK
              : null,
          jpOn:
            workSchemeDefaults?.JP && workSchemeDefaults?.JP !== "null"
              ? true
              : false,
          jp:
            workSchemeDefaults?.JP && workSchemeDefaults?.JP !== "null"
              ? workSchemeDefaults?.JP
              : null,
          cutOffBPJSTKO: isCutOffManual
            ? "Manual"
            : workSchemeDefaults?.PotonganBPJSTKO,
          cutOffNominal: !isCutOffManual
            ? ""
            : workSchemeDefaults?.PotonganBPJSTKO,
        },
        others: {
          manfee: manfeeArr || [],
          thr: workSchemeDefaults?.PenagihanTHR || null,
          uak: workSchemeDefaults?.PenagihanUAK || null,
          tkoBank: tkoBanksArr || [],
        },
        isLoaded: isLoadingSecond || !defaultSecond ? "NO" : "YES",
      };
    }
  }, [defaultSecond, isCutOffManual, isLoadingSecond]);

  const defaultAdditionalForm = useMemo(() => {
    const storageForm = sessionStorage?.getItem("additionalFormEdit");
    const isLoaded = storageForm
      ? JSON.parse(storageForm)?.isLoaded === "YES"
      : false;

    const defaultAdditional = defaultThird?.additional;
    if (isLoaded) {
      return JSON.parse(storageForm);
    } else {
      return {
        contacts: defaultAdditional?.contactInformation?.map((obj) => {
          return {
            id: obj?.contactID,
            schemeID: obj?.schemeID,
            name: obj?.ClientContact?.name,
            jobTitle: obj?.ClientContact?.jobTitle,
            email: obj?.ClientContact?.email,
            phoneNumber: obj?.ClientContact?.phoneNumber,
            workUnit: obj?.ClientContact?.workUnit,
            clientCode: obj?.ClientContact?.clientCode,
          };
        }),
        recipientsAccount: {
          bankName:
            defaultAdditional?.rekeningPenerimaInvoice?.bankName?.replace(
              "BANK ",
              ""
            ) || "OCBC NISP",
          bankNumber:
            defaultAdditional?.rekeningPenerimaInvoice?.bankNumber ||
            "417800003234",
          bankRecipient: "PT Permata Indo Sejahtera",
        },
        invoiceFlow: {
          dateOfReceiptOfData: defaultAdditional?.flowInvoice
            ?.TanggalDiterimaData
            ? defaultAdditional?.flowInvoice?.TanggalDiterimaData
            : null,
          UP: defaultAdditional?.flowInvoice?.UP
            ? defaultAdditional?.flowInvoice?.UP
            : null,
          dateOfFillingOfSoftCopy: defaultAdditional?.flowInvoice
            ?.TanggalPengajuanSoftCopyInvoice
            ? defaultAdditional?.flowInvoice?.TanggalPengajuanSoftCopyInvoice
            : null,
          dateOfFillingOfHardCopy: defaultAdditional?.flowInvoice
            ?.TanggalHardCopyInvoiceDiterima
            ? defaultAdditional?.flowInvoice?.TanggalHardCopyInvoiceDiterima
            : null,
          payrollDate: defaultAdditional?.flowInvoice?.TanggalMulaiPenggajian
            ? defaultAdditional?.flowInvoice?.TanggalMulaiPenggajian
            : null,
          cutOffAttendance: defaultAdditional?.flowInvoice?.TanggalCutOffAbsensi
            ? defaultAdditional?.flowInvoice?.TanggalCutOffAbsensi
            : null,
          supportingDocument: defaultAdditional?.flowInvoice
            ?.DokumenPendukungInvoice
            ? defaultAdditional?.flowInvoice?.DokumenPendukungInvoice
            : null,
        },
        cooperationPeriod: {
          startDate: defaultAdditional?.periodeKerjasama?.startDate || null,
          endDate: defaultAdditional?.periodeKerjasama?.endDate || null,
          documentType: defaultAdditional?.periodeKerjasama?.docType || "PKS",
          documentNumber:
            defaultAdditional?.periodeKerjasama?.docNumber || null,
          id: defaultAdditional?.periodeKerjasama?.id || null,
        },
        others: {
          deductedAttendance: defaultAdditional?.others?.KetentuanPotongAbsen
            ? defaultAdditional?.others?.KetentuanPotongAbsen
            : null,
          others: defaultAdditional?.others?.KeteranganLainnya
            ? defaultAdditional?.others?.KeteranganLainnya
            : null,
        },

        isLoaded: isLoadingThird || !defaultThird ? "NO" : "YES",
      };
    }
  }, [defaultThird, isLoadingThird]);

  const [selectedButtonBPJS, setSelectedButtonBPJS] = useState(
    defaultSecond?.workScheme?.PenagihanBPU === "" ||
      defaultSecond?.workScheme?.PenagihanBPU === null
      ? "Tenaga Kerja"
      : "BPU"
  );
  const [selectedValueBPU, setSelectedValueBPU] = useState(
    defaultSecond?.workScheme?.PenagihanBPU || ""
  );

  // console.log(
  //   selectedValueBPU,
  //   "hahha",
  //   defaultSecond?.workScheme?.PenagihanBPU
  // );

  const sessionSelectedButtonBPU = sessionStorage.getItem(
    "idButtonTypeBPUEdit"
  );

  const setActiveButtonBPJS = (idButton) => {
    setSelectedButtonBPJS(idButton);
    sessionStorage.setItem("idButtonTypeBPUEdit", idButton);
  };

  useEffect(() => {
    if (
      defaultSecond?.workScheme?.PenagihanBPU === "" ||
      defaultSecond?.workScheme?.PenagihanBPU === null
    ) {
      setSelectedButtonBPJS("Tenaga Kerja");
    } else if (
      defaultSecond?.workScheme?.PenagihanBPU === "Tagih Ke Perusahaan"
    ) {
      setSelectedButtonBPJS("BPU");
      setSelectedValueBPU("Tagih Ke Perusahaan");
    } else if (
      defaultSecond?.workScheme?.PenagihanBPU ===
      "Tagih Ke Perusahaan & Karyawan"
    ) {
      setSelectedButtonBPJS("BPU");
      setSelectedValueBPU("Tagih Ke Perusahaan & Karyawan");
    } else {
      setSelectedButtonBPJS("BPU");
      setSelectedValueBPU("Tagih Ke Karyawan");
    }
  }, [defaultSecond?.workScheme?.PenagihanBPU]);

  useEffect(() => {
    if (sessionSelectedButtonBPU) {
      setSelectedButtonBPJS(sessionSelectedButtonBPU);
    } else {
      setSelectedButtonBPJS(
        defaultSecond?.workScheme?.PenagihanBPU === "" ||
          defaultSecond?.workScheme?.PenagihanBPU === null
          ? "Tenaga Kerja"
          : "BPU"
      );
    }
  }, [sessionSelectedButtonBPU, defaultSecond?.workScheme?.PenagihanBPU]);

  const defaultManPowerForm = useMemo(() => {
    const arr = [];
    const manPowerDefaults = defaultOrderForm?.manPowers;
    const cities = syncData?.cities;

    for (let i = 0; i < manPowerDefaults?.length; i++) {
      const perLocation = manPowerDefaults[i];
      const city = cities?.find((c) => c?.code === perLocation?.cityCode);
      const areaEquipments = perLocation?.areaEquipments?.map((obj) => {
        return {
          id: obj?.id,
          name: obj?.name,
          value: obj?.price,
        };
      });
      let each = {
        id: perLocation?.cityCode,
        location: perLocation?.cityCode,
        umk: city?.umk || 0,
        jobs: [],
        areaWorkEquipments: areaEquipments || [],
      };
      const jobArr = [];
      for (let j = 0; j < perLocation?.manPowers?.length; j++) {
        const job = perLocation?.manPowers[j];
        const allowances = job?.allowances?.map((obj) => {
          return {
            id: obj?.id,
            name: obj?.name,
            value: obj?.value,
            type: obj?.type || "",
          };
        });
        const personalEquipments = job?.personalEquipments?.map((obj) => {
          return {
            id: obj?.id,
            name: obj?.name,
            value: obj?.price,
          };
        });
        const qualifications = job?.qualifications?.map((obj) => {
          return {
            id: obj?.id,
            name: obj?.name,
            value: obj?.value,
          };
        });
        const eachJob = {
          id: job?.id,
          title: job?.name,
          contractType: job?.contractType || "",
          salary: `${job?.salary}`?.split("::")[0] || "",
          salaryPeriod: `${job?.salary}`?.split("::")[1] || "",
          personalEquipments: personalEquipments || [],
          allowances: allowances || [],
          demand: +job?.amount,
          fulfillmentDate: job?.expectedFulfillmentDate || "",
          qualifications: qualifications || [],
        };
        jobArr.push(eachJob);
      }
      each.jobs = jobArr;
      arr.push(each);
    }

    return arr;
  }, [syncData, defaultOrderForm]);

  const defaultDeploymentsData = useMemo(() => {
    return [
      {
        role: "BUSINESS MANAGER",
        required: true,
        delegations: syncData?.users?.businessManagers?.map((obj) => {
          return {
            id: obj?.employeeId,
            name: obj?.name,
            profilePicture: "",
            isPicked: false,
            isPIC: false,
          };
        }),
      },
      {
        role: "ACCOUNT MANAGER / ACCOUNT OFFICER",
        delegations: syncData?.users?.accountManagerOrPIC?.map((obj) => {
          return {
            id: obj?.employeeId,
            name: obj?.name,
            profilePicture: "",
            isPicked: false,
            isPIC: false,
          };
        }),
      },
      {
        role: "ADMIN / ADMIN CONTRACT",
        delegations: syncData?.users?.accountOfficerOrAdmin?.map((obj) => {
          return {
            id: obj?.employeeId,
            name: obj?.name,
            profilePicture: "",
            isPicked: false,
            isPIC: false,
          };
        }),
      },
      {
        role: "RECRUITMENT OFFICER",
        delegations: syncData?.users?.recruitmentOfficers?.map((obj) => {
          return {
            id: obj?.employeeId,
            name: obj?.name,
            profilePicture: "",
            isPicked: false,
            isPIC: false,
          };
        }),
      },
    ];
  }, [
    syncData?.users?.accountManagerOrPIC,
    syncData?.users?.accountOfficerOrAdmin,
    syncData?.users?.businessManagers,
    syncData?.users?.recruitmentOfficers,
  ]);

  const getLocationUMK = useCallback(
    (id) => {
      return syncData?.cities?.find((obj) => obj?.code === id)?.umk || 4500000;
    },
    [syncData?.cities]
  );

  const getDenom = useCallback(
    (name) => {
      return (
        syncData?.qualifications?.find((obj) => obj?.value === name)?.denom ||
        ""
      );
    },
    [syncData?.qualifications]
  );

  const defaultManPowerFormArr = useMemo(() => {
    return defaultOrderForm?.manPowers?.map((obj) => {
      const locationUsers = obj?.delegations;
      const ddData = [
        {
          role: "BUSINESS MANAGER",
          delegations: syncData?.users?.businessManagers?.map((su) => {
            const found = locationUsers?.find(
              (u) => u?.employeeId === su?.employeeId
            );
            return {
              delegationID: found?.delegationID || "",
              id: su?.employeeId,
              name: su?.name,
              profilePicture: "",
              isPicked: !!found,
              isPIC: found?.isPIC || false,
            };
          }),
        },
        {
          role: "ACCOUNT MANAGER",
          delegations: syncData?.users?.accountManagerOrPIC?.map((su) => {
            const found = locationUsers?.find(
              (u) => u?.employeeId === su?.employeeId
            );
            return {
              delegationID: found?.delegationID || "",
              id: su?.employeeId,
              name: su?.name,
              profilePicture: "",
              isPicked: !!found,
              isPIC: found?.isPIC || false,
            };
          }),
        },
        {
          role: "ACCOUNT OFFICER / ADMIN",
          delegations: syncData?.users?.accountOfficerOrAdmin?.map((su) => {
            const found = locationUsers?.find(
              (u) => u?.employeeId === su?.employeeId
            );
            return {
              delegationID: found?.delegationID || "",
              id: su?.employeeId,
              name: su?.name,
              profilePicture: "",
              isPicked: !!found,
              isPIC: found?.isPIC || false,
            };
          }),
        },
        {
          role: "RECRUITMENT OFFICER",
          delegations: syncData?.users?.recruitmentOfficers?.map((su) => {
            const found = locationUsers?.find(
              (u) => u?.employeeId === su?.employeeId
            );
            return {
              delegationID: found?.delegationID || "",
              id: su?.employeeId,
              name: su?.name,
              profilePicture: "",
              isPicked: !!found,
              isPIC: found?.isPIC || false,
            };
          }),
        },
      ];
      return {
        cityCode: obj?.cityCode,
        locationID: obj?.cityCode,
        locationName: obj?.cityName,
        locationUMK: getLocationUMK(obj?.cityCode),
        deployments: ddData,
        positions: obj?.manPowers?.map((p) => {
          return {
            positionID: p?.id,
            id: p?.id,
            title: p?.name,
            amount: p?.amount,
            contractType: p?.contractType,
            salaryValue: p?.salary?.includes("::")
              ? p?.salary?.split("::")[0]
              : p?.salary,
            salaryDenom: p?.salary?.includes("::")
              ? p?.salary?.split("::")[1]
              : "Bulanan",
            personalEquipments: p?.personalEquipments?.map((pe) => {
              return {
                id: pe?.id,
                equipmentID: pe?.id,
                positionID: pe?.positionID,
                schemeID: pe?.schemeID,
                name: pe?.name,
                value: pe?.price,
              };
            }),
            allowances: p?.allowances?.map((a) => {
              return {
                id: a?.id,
                type: a?.type,
                allowanceID: a?.id,
                positionID: a?.positionID,
                name: a?.name,
                value: a?.value,
              };
            }),
            expectedDate: new Date(p?.expectedFulfillmentDate),
            qualifications: p?.qualifications?.map((q) => {
              return {
                id: q?.id,
                qualificationID: q?.id,
                positionID: q?.positionID,
                name: q?.name,
                value: q?.value,
                denom: getDenom(q?.name),
              };
            }),
          };
        }),
        areaEquipments: obj?.areaEquipments?.map((eq) => {
          return {
            id: eq?.id,
            equipmentID: eq?.id,
            schemeID: eq?.schemeID,
            name: eq?.name,
            value: eq?.price,
          };
        }),
      };
    });
  }, [
    defaultOrderForm?.manPowers,
    getDenom,
    getLocationUMK,
    syncData?.users?.accountManagerOrPIC,
    syncData?.users?.accountOfficerOrAdmin,
    syncData?.users?.businessManagers,
    syncData?.users?.recruitmentOfficers,
  ]);

  // eslint-disable-next-line no-unused-vars
  const [manPowerFormArr, setManPowerFormArr] = useState(
    defaultManPowerFormArr || []
  );

  const defaultDelegationsForm = useMemo(() => {
    const parsedForm = sessionStorage?.getItem("delegationsFormEdit")
      ? JSON.parse(sessionStorage?.getItem("delegationsFormEdit"))
      : [];

    return parsedForm?.length
      ? parsedForm
      : defaultFourth?.position?.deployment?.map((obj) => {
          const users = obj?.users;
          const sorted = [
            users?.businessManagers,
            users?.accountManagerOrPIC,
            users?.accountOfficerOrAdmin,
            users?.recruitmentOfficers,
          ];
          return {
            id: makeRandomString(5),
            preventDelete: true,
            cityCode: obj?.cityCode,
            cityName: obj?.cityName,
            umk: syncData?.cities?.find((c) => c?.code === obj?.cityCode)?.umk,
            deployments: defaultDeploymentsData?.map((d, dI) => {
              return {
                ...d,
                delegations: d?.delegations?.map((de) => {
                  return {
                    ...de,
                    delegationID: sorted[dI]?.find(
                      (s) => s?.employeeId === de?.id
                    )?.id,
                    isPicked: sorted[dI]?.find((s) => s?.employeeId === de?.id)
                      ? true
                      : false,
                    isPIC: sorted[dI]?.find((s) => s?.employeeId === de?.id)
                      ?.isPIC
                      ? true
                      : false,
                  };
                }),
              };
            }),
          };
        });
  }, [
    defaultDeploymentsData,
    defaultFourth?.position?.deployment,
    syncData?.cities,
  ]);

  const defaultPositionsForm = useMemo(() => {
    const parsedForm = sessionStorage?.getItem("positionsFormEdit")
      ? JSON.parse(sessionStorage?.getItem("positionsFormEdit"))
      : [];
    return parsedForm?.length
      ? parsedForm
      : defaultFifthNew?.position?.list?.map((obj) => {
          return {
            id: makeRandomString(5),
            preventDelete: true,
            search: "",
            isExpanded: false,
            positionName: obj?.position,
            dedicatedROName: obj?.dedicatedRO?.name,
            dedicatedROId: obj?.dedicatedRO?.employeeId || "",
            qualifications:
              obj?.qualification?.map((q) => {
                return {
                  id: q?.id,
                  qualificationID: q?.id,
                  name: q?.name,
                  value: q?.value,
                  denom: syncData?.qualifications?.find(
                    (o) => o?.value === q?.name
                  )?.denom,
                };
              }) || [],
            citiesData: defaultDelegationsForm?.map((d) => {
              const found = obj?.cities?.find(
                (ci) => ci?.position?.cityCode === d?.cityCode
              );
              const salaryJobs = found
                ? found?.position?.salary?.includes("::")
                  ? found?.position?.salary?.split("::")[0]
                  : found?.position?.salary
                : syncData?.cities?.find((sc) => sc?.code === d?.cityCode)
                    ?.umk || 4500000;

              const { jkk, jkm, jht } = BPURangeSalary(
                salaryJobs,
                found
                  ? found?.position?.salary?.includes("::")
                    ? found?.position?.salary?.split("::")[1]
                    : "Bulanan"
                  : "Bulanan"
              );
              return {
                id: found?.position?.id || d?.cityName || makeRandomString(5),
                positionID: found?.position?.id || "",
                preventDelete: true,
                preventEdit: true,
                isApplied: found ? true : false,
                cityCode: d?.cityCode,
                cityName: found?.cityName || d?.cityName,
                umk: syncData?.cities?.find((sc) => sc?.code === d?.cityCode)
                  ?.umk,
                amount: found?.position?.amount || 1,
                salaryValue: salaryJobs,
                salaryDenom: found
                  ? found?.position?.salary?.includes("::")
                    ? found?.position?.salary?.split("::")[1]
                    : "Bulanan"
                  : "Bulanan",
                personalEquipments: found
                  ? found?.position?.personalEquipments?.map((p) => {
                      return {
                        equipmentID: p?.id,
                        name: p?.name,
                        value: p?.price,
                      };
                    })
                  : [],
                allowances: found
                  ? found?.position?.allowances
                      ?.filter((a) => a?.type !== "BPU")
                      ?.map((a) => {
                        return {
                          allowanceID: a?.id,
                          name: a?.name,
                          value: a?.value,
                          denom: a?.denom ? a?.denom : "Bulanan",
                        };
                      })
                  : [],
                jkk: found
                  ? selectedValueBPU?.includes("Tagih Ke Perusahaan")
                    ? found?.position?.allowances?.find(
                        (a) =>
                          a?.name === "JKK" && a?.billingTo === "Perusahaan"
                      )?.value || "0"
                    : found?.position?.allowances?.find(
                        (a) => a?.name === "JKK" && a?.billingTo === "Karyawan"
                      )?.value || "0"
                  : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                  ? salaryJobs
                    ? `${+jkk / 2}`
                    : "0"
                  : salaryJobs
                  ? jkk
                  : "0",

                jkkID: selectedValueBPU?.includes("Tagih Ke Perusahaan")
                  ? found?.position?.allowances?.find(
                      (a) => a?.name === "JKK" && a?.billingTo === "Perusahaan"
                    )?.id || ""
                  : found?.position?.allowances?.find(
                      (a) => a?.name === "JKK" && a?.billingTo === "Karyawan"
                    )?.id || "",

                jkm: found
                  ? selectedValueBPU?.includes("Tagih Ke Perusahaan")
                    ? found?.position?.allowances?.find(
                        (a) =>
                          a?.name === "JKM" && a?.billingTo === "Perusahaan"
                      )?.value || "0"
                    : found?.position?.allowances?.find(
                        (a) => a?.name === "JKM" && a?.billingTo === "Karyawan"
                      )?.value || "0"
                  : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                  ? salaryJobs
                    ? `${+jkm / 2}`
                    : "0"
                  : salaryJobs
                  ? jkm
                  : "0",

                jkmID: selectedValueBPU?.includes("Tagih Ke Perusahaan")
                  ? found?.position?.allowances?.find(
                      (a) => a?.name === "JKM" && a?.billingTo === "Perusahaan"
                    )?.id || ""
                  : found?.position?.allowances?.find(
                      (a) => a?.name === "JKM" && a?.billingTo === "Karyawan"
                    )?.id || "",

                jht: found
                  ? selectedValueBPU?.includes("Tagih Ke Perusahaan")
                    ? found?.position?.allowances?.find(
                        (a) =>
                          a?.name === "JHT" && a?.billingTo === "Perusahaan"
                      )?.value || "0"
                    : found?.position?.allowances?.find(
                        (a) => a?.name === "JHT" && a?.billingTo === "Karyawan"
                      )?.value || "0"
                  : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                  ? salaryJobs
                    ? `${+jht / 2}`
                    : "0"
                  : salaryJobs
                  ? jht
                  : "0",

                jhtID: selectedValueBPU?.includes("Tagih Ke Perusahaan")
                  ? found?.position?.allowances?.find(
                      (a) => a?.name === "JHT" && a?.billingTo === "Perusahaan"
                    )?.id || ""
                  : found?.position?.allowances?.find(
                      (a) => a?.name === "JHT" && a?.billingTo === "Karyawan"
                    )?.id || "",

                jkk1: found
                  ? found?.position?.allowances?.find(
                      (a) => a?.name === "JKK" && a?.billingTo === "Karyawan"
                    )?.value || "0"
                  : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                  ? salaryJobs
                    ? `${+jkk / 2}`
                    : "0"
                  : "0",
                jkkID1:
                  found?.position?.allowances?.find(
                    (a) => a?.name === "JKK" && a?.billingTo === "Karyawan"
                  )?.id || "",
                jkm1: found
                  ? found?.position?.allowances?.find(
                      (a) => a?.name === "JKM" && a?.billingTo === "Karyawan"
                    )?.value || "0"
                  : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                  ? salaryJobs
                    ? `${+jkm / 2}`
                    : "0"
                  : "0",
                jkmID1:
                  found?.position?.allowances?.find(
                    (a) => a?.name === "JKM" && a?.billingTo === "Karyawan"
                  )?.id || "",
                jht1: found
                  ? found?.position?.allowances?.find(
                      (a) => a?.name === "JHT" && a?.billingTo === "Karyawan"
                    )?.value || "0"
                  : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
                  ? salaryJobs
                    ? `${+jht / 2}`
                    : "0"
                  : "0",
                jhtID1:
                  found?.position?.allowances?.find(
                    (a) => a?.name === "JHT" && a?.billingTo === "Karyawan"
                  )?.id || "",

                isJkkManual: found ? true : false,
                isJkmManual: found ? true : false,
                isJhtManual: found ? true : false,
                isJkk1Manual: found ? true : false,
                isJkm1Manual: found ? true : false,
                isJht1Manual: found ? true : false,
                expectedDate: found?.position?.expectedFulfillmentDate || "",
              };
            }),
          };
        });
  }, [
    defaultDelegationsForm,
    defaultFifthNew?.position?.list,
    syncData?.cities,
    syncData?.qualifications,
    selectedValueBPU,
    // BPURangeSalary,
  ]);

  const [delegationsForm, setDelegationsForm] = useState(
    defaultDelegationsForm?.length ? defaultDelegationsForm : []
  );

  // const [positionsForm, setPositionsForm] = useState(
  //   defaultPositionsForm?.length ? defaultPositionsForm : []
  // );

  // useEffect(() => {
  //   if (defaultPositionsForm?.length) {
  //     setPositionsForm(defaultPositionsForm);
  //   }
  // }, [setPositionsForm, defaultPositionsForm]);

  useEffect(() => {
    setDelegationsForm(defaultDelegationsForm);
  }, [defaultDelegationsForm]);

  useEffect(() => {
    setPositionsForm(defaultPositionsForm);
  }, [defaultPositionsForm, setPositionsForm]);

  const defaultManfee = useMemo(() => {
    return sessionStorage?.getItem("manfeeEdit")
      ? sessionStorage?.getItem("manfeeEdit")
      : defaultFifth?.paymentMethodEstimation?.setManfee;
  }, [defaultFifth?.paymentMethodEstimation?.setManfee]);

  const defaultTOP = useMemo(() => {
    return sessionStorage?.getItem("TOPEdit")
      ? sessionStorage?.getItem("TOPEdit")
      : defaultFifth?.paymentMethodEstimation?.setTop;
  }, [defaultFifth?.paymentMethodEstimation?.setTop]);
  const defaultRemark = defaultOrderForm?.remark || null;

  const defaultFiles = useMemo(() => {
    return defaultOrderForm?.additional?.files?.map((file) => {
      return {
        ...file,
        name: file?.url,
      };
    });
  }, [defaultOrderForm]);

  const defaultDelegations = useMemo(() => {
    const bmArr = [];
    const amArr = [];
    const admArr = [];
    const roArr = [];

    const users = syncData?.users;

    const defaultDeployment = defaultFourth?.deployment;

    for (let i = 0; i < defaultDeployment?.length; i++) {
      const perlocationArr = defaultDeployment[i];
      const deploymentPerLocation = perlocationArr?.users;

      for (let j = 0; j < deploymentPerLocation?.length; j++) {
        const perUser = deploymentPerLocation[j];
        if (
          users?.businessManagers?.find(
            (obj) => obj?.employeeId === perUser?.employeeId
          )
        ) {
          bmArr?.push({
            ...perUser,
            locationId: perlocationArr?.code,
          });
        }
        if (
          users?.accountManagerOrPIC?.find(
            (obj) => obj?.employeeId === perUser?.employeeId
          )
        ) {
          amArr?.push({
            ...perUser,
            locationId: perlocationArr?.code,
          });
        }
        if (
          users?.accountOfficerOrAdmin?.find(
            (obj) => obj?.employeeId === perUser?.employeeId
          )
        ) {
          admArr?.push({
            ...perUser,
            locationId: perlocationArr?.code,
          });
        }
        if (
          users?.recruitmentOfficers?.find(
            (obj) => obj?.employeeId === perUser?.employeeId
          )
        ) {
          roArr?.push({
            ...perUser,
            locationId: perlocationArr?.code,
          });
        }
      }
    }
    return {
      businessManagers: bmArr,
      accountManagers: amArr,
      admins: admArr,
      recruiters: roArr,
    };
  }, [defaultFourth?.deployment, syncData?.users]);

  // FORM STATES PER STEP

  const [manfee, setManFee] = useState(defaultManfee || "");

  useEffect(() => {
    setManFee(defaultManfee);
  }, [defaultManfee]);
  const [TOP, setTOP] = useState(defaultTOP || "");
  useEffect(() => {
    setTOP(defaultTOP);
  }, [defaultTOP]);
  const [remark, setRemark] = useState(defaultRemark || "");
  const [files, setFiles] = useState(defaultFiles || []);

  // eslint-disable-next-line no-unused-vars
  const [delegations, setDelegations] = useState({
    businessManagers: defaultDelegations?.businessManagers || [],
    accountManagers: defaultDelegations?.accountManagers || [],
    admins: defaultDelegations?.admins || [],
    recruiters: defaultDelegations?.recruiters || [],
  });

  // firstStep
  const [contractForm, setContractForm] = useState({
    contract: defaultContractForm?.contract
      ? defaultContractForm?.contract
      : null,
  });

  const defaultContracts = useMemo(() => {
    const storageContracts = sessionStorage.getItem("contractsEdit")
      ? JSON?.parse(sessionStorage?.getItem("contractsEdit"))
      : [];
    return storageContracts?.length
      ? storageContracts
      : defaultFirst?.contractLogs?.map((obj) => {
          return {
            ...obj,
            id: obj?.id,
            contractID: obj?.id,
            docType: obj?.docType,
            docNumber: obj?.flbNumber,
            startDate: obj?.startDate,
            endDate: obj?.endDate,
            uploadedAt: obj?.createdAt,
            status: obj?.status,
            // file: null,
            // fileUrl: fileBaseUrl + obj?.file
          };
        }) || [];
  }, [defaultFirst?.contractLogs]);

  const defaultContracts2 = [...defaultContracts];

  for (let i = 0; i < defaultContracts2.length; i++) {
    const str = defaultContracts2[i]["str"];
    if (str) {
      defaultContracts2[i]["file"] = base64ToFile(str, "file");
    }
  }

  const [contracts, setContracts] = useState(
    defaultContracts2?.length ? defaultContracts2 : []
  );

  useEffect(() => {
    setContracts(defaultContracts || []);
  }, [defaultContracts]);

  // secondStep
  const [workSchemeForm, setWorkSchemeForm] = useState({
    isLoaded: defaultWorkSchemeForm?.isLoaded,
    tkoContract: defaultWorkSchemeForm?.tkoContract
      ? defaultWorkSchemeForm?.tkoContract
      : null,
    taxs: {
      pph21: defaultWorkSchemeForm?.taxs?.pph21
        ? defaultWorkSchemeForm?.taxs?.pph21
        : null,
      pph23: defaultWorkSchemeForm?.taxs?.pph23
        ? defaultWorkSchemeForm?.taxs?.pph23
        : null,
      ppn: defaultWorkSchemeForm?.taxs?.ppn
        ? defaultWorkSchemeForm?.taxs?.ppn
        : null,
    },
    bpjs: {
      registration: defaultWorkSchemeForm?.bpjs?.registration
        ? defaultWorkSchemeForm?.bpjs?.registration
        : null,
      paymentProof: defaultWorkSchemeForm?.bpjs?.paymentProof
        ? defaultWorkSchemeForm?.bpjs?.paymentProof
        : null,
      jkkOn: defaultWorkSchemeForm?.bpjs?.jkkOn
        ? defaultWorkSchemeForm?.bpjs?.jkkOn
        : false,
      jkk: defaultWorkSchemeForm?.bpjs?.jkk
        ? defaultWorkSchemeForm?.bpjs?.jkk
        : null,
      jkmOn: defaultWorkSchemeForm?.bpjs?.jkmOn
        ? defaultWorkSchemeForm?.bpjs?.jkmOn
        : false,
      jkm: defaultWorkSchemeForm?.bpjs?.jkm
        ? defaultWorkSchemeForm?.bpjs?.jkm
        : null,
      jhtOn: defaultWorkSchemeForm?.bpjs?.jhtOn
        ? defaultWorkSchemeForm?.bpjs?.jhtOn
        : false,
      jht: defaultWorkSchemeForm?.bpjs?.jht
        ? defaultWorkSchemeForm?.bpjs?.jht
        : null,
      healthOn: defaultWorkSchemeForm?.bpjs?.healthOn
        ? defaultWorkSchemeForm?.bpjs?.healthOn
        : false,
      health: defaultWorkSchemeForm?.bpjs?.health
        ? defaultWorkSchemeForm?.bpjs?.health
        : null,
      akhdkOn: defaultWorkSchemeForm?.bpjs?.akhdkOn
        ? defaultWorkSchemeForm?.bpjs?.akhdkOn
        : false,
      akhdk: defaultWorkSchemeForm?.bpjs?.akhdk
        ? defaultWorkSchemeForm?.bpjs?.akhdk
        : null,
      jpOn: defaultWorkSchemeForm?.bpjs?.jpOn
        ? defaultWorkSchemeForm?.bpjs?.jpOn
        : null,
      jp: defaultWorkSchemeForm?.bpjs?.jp
        ? defaultWorkSchemeForm?.bpjs?.jp
        : null,
      cutOffBPJSTKO: defaultWorkSchemeForm?.bpjs?.cutOffBPJSTKO
        ? defaultWorkSchemeForm?.bpjs?.cutOffBPJSTKO
        : null,
      cutOffNominal: defaultWorkSchemeForm?.bpjs?.cutOffNominal
        ? defaultWorkSchemeForm?.bpjs?.cutOffNominal
        : null,
    },
    others: {
      manfee: defaultWorkSchemeForm?.others?.manfee
        ? defaultWorkSchemeForm?.others?.manfee
        : [],
      thr: defaultWorkSchemeForm?.others?.thr
        ? defaultWorkSchemeForm?.others?.thr
        : null,
      uak: defaultWorkSchemeForm?.others?.uak
        ? defaultWorkSchemeForm?.others?.uak
        : null,
      tkoBank: defaultWorkSchemeForm?.others?.tkoBank
        ? defaultWorkSchemeForm?.others?.tkoBank
        : [],
    },
  });

  useEffect(() => {
    if (!isLoadingSecond) {
      setWorkSchemeForm(defaultWorkSchemeForm);
    }
  }, [defaultWorkSchemeForm, isLoadingSecond]);

  // thirdStep
  const [additionalForm, setAdditionalForm] = useState({
    contacts: defaultAdditionalForm?.contacts
      ? defaultAdditionalForm?.contacts
      : [],
    recipientsAccount: {
      bankName: defaultAdditionalForm?.recipientsAccount?.bankName || null,
      bankNumber: defaultAdditionalForm?.recipientsAccount?.bankNumber || null,
      bankRecipient: "PT Permata Indo Sejahtera",
    },
    invoiceFlow: {
      dateOfReceiptOfData: defaultAdditionalForm?.invoiceFlow
        ?.dateOfReceiptOfData
        ? defaultAdditionalForm?.invoiceFlow?.dateOfReceiptOfData
        : null,
      UP: defaultAdditionalForm?.invoiceFlow?.UP
        ? defaultAdditionalForm?.invoiceFlow?.UP
        : null,
      dateOfFillingOfSoftCopy: defaultAdditionalForm?.invoiceFlow
        ?.dateOfFillingOfSoftCopy
        ? defaultAdditionalForm?.invoiceFlow?.dateOfFillingOfSoftCopy
        : null,
      dateOfFillingOfHardCopy: defaultAdditionalForm?.invoiceFlow
        ?.dateOfFillingOfHardCopy
        ? defaultAdditionalForm?.invoiceFlow?.dateOfFillingOfHardCopy
        : null,
      payrollDate: defaultAdditionalForm?.invoiceFlow?.payrollDate
        ? defaultAdditionalForm?.invoiceFlow?.payrollDate
        : null,
      cutOffAttendance: defaultAdditionalForm?.invoiceFlow?.cutOffAttendance
        ? defaultAdditionalForm?.invoiceFlow?.cutOffAttendance
        : null,
      supportingDocument: defaultAdditionalForm?.invoiceFlow?.supportingDocument
        ? defaultAdditionalForm?.invoiceFlow?.supportingDocument
        : null,
    },
    // cooperationPeriod: {
    //   startDate: defaultAdditionalForm?.cooperationPeriod?.startDate || null,
    //   endDate: defaultAdditionalForm?.cooperationPeriod?.endDate
    //     ? defaultAdditionalForm?.cooperationPeriod?.endDate
    //     : null,
    //   documentType:
    //     defaultAdditionalForm?.cooperationPeriod?.documentType || 'PKS',
    //   documentNumber:
    //     defaultAdditionalForm?.cooperationPeriod?.documentNumber || null,
    //   id: defaultAdditionalForm?.cooperationPeriod?.id || null,
    // },
    others: {
      deductedAttendance: defaultAdditionalForm?.others?.deductedAttendance
        ? defaultAdditionalForm?.others?.deductedAttendance
        : null,
      others: defaultAdditionalForm?.others?.others
        ? defaultAdditionalForm?.others?.others
        : null,
    },
  });

  useEffect(() => {
    setAdditionalForm(defaultAdditionalForm);
  }, [defaultAdditionalForm]);

  // fourthStep
  const [manPowerForm, setManPowerForm] = useState(
    defaultManPowerForm?.length > 0 ? defaultManPowerForm : []
  );

  // allSteps

  // const fixedDefaultDelegations = useMemo(() => {
  //   const arr = [];

  //   const businessManagers = defaultDelegations?.businessManagers;
  //   for (let i = 0; i < businessManagers?.length; i++) {
  //     const bm = businessManagers[i];
  //     arr.push({
  //       delegationID: bm?.delegationID,
  //       employeeId: bm?.employeeId,
  //       cityCode: bm?.locationId,
  //     });
  //   }

  //   const accountManagers = defaultDelegations?.accountManagers;
  //   for (let i = 0; i < accountManagers?.length; i++) {
  //     const am = accountManagers[i];
  //     arr.push({
  //       delegationID: am?.delegationID,
  //       employeeId: am?.employeeId,
  //       cityCode: am?.locationId,
  //       isPIC: am?.isPIC === false ? "NO" : "YES",
  //     });
  //   }

  //   const admins = defaultDelegations?.admins;
  //   for (let i = 0; i < admins?.length; i++) {
  //     const admin = admins[i];
  //     arr.push({
  //       delegationID: admin?.delegationID,
  //       employeeId: admin?.employeeId,
  //       cityCode: admin?.locationId,
  //       isPIC: admin?.isPIC === false ? "NO" : "YES",
  //     });
  //   }

  //   const recruiters = defaultDelegations?.recruiters;
  //   for (let i = 0; i < recruiters?.length; i++) {
  //     const ro = recruiters[i];
  //     arr.push({
  //       delegationID: ro?.delegationID,
  //       employeeId: ro?.employeeId,
  //       cityCode: ro?.locationId,
  //       isPIC: ro?.isPIC === false ? "NO" : "YES",
  //     });
  //   }

  //   return arr;
  //   // eslint-disable-next-line
  // }, [defaultDelegations, trigger]);

  const fixedDelegations = useMemo(() => {
    const arr = [];

    const businessManagers = delegations?.businessManagers;
    for (let i = 0; i < businessManagers?.length; i++) {
      const bm = businessManagers[i];
      if (manPowerForm?.find((obj) => obj?.location === bm?.locationId)) {
        arr.push({
          delegationID: bm?.delegationID,
          employeeId: bm?.employeeId,
          cityCode: bm?.locationId,
        });
      }
    }

    const accountManagers = delegations?.accountManagers;
    for (let i = 0; i < accountManagers?.length; i++) {
      const am = accountManagers[i];
      if (manPowerForm?.find((obj) => obj?.location === am?.locationId)) {
        arr.push({
          delegationID: am?.delegationID,
          employeeId: am?.employeeId,
          cityCode: am?.locationId,
          isPIC: am?.isPIC === false ? "NO" : "YES",
        });
      }
    }

    const admins = delegations?.admins;
    for (let i = 0; i < admins?.length; i++) {
      const admin = admins[i];
      if (manPowerForm?.find((obj) => obj?.location === admin?.locationId)) {
        arr.push({
          delegationID: admin?.delegationID,
          employeeId: admin?.employeeId,
          cityCode: admin?.locationId,
          isPIC: admin?.isPIC === false ? "NO" : "YES",
        });
      }
    }

    const recruiters = delegations?.recruiters;
    for (let i = 0; i < recruiters?.length; i++) {
      const ro = recruiters[i];
      if (manPowerForm?.find((obj) => obj?.location === ro?.locationId)) {
        arr.push({
          delegationID: ro?.delegationID,
          employeeId: ro?.employeeId,
          cityCode: ro?.locationId,
          isPIC: ro?.isPIC === false ? "NO" : "YES",
        });
      }
    }

    return arr;
    // eslint-disable-next-line
  }, [delegations, manPowerForm, trigger]);

  const form = useMemo(() => {
    return {
      contractType: contractForm?.contract,
      clientCode: clientCode,
      setManFee: manfee,
      setTOP: TOP,
      remark: remark,
      workSchemes: workSchemeForm,
      additional: additionalForm,
      manPowers: manPowerForm,
      files: files,
      delegations: fixedDelegations,
    };
  }, [
    contractForm,
    workSchemeForm,
    clientCode,
    additionalForm,
    manPowerForm,
    files,
    TOP,
    manfee,
    remark,
    fixedDelegations,
  ]);

  // OPTIONSFETCHER AND ISDONECHECKER

  const optionsFetcher = useMemo(() => {
    return {
      first: () => {
        let arr = [];
        const options = syncData?.options;
        for (let i = 0; i < options?.length; i++) {
          const option = options[i];
          if (
            option?.type === "contractTypeClient" &&
            option?.value === "Jasa Pemborongan"
          ) {
            arr.push({
              type: "PEMBORONGAN",
              title: option?.value,
              label: "PPN Dari Total Biaya",
              rules: [
                {
                  id: 11,
                  desc: "Status Karyawan PKWT atau Mitra",
                },
                {
                  id: 12,
                  desc: "PPh 21 dihitung dari penghasilan per bulan",
                },
                {
                  id: 13,
                  desc: "BPJS Kesehatan dihitung dari bantuan operasional (minimum UMP)",
                },
                {
                  id: 14,
                  desc: "BPJS Ketenagakerjaan JKK, JKM, JHT, atau JKK & JKM Dihitung dari bantuan operasional",
                },
                {
                  id: 15,
                  desc: "Manajemen Fee dihitung dari selisih nilai kontrak dengan biaya yang dikeluarkan",
                },
              ],
            });
          } else if (
            option?.type === "contractTypeClient" &&
            option?.value === "Penyedia Jasa Tenaga Kerja"
          ) {
            arr.push({
              type: "PJTK",
              title: option?.value,
              label: "PPN dari Manfee & PPN Fasilitas dari Total",
              rules: [
                {
                  id: 21,
                  desc: "Status Karyawan PKWT",
                },
                {
                  id: 22,
                  desc: "PPH 21 dihitung dari penghasilan yang disetahunkan",
                },
                {
                  id: 23,
                  desc: "BPJS Kesehatan dihitung dari Gapok + Tunjangan tetap (minimum UMP)",
                },
                {
                  id: 24,
                  desc: "BPJS Ketenagakerjaan JKK, JKM, JHT, JP Dihitung dari Gapok + Tunjangan tetap",
                },
                {
                  id: 25,
                  desc: "Manajemen Fee dihitung dari prosentase atau nominal yang disepakati",
                },
              ],
            });
          }
        }
        return arr;
      },
      second: () => {
        const arr = [];

        const taxs = [];
        const pph21 = [];
        const pph23 = [];
        const ppn = [];

        const bpjs = [];
        const registration = [];
        const paymentProof = [];
        const jkk = [];
        const jkm = [];
        const jht = [];
        const health = [];
        const akdhk = [];
        const jp = [];
        const cutOffBPJSTKO = [];

        const others = [];
        const manfee = [];
        const uak = [];
        const thr = [];
        const tkoBank = [];

        for (let i = 0; i < syncData?.options?.length; i++) {
          const option = syncData?.options[i];
          if (option?.type === "PPH21") {
            pph21.push({
              id: option.id,
              name: option?.value,
            });
          } else if (option?.type === "PPH23") {
            pph23.push({
              id: option.id,
              name: option?.value,
            });
          } else if (option?.type === "PPN") {
            ppn.push({
              id: option.id,
              name: option?.value,
            });
          } else if (option?.type === "Pendaftaran BPJS") {
            registration.push({
              id: option.id,
              name: option?.value,
            });
          } else if (option?.type === "Bukti Pendaftaran BPJS") {
            paymentProof.push({
              id: option.id,
              name: option?.value,
            });
          } else if (option?.type === "JKK") {
            jkk.push({
              id: option.id,
              name: option?.value,
            });
          } else if (option?.type === "JKM") {
            jkm.push({
              id: option.id,
              name: option?.value,
            });
          } else if (option?.type === "JHT") {
            jht.push({
              id: option.id,
              name: option?.value,
            });
          } else if (option?.type === "JP") {
            jp.push({
              id: option.id,
              name: option?.value,
            });
          } else if (option?.type === "KS") {
            health.push({
              id: option.id,
              name: option?.value,
            });
          } else if (option?.type === "AKDHK") {
            akdhk.push({
              id: option.id,
              name: option?.value,
            });
          } else if (option?.type === "Pengenaan Manfee") {
            manfee.push({
              id: option.id,
              name: option?.value,
            });
          } else if (option?.type === "Penagihan UAK") {
            uak.push({
              id: option.id,
              name: option?.value,
            });
          } else if (option?.type === "Penagihan THR") {
            thr.push({
              id: option.id,
              name: option?.value,
            });
          } else if (option?.type === "AKDHK") {
            akdhk.push({
              id: option.id,
              name: option?.value,
            });
          } else if (option?.type === "Potongan BPJS TKO") {
            cutOffBPJSTKO.push({
              id: option.id,
              name: option?.value,
            });
          }
        }

        for (let i = 0; i < syncData?.banks.length; i++) {
          tkoBank.push({
            id: i,
            name: syncData?.banks[i],
          });
        }

        taxs.push({
          pph21: pph21,
          pph23: pph23,
          ppn: ppn,
        });
        bpjs.push({
          registration: registration,
          paymentProof: paymentProof,
          jkk: jkk,
          jkm: jkm,
          jht: jht,
          health: health,
          akdhk: akdhk,
          jp: jp,
          cutOffBPJSTKO: cutOffBPJSTKO,
        });
        others.push({
          manfee: manfee,
          uak: uak,
          thr: thr,
          tkoBank: tkoBank,
        });

        arr.push({
          taxs: taxs[0],
          bpjs: bpjs[0],
          others: others[0],
        });

        return arr[0];
      },
      fourth: () => {
        const arr = [];

        const contractTypeWorker = [];
        const personalWorkEquipment = [];
        const allowance = [];
        const areaWorkEquipment = [];

        const qualifications = [];

        for (let i = 0; i < syncData?.options.length; i++) {
          const option = syncData?.options[i];

          if (option?.type === "contractTypeWorker") {
            contractTypeWorker.push({
              id: option.id,
              name: option?.value,
            });
          } else if (option?.type === "Personal Work Equipment") {
            personalWorkEquipment.push({
              name: option?.value,
              value: "",
            });
          } else if (option?.type === "Allowance") {
            allowance.push({
              name: option?.value,
              value: "",
            });
          } else if (option?.type === "Area Work Equipment") {
            areaWorkEquipment.push({
              name: option?.value,
              value: "",
            });
          }
        }

        for (let i = 0; i < syncData?.qualifications.length; i++) {
          const qualification = syncData?.qualifications[i];

          qualifications.push({
            name: qualification?.value,
            denom: qualification.denom,
            value: null,
          });
        }

        arr.push({
          contractTypeWorker: contractTypeWorker,
          personalWorkEquipment: personalWorkEquipment,
          allowance: allowance,
          areaWorkEquipment: areaWorkEquipment,
          qualifications: qualifications,
        });

        return arr[0];
      },
    };
  }, [syncData]);

  const isDoneChecker = useMemo(() => {
    return {
      first: () => {
        if (contractForm?.contract !== null) {
          return true;
        }
        return false;
      },
      second: () => {
        if (workSchemeForm?.taxs?.pph21 === null) {
          return false;
        } else if (workSchemeForm?.taxs?.pph23 === null) {
          return false;
        } else if (workSchemeForm?.taxs?.ppn === null) {
          return false;
        } else if (workSchemeForm?.others?.manfee?.length === 0) {
          return false;
        } else if (workSchemeForm?.others?.thr === null) {
          return false;
        } else if (workSchemeForm?.others?.uak === null) {
          return false;
        } else if (workSchemeForm?.others?.tkoBank?.length === 0) {
          return false;
        } else {
          return true;
        }
      },
      third: () => {
        if (
          //   !additionalForm?.cooperationPeriod?.documentNumber) {
          //   return false;
          // } else if (!additionalForm?.cooperationPeriod?.startDate) {
          //   return false;
          // } else if (!additionalForm?.cooperationPeriod?.endDate) {
          //   return false;
          // } else
          !additionalForm?.invoiceFlow?.dateOfReceiptOfData
        ) {
          return false;
        } else if (!additionalForm?.invoiceFlow?.UP) {
          return false;
        } else if (!additionalForm?.invoiceFlow?.dateOfFillingOfSoftCopy) {
          return false;
        } else if (!additionalForm?.invoiceFlow?.dateOfFillingOfHardCopy) {
          return false;
        } else if (!additionalForm?.invoiceFlow?.payrollDate) {
          return false;
        } else if (!additionalForm?.invoiceFlow?.cutOffAttendance) {
          return false;
        } else if (!additionalForm?.recipientsAccount?.bankNumber) {
          return false;
        } else {
          return true;
        }
      },
      fourth: () => {
        return true;
      },
      sixth: () => {
        if (fixedDelegations?.length > 0) {
          const cityCodeArr = fixedDelegations?.map((each) => each.cityCode);
          const locationArr = manPowerForm?.map((each) => each.location);
          return locationArr.every((element) => {
            return cityCodeArr.indexOf(element) !== -1;
          });
        }
        return false;
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    trigger,
    workSchemeForm,
    additionalForm,
    contractForm,
    manPowerFormArr,
    fixedDelegations,
  ]);

  // GENERAL
  const fixedWorkSchemeManFee = useMemo(() => {
    let arr = [];

    const manfeeArr = workSchemeForm?.others?.manfee;
    for (let i = 0; i < manfeeArr?.length; i++) {
      const manfee = manfeeArr[i];
      arr.push(manfee?.name);
    }
    return arr.join("::");
  }, [workSchemeForm?.others?.manfee]);

  const fixedTkoBank = useMemo(() => {
    let arr = [];

    const banksArr = workSchemeForm?.others?.tkoBank;
    for (let i = 0; i < banksArr?.length; i++) {
      const bank = banksArr[i];
      arr.push(bank);
    }
    return arr.join("::");
  }, [workSchemeForm?.others?.tkoBank]);

  const fixedBpjsCut = useMemo(() => {
    return workSchemeForm?.bpjs?.cutOffBPJSTKO;
  }, [workSchemeForm?.bpjs?.cutOffBPJSTKO]);

  const renewTrigger = useCallback(() => {
    setTrigger(Math.random());
  }, []);

  const debounceTrigger = useMemo(
    () => debounce(renewTrigger, 500),
    [renewTrigger]
  );

  const renewDefault = useCallback(() => {
    if (!isLoadingFirst) {
      sessionStorage.setItem("contractFormEdit", JSON.stringify(contractForm));
      const contractArr = [...contracts];

      for (let i = 0; i < contractArr.length; i++) {
        const file = contractArr[i]["file"];
        if (file && typeof file === "object") {
          fileToBase64(file, async (base64) => {
            contractArr[i]["str"] = base64;
          });
        }
      }
      sessionStorage.setItem(
        "contractsEdit",
        JSON.stringify(
          contractArr?.map((obj) => {
            return {
              ...obj,

              type: typeof obj?.file,
            };
          })
        )
      );
    }

    if (!isLoadingSecond) {
      sessionStorage.setItem(
        "workSchemeFormEdit",
        JSON.stringify(workSchemeForm)
      );
      sessionStorage.setItem("withBPJSEdit", withBPJS ? "YES" : "NO");
      sessionStorage.setItem("selectedValueBPUEdit", selectedValueBPU);
    }

    if (!isLoadingThird) {
      sessionStorage.setItem(
        "additionalFormEdit",
        JSON.stringify(additionalForm)
      );
    }

    if (!isLoadingFourth) {
      sessionStorage.setItem(
        "manPowerFormAddEdit",
        JSON.stringify(manPowerForm)
      );
      sessionStorage.setItem(
        "manPowerFormArrEdit",
        JSON.stringify(manPowerFormArr)
      );
      sessionStorage.setItem(
        "delegationsFormEdit",
        JSON.stringify(delegationsForm)
      );

      sessionStorage.setItem("remarkEdit", JSON.stringify(remark));
      sessionStorage.setItem("delegationsEdit", JSON.stringify(delegations));
    }

    if (!isLoadingFifthNew) {
      sessionStorage.setItem(
        "positionsFormEdit",
        JSON.stringify(positionsForm)
      );
    }

    if (!isLoadingFifth) {
      sessionStorage.setItem("TOPEdit", JSON.stringify(TOP));
      sessionStorage.setItem("manfeeEdit", JSON.stringify(manfee));
    }

    sessionStorage.setItem("stepsEdit", JSON.stringify(steps));
    sessionStorage.setItem(
      "selectedButtonBPJSEdit",
      JSON.stringify(selectedButtonBPJS)
    );

    sessionStorage.setItem("isLoadingFirst", isLoadingFirst ? "YES" : "NO");
    sessionStorage.setItem("isLoadingSecond", isLoadingSecond ? "YES" : "NO");
    sessionStorage.setItem("isLoadingThird", isLoadingThird ? "YES" : "NO");
    sessionStorage.setItem("isLoadingFourth", isLoadingFourth ? "YES" : "NO");
    sessionStorage.setItem("isLoadingFifth", isLoadingFifth ? "YES" : "NO");

    sessionStorage.setItem("defaultFirst", JSON.stringify(defaultFirst));
    sessionStorage.setItem("defaultSecond", JSON.stringify(defaultSecond));
    sessionStorage.setItem("defaultThird", JSON.stringify(defaultThird));
    sessionStorage.setItem("defaultFourth", JSON.stringify(defaultFourth));
    sessionStorage.setItem("defaultFifthNew", JSON.stringify(defaultFifthNew));
    sessionStorage.setItem("defaultFifth", JSON.stringify(defaultFifth));

    // sessionStorage.setItem('orderFiles', JSON.stringify(fixedFiles))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contractForm,
    workSchemeForm,
    additionalForm,
    manPowerForm,
    manPowerFormArr,
    delegationsForm,
    positionsForm,
    remark,
    TOP,
    manfee,
    delegations,
    steps,
    trigger,
    selectedButtonBPJS,
    selectedValueBPU,
    contracts,
    withBPJS,
    isLoadingFirst,
    isLoadingSecond,
    isLoadingThird,
    isLoadingFourth,
    isLoadingFifth,
    defaultFirst,
    defaultSecond,
    defaultThird,
    defaultFourth,
    defaultFifth,
  ]);

  const debounceDefault = useMemo(
    () => debounce(renewDefault, 500),
    [renewDefault]
  );

  useEffect(() => {
    debounceDefault();
  }, [debounceDefault, trigger]);

  const onPrevious = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep, setCurrentStep]);

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "start",
    });
  };

  const onNext = useCallback(
    (isDone) => {
      const newSteps = [...steps];
      if (isDone) {
        newSteps[currentStep - 1].isDone = true;
        setSteps(newSteps);
        setCurrentStep(currentStep + 1);
        scrollingTop();
      }
    },
    [currentStep, setCurrentStep, setSteps, steps]
  );

  // const disableSubmit = useMemo(() => {
  //   if (
  //     !isDoneChecker?.first() ||
  //     !isDoneChecker?.second() ||
  //     !isDoneChecker?.third() ||
  //     !isDoneChecker?.fourth() ||
  //     !manfee ||
  //     !TOP ||
  //     !isDoneChecker?.sixth()
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }, [isDoneChecker, manfee, TOP]);

  // const deletedDelegations = useMemo(() => {
  //   const arr = [];
  //   for (let i = 0; i < fixedDefaultDelegations?.length; i++) {
  //     const delegation = fixedDefaultDelegations[i];
  //     const found = fixedDelegations?.find(
  //       (el) => el?.delegationID === delegation?.delegationID
  //     );
  //     if (!found) {
  //       arr?.push(delegation);
  //     }
  //   }
  //   return arr;
  // }, [fixedDefaultDelegations, fixedDelegations]);

  const deletedFileIDs = useMemo(() => {
    const arr = [];
    for (let i = 0; i < defaultFiles?.length; i++) {
      const found = files?.find((file) => file?.id === defaultFiles[i]?.id);
      if (!found) {
        arr.push(defaultFiles[i]?.id);
      }
    }

    return arr;
  }, [defaultFiles, files]);

  const mappedDelegationsForm = useMemo(() => {
    return delegationsForm
      ?.map((obj) => ({
        code: obj?.cityCode,
        delegations: obj?.deployments
          ?.map((d) =>
            d?.delegations?.map((u) => ({
              ...u,
              position: d?.role,
            }))
          )
          ?.flat()
          ?.filter((u) => u?.isPicked),
        hasDelegationID: obj?.deployments?.some((d) =>
          d?.delegations?.some((u) => u?.delegationID)
        ),
      }))
      .sort((a, b) =>
        a.hasDelegationID === b.hasDelegationID ? 0 : a.hasDelegationID ? -1 : 1
      );
  }, [delegationsForm]);

  const mappedDeletedDelegationsForm = useMemo(() => {
    return delegationsForm?.map((obj) => {
      return {
        code: obj?.cityCode,
        delegations: obj?.deployments
          ?.map((d) =>
            d?.delegations?.map((u) => {
              return {
                ...u,
                position: d?.role,
              };
            })
          )
          ?.flat()
          ?.filter((u) => !u?.isPicked)
          ?.filter((u) => u?.delegationID),
      };
    });
  }, [delegationsForm]);

  const handleRenewPositionsForm = useCallback(() => {
    const newPositionsForm = positionsForm.slice(0);

    for (let i = 0; i < newPositionsForm?.length; i++) {
      const perPosition = newPositionsForm[i];
      const newCitiesData = delegationsForm?.map((d) => {
        const found = positionsForm
          ?.find(
            (obj) =>
              obj?.positionName?.toUpperCase() ===
              perPosition?.positionName?.toUpperCase()
          )
          ?.citiesData?.find((obj) => obj?.cityCode === d?.cityCode);

        const salaryJobs = found?.salaryValue || d?.umk || 4500000;

        const { jkk, jkm, jht } = BPURangeSalary(
          salaryJobs,
          found?.salaryDenom || "Bulanan"
        );
        return {
          id: found?.id || makeRandomString(5),
          isApplied: found?.isApplied || false,
          preventDelete: found?.preventDelete || false,
          preventEdit: found?.preventEdit || false,
          positionID: found?.positionID || null,
          cityCode: found?.cityCode || d?.cityCode,
          cityName: found?.cityName || d?.cityName,
          umk: found?.umk || d?.umk,
          amount: found?.amount || 1,
          salaryValue: salaryJobs,
          salaryDenom: found?.salaryDenom || "Bulanan",
          personalEquipments: found?.personalEquipments || [],
          allowances: found?.allowances || [],
          jkkID: found?.jkkID || "",
          jkk: found?.jkk
            ? found?.jkk
            : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
            ? salaryJobs
              ? `${+jkk / 2}`
              : "0"
            : salaryJobs
            ? jkk
            : "0",

          jkmID: found?.jkmID || "",
          jkm: found?.jkm
            ? found?.jkm
            : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
            ? salaryJobs
              ? `${+jkm / 2}`
              : "0"
            : salaryJobs
            ? jkm
            : "0",

          jhtID: found?.jhtID || "",
          jht: found?.jht
            ? found?.jht
            : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
            ? salaryJobs
              ? `${+jht / 2}`
              : "0"
            : salaryJobs
            ? jht
            : "0",
          isJkkManual: found?.isJkkManual ? found?.isJkkManual : false,
          isJhtManual: found?.isJhtManual ? found?.isJhtManual : false,
          isJkmManual: found?.isJkmManual ? found?.isJkmManual : false,
          jkkID1: found?.jkkID1 || "",
          jkk1: found?.jkk1
            ? found?.jkk1
            : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
            ? salaryJobs
              ? `${+jkk / 2}`
              : "0"
            : "0",

          jkmID1: found?.jkmID1 || "",
          jkm1: found?.jkm1
            ? found?.jkm1
            : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
            ? salaryJobs
              ? `${+jkm / 2}`
              : "0"
            : "0",
          jhtID1: found?.jhtID1 || "",
          jht1: found?.jht1
            ? found?.jht1
            : selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
            ? salaryJobs
              ? `${+jht / 2}`
              : "0"
            : "0",

          isJkk1Manual: found?.isJkk1Manual ? found?.isJkk1Manual : false,
          isJht1Manual: found?.isJht1Manual ? found?.isJht1Manual : false,
          isJkm1Manual: found?.isJkm1Manual ? found?.isJkm1Manual : false,
          expectedDate: found?.expectedDate || "",
        };
      });
      perPosition.citiesData = newCitiesData;
    }
    // setPositionsForm(newPositionsForm)
  }, [delegationsForm, positionsForm, selectedValueBPU]);

  useEffect(() => {
    if (
      !isLoadingFifthNew &&
      positionsForm?.length &&
      delegationsForm?.length &&
      positionsForm[0]?.citiesData?.length !== delegationsForm?.length
    ) {
      handleRenewPositionsForm();
    }
  }, [
    delegationsForm?.length,
    handleRenewPositionsForm,
    isLoadingFifthNew,
    positionsForm,
    positionsForm?.length,
  ]);

  const handleSubmit = useCallback(
    async (e, type) => {
      if (type === "send") {
        setSubmittingType("send");
        setSubmitting(true);
      } else {
        setSubmittingType("draft");
        setSubmittingDraft(true);
      }
      try {
        e.preventDefault();

        const formData = new FormData();

        formData.append("contractType", form?.contractType);
        formData.append("employeementType", form?.workSchemes?.tkoContract);
        formData.append("clientCode", form?.clientCode);
        formData.append("setManFee", form?.setManFee);
        formData.append("setTOP", form?.setTOP);
        formData.append("remark", form?.remark);
        if (!defaultSecond?.workScheme || defaultSecond?.workScheme === null) {
          formData.append("workScheme[orderComprehensiveID]", "");
        } else {
          formData.append(
            "workScheme[orderComprehensiveID]",
            defaultSecond?.workScheme?.id || ""
          );
        }
        formData.append(
          "workScheme[taxs][pph21]",
          form?.workSchemes?.taxs?.pph21
        );
        formData.append(
          "workScheme[taxs][pph23]",
          form?.workSchemes?.taxs?.pph23
        );
        formData.append("workScheme[taxs][ppn]", form?.workSchemes?.taxs?.ppn);
        formData.append(
          "workScheme[bpjs][registration]",
          withBPJS ? form?.workSchemes?.bpjs?.registration : null
        );
        formData.append(
          "workScheme[bpjs][paymentProof]",
          withBPJS ? form?.workSchemes?.bpjs?.paymentProof : null
        );
        formData.append(
          "workScheme[bpjs][bpuType]",
          withBPJS ? selectedValueBPU : null
        );
        formData.append(
          "workScheme[bpjs][jkk]",
          withBPJS ? form?.workSchemes?.bpjs?.jkk : null
        );
        formData.append(
          "workScheme[bpjs][jkm]",
          withBPJS ? form?.workSchemes?.bpjs?.jkm : null
        );
        formData.append(
          "workScheme[bpjs][jht]",
          withBPJS ? form?.workSchemes?.bpjs?.jht : null
        );
        formData.append(
          "workScheme[bpjs][akhdk]",
          withBPJS ? form?.workSchemes?.bpjs?.akhdk : null
        );
        formData.append(
          "workScheme[bpjs][jp]",
          withBPJS ? form?.workSchemes?.bpjs?.jp : null
        );
        formData.append(
          "workScheme[bpjs][health]",
          withBPJS ? form?.workSchemes?.bpjs?.health : null
        );
        formData.append(
          "workScheme[bpjs][cutOffBPJSTKO]",
          withBPJS
            ? form?.workSchemes?.bpjs?.cutOffBPJSTKO === "Manual"
              ? form?.workSchemes?.bpjs?.cutOffNominal
              : form?.workSchemes?.bpjs?.cutOffBPJSTKO
            : null
        );
        formData.append("workScheme[others][manfee]", fixedWorkSchemeManFee);
        formData.append(
          "workScheme[others][thr]",
          form?.workSchemes?.others?.thr
        );
        formData.append(
          "workScheme[others][uak]",
          form?.workSchemes?.others?.uak
        );
        formData.append("workScheme[others][tkoBank]", fixedTkoBank);

        formData.append(
          "additional[recipientsAccount][bankName]",
          form?.additional?.recipientsAccount?.bankName
        );
        formData.append(
          "additional[recipientsAccount][bankNumber]",
          form?.additional?.recipientsAccount?.bankNumber
        );
        formData.append(
          "additional[recipientsAccount][bankRecipient]",
          form?.additional?.recipientsAccount?.bankRecipient
        );

        for (let i = 0; i < form?.additional?.contacts.length; i++) {
          formData?.append(
            `additional[contactInformation][${i}][contactID]`,
            form?.additional?.contacts[i]?.id
          );
        }

        if (
          !defaultThird?.additional?.flowInvoice ||
          defaultThird?.additional?.flowInvoice === null
        ) {
          formData.append("additional[invoiceFlow][OrderInvoiceFlowID]", "");
        } else {
          formData.append(
            "additional[invoiceFlow][OrderInvoiceFlowID]",
            defaultThird?.additional?.flowInvoice?.id || ""
          );
        }
        formData.append(
          "additional[invoiceFlow][dateOfReceiptOfData]",
          form?.additional?.invoiceFlow?.dateOfReceiptOfData
        );
        formData.append(
          "additional[invoiceFlow][UP]",
          form?.additional?.invoiceFlow?.UP
        );
        formData.append(
          "additional[invoiceFlow][dateOfFillingOfSoftCopy]",
          form?.additional?.invoiceFlow?.dateOfFillingOfSoftCopy
        );
        formData.append(
          "additional[invoiceFlow][dateOfFillingOfHardCopy]",
          form?.additional?.invoiceFlow?.dateOfFillingOfHardCopy
        );
        formData.append(
          "additional[invoiceFlow][payrollDate]",
          form?.additional?.invoiceFlow?.payrollDate
        );
        formData.append(
          "additional[invoiceFlow][cutOffAttendance]",
          form?.additional?.invoiceFlow?.cutOffAttendance
        );
        formData.append(
          "additional[invoiceFlow][supportingDocument]",
          form?.additional?.invoiceFlow?.supportingDocument || ""
        );
        // formData.append(
        //   "additional[cooperationPeriod][contractID]",
        //   defaultOrderForm?.additional?.periodeKerjasama[0]?.id || ""
        // );
        // formData.append(
        //   "additional[cooperationPeriod][startDate]",
        //   form?.additional?.cooperationPeriod?.startDate
        //     ? formatDate(form?.additional?.cooperationPeriod?.startDate)
        //     : null
        // );
        // formData.append(
        //   "additional[cooperationPeriod][endDate]",
        //   form?.additional?.cooperationPeriod?.endDate
        //     ? formatDate(form?.additional?.cooperationPeriod?.endDate)
        //     : null
        // );
        // formData.append(
        //   "additional[cooperationPeriod][documentNumber]",
        //   form?.additional?.cooperationPeriod?.documentNumber
        // );
        // formData.append(
        //   "additional[cooperationPeriod][docType]",
        //   form?.additional?.cooperationPeriod?.documentType
        // );
        formData.append(
          "additional[others][deductedAttendance]",
          form?.additional?.others?.deductedAttendance || ""
        );
        formData.append(
          "additional[others][others]",
          form?.additional?.others?.others || ""
        );

        for (let i = 0; i < mappedDelegationsForm.length; i++) {
          const dataPerCity = mappedDelegationsForm[i];
          formData?.append(`orderLocations[${i}][code]`, dataPerCity?.code);

          for (let j = 0; j < dataPerCity?.delegations?.length; j++) {
            const perDelegation = dataPerCity?.delegations[j];

            formData?.append(
              `orderLocations[${i}][delegations][${j}][delegationID]`,
              perDelegation?.delegationID || ""
            );
            formData?.append(
              `orderLocations[${i}][delegations][${j}][position]`,
              perDelegation?.position
            );
            formData?.append(
              `orderLocations[${i}][delegations][${j}][employeeId]`,
              perDelegation?.id
            );
            formData?.append(
              `orderLocations[${i}][delegations][${j}][isPIC]`,
              perDelegation?.isPIC ? "YES" : "NO"
            );
            formData?.append(
              `orderLocations[${i}][delegations][${j}][action]`,
              perDelegation?.delegationID ? "UPDATE" : ""
            );
          }
          if (mappedDeletedDelegationsForm[i]?.delegations?.length) {
            for (
              let j = 0;
              j < mappedDeletedDelegationsForm[i]?.delegations?.length;
              j++
            ) {
              const perDelegation =
                mappedDeletedDelegationsForm[i]?.delegations[j];

              formData?.append(
                `orderLocations[${i}][delegations][${
                  j + dataPerCity?.delegations?.length
                }][delegationID]`,
                perDelegation?.delegationID || ""
              );
              formData?.append(
                `orderLocations[${i}][delegations][${
                  j + dataPerCity?.delegations?.length
                }][position]`,
                perDelegation?.position
              );
              formData?.append(
                `orderLocations[${i}][delegations][${
                  j + dataPerCity?.delegations?.length
                }][employeeId]`,
                perDelegation?.id
              );
              formData?.append(
                `orderLocations[${i}][delegations][${
                  j + dataPerCity?.delegations?.length
                }][isPIC]`,
                perDelegation?.isPIC ? "YES" : "NO"
              );
              formData?.append(
                `orderLocations[${i}][delegations][${
                  j + dataPerCity?.delegations?.length
                }][action]`,
                perDelegation?.delegationID ? "DELETE" : ""
              );
            }
          }
        }

        for (let i = 0; i < positionsForm?.length; i++) {
          const perPositionData = positionsForm[i];

          formData?.append(`jobs[${i}][title]`, perPositionData?.positionName);
          formData?.append(
            `jobs[${i}][dedicatedRO]`,
            perPositionData?.dedicatedROId
          );

          for (let j = 0; j < perPositionData?.qualifications?.length; j++) {
            const perQualification = perPositionData?.qualifications[j];

            formData?.append(
              `jobs[${i}][qualifications][${j}][name]`,
              perQualification?.name
            );
            formData?.append(
              `jobs[${i}][qualifications][${j}][value]`,
              perQualification?.value
            );
            formData?.append(
              `jobs[${i}][qualifications][${j}][action]`,
              perQualification?.qualificationID ? "UPDATE" : ""
            );
          }

          const deletedQualificationsArr =
            defaultPositionsForm
              ?.find(
                (obj) => obj?.positionName === perPositionData?.positionName
              )
              ?.qualifications?.filter((obj) =>
                perPositionData?.qualifications?.every(
                  (f) => f?.qualificationID !== obj?.qualificationID
                )
              ) || [];

          for (let j = 0; j < deletedQualificationsArr?.length; j++) {
            const perQualification = deletedQualificationsArr[j];

            formData?.append(
              `jobs[${i}][qualifications][${
                j + perPositionData?.qualifications?.length
              }][name]`,
              perQualification?.name
            );
            formData?.append(
              `jobs[${i}][qualifications][${
                j + perPositionData?.qualifications?.length
              }][value]`,
              perQualification?.value
            );
            formData?.append(
              `jobs[${i}][qualifications][${
                j + perPositionData?.qualifications?.length
              }][action]`,
              perQualification?.qualificationID ? "DELETE" : ""
            );
          }

          const appliedCitiesData = perPositionData?.citiesData
            ?.filter((obj) => obj?.isApplied)
            ?.sort((a, b) => {
              if (!a.positionID) return 1; // Pindahkan objek tanpa positionID ke urutan terakhir
              if (!b.positionID) return -1; // Pindahkan objek dengan positionID di depan
              return 0; // Pertahankan urutan lainnya
            });

          for (let j = 0; j < appliedCitiesData?.length; j++) {
            const perCityData = appliedCitiesData[j];

            formData?.append(
              `jobs[${i}][locations][${j}][positionID]`,
              perCityData?.positionID || ""
            );
            formData?.append(
              `jobs[${i}][locations][${j}][code]`,
              perCityData?.cityCode
            );
            formData?.append(
              `jobs[${i}][locations][${j}][salary]`,
              `${perCityData?.salaryValue}::${perCityData?.salaryDenom}`
            );
            formData?.append(
              `jobs[${i}][locations][${j}][fulfilmentDate]`,
              moment(perCityData?.expectedDate)?.format("YYYY-MM-DD")
            );
            formData?.append(
              `jobs[${i}][locations][${j}][demand]`,
              perCityData?.amount
            );

            for (let k = 0; k < perCityData?.personalEquipments?.length; k++) {
              const perEquipment = perCityData?.personalEquipments[k];
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${k}][equipmentID]`,
                perEquipment?.equipmentID || ""
              );
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${k}][name]`,
                perEquipment?.name
              );
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${k}][price]`,
                perEquipment?.value
              );
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${k}][action]`,
                perEquipment?.equipmentID ? "UPDATE" : ""
              );
            }

            const deletedPersonalEquipmentsArr =
              defaultPositionsForm
                ?.find(
                  (obj) => obj?.positionName === perPositionData?.positionName
                )
                ?.citiesData?.find(
                  (obj) => obj?.positionID === perCityData?.positionID
                )
                ?.personalEquipments?.filter((obj) =>
                  perCityData?.personalEquipments?.every(
                    (f) => f?.equipmentID !== obj?.equipmentID
                  )
                ) || [];

            // console.log(deletedPersonalEquipmentsArr, " ini test");

            for (let k = 0; k < deletedPersonalEquipmentsArr?.length; k++) {
              const perEquipment = deletedPersonalEquipmentsArr[k];
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${
                  k + perCityData?.personalEquipments?.length
                }][equipmentID]`,
                perEquipment?.equipmentID || ""
              );
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${
                  k + perCityData?.personalEquipments?.length
                }][name]`,
                perEquipment?.name
              );
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${
                  k + perCityData?.personalEquipments?.length
                }][price]`,
                perEquipment?.value
              );
              formData?.append(
                `jobs[${i}][locations][${j}][personalEquipments][${
                  k + perCityData?.personalEquipments?.length
                }][action]`,
                perEquipment?.equipmentID ? "DELETE" : ""
              );
            }

            for (let k = 0; k < perCityData?.allowances?.length; k++) {
              const perAllowance = perCityData?.allowances[k];
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${k}][allowanceID]`,
                perAllowance?.allowanceID || ""
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${k}][name]`,
                perAllowance?.name
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${k}][value]`,
                perAllowance?.value
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${k}][denom]`,
                perAllowance?.denom || "Bulanan"
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${k}][action]`,
                perAllowance?.allowanceID ? "UPDATE" : ""
              );
            }

            const deletedAllowancesArr =
              defaultPositionsForm
                ?.find(
                  (obj) => obj?.positionName === perPositionData?.positionName
                )
                ?.citiesData?.find(
                  (obj) => obj?.positionID === perCityData?.positionID
                )
                ?.allowances?.filter((obj) =>
                  perCityData?.allowances?.every(
                    (f) => f?.allowanceID !== obj?.allowanceID
                  )
                ) || [];

            for (let k = 0; k < deletedAllowancesArr?.length; k++) {
              const perAllowance = deletedAllowancesArr[k];
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  k + perCityData?.allowances?.length
                }][allowanceID]`,
                perAllowance?.allowanceID || ""
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  k + perCityData?.allowances?.length
                }][name]`,
                perAllowance?.name
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  k + perCityData?.allowances?.length
                }][value]`,
                perAllowance?.value
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  k + perCityData?.allowances?.length
                }][denom]`,
                perAllowance?.denom
              );
              formData?.append(
                `jobs[${i}][locations][${j}][allowances][${
                  k + perCityData?.allowances?.length
                }][action]`,
                perAllowance?.allowanceID ? "DELETE" : ""
              );
            }

            if (selectedButtonBPJS === "BPU") {
              if (
                selectedValueBPU === "Tagih Ke Perusahaan" ||
                selectedValueBPU === "Tagih Ke Karyawan"
              ) {
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][allowanceID]`,
                  perCityData?.jkkID || ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][name]`,
                  "JKK"
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][value]`,
                  perCityData?.jkk || 0
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][action]`,
                  perCityData?.jkkID ? "UPDATE" : ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][type]`,
                  "BPU"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][billingTo]`,
                  selectedValueBPU === "Tagih Ke Karyawan"
                    ? "Karyawan"
                    : "Perusahaan"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][allowanceID]`,
                  perCityData?.jkmID || ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][name]`,
                  "JKM"
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][value]`,
                  perCityData?.jkm || 0
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][action]`,
                  perCityData?.jkmID ? "UPDATE" : ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][type]`,
                  "BPU"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][billingTo]`,
                  selectedValueBPU === "Tagih Ke Karyawan"
                    ? "Karyawan"
                    : "Perusahaan"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][allowanceID]`,
                  perCityData?.jhtID || ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][name]`,
                  "JHT"
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][value]`,
                  perCityData?.jht || 0
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][action]`,
                  perCityData?.jhtID ? "UPDATE" : ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][type]`,
                  "BPU"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][billingTo]`,
                  selectedValueBPU === "Tagih Ke Karyawan"
                    ? "Karyawan"
                    : "Perusahaan"
                );
              } else if (
                selectedValueBPU === "Tagih Ke Perusahaan & Karyawan"
              ) {
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][allowanceID]`,
                  perCityData?.jkkID || ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][name]`,
                  "JKK"
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][value]`,
                  perCityData?.jkk || 0
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][action]`,
                  perCityData?.jkkID ? "UPDATE" : ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][type]`,
                  "BPU"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length
                  }][billingTo]`,
                  "Perusahaan"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][allowanceID]`,
                  perCityData?.jkmID || ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][name]`,
                  "JKM"
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][value]`,
                  perCityData?.jkm || 0
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][action]`,
                  perCityData?.jkmID ? "UPDATE" : ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][type]`,
                  "BPU"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    1
                  }][billingTo]`,
                  "Perusahaan"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][allowanceID]`,
                  perCityData?.jhtID || ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][name]`,
                  "JHT"
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][value]`,
                  perCityData?.jht || 0
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][action]`,
                  perCityData?.jhtID ? "UPDATE" : ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][type]`,
                  "BPU"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    2
                  }][billingTo]`,
                  "Perusahaan"
                );

                // next

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    3
                  }][allowanceID]`,
                  perCityData?.jkkID1 || ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    3
                  }][name]`,
                  "JKK"
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    3
                  }][value]`,
                  perCityData?.jkk1 || 0
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    3
                  }][action]`,
                  perCityData?.jkkID1 ? "UPDATE" : ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    3
                  }][type]`,
                  "BPU"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    3
                  }][billingTo]`,
                  "Karyawan"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    4
                  }][allowanceID]`,
                  perCityData?.jkmID1 || ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    4
                  }][name]`,
                  "JKM"
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    4
                  }][value]`,
                  perCityData?.jkm1 || 0
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    4
                  }][action]`,
                  perCityData?.jkmID1 ? "UPDATE" : ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    4
                  }][type]`,
                  "BPU"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    4
                  }][billingTo]`,
                  "Karyawan"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    5
                  }][allowanceID]`,
                  perCityData?.jhtID1 || ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    5
                  }][name]`,
                  "JHT"
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    5
                  }][value]`,
                  perCityData?.jht1 || 0
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    5
                  }][action]`,
                  perCityData?.jhtID1 ? "UPDATE" : ""
                );
                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    5
                  }][type]`,
                  "BPU"
                );

                formData?.append(
                  `jobs[${i}][locations][${j}][allowances][${
                    perCityData?.allowances?.length +
                    deletedAllowancesArr?.length +
                    5
                  }][billingTo]`,
                  "Karyawan"
                );
              }
            }
          }
        }

        for (let i = 0; i < contracts.length; i++) {
          formData?.append(
            `cooperationPeriod[${i}][id]`,
            contracts[i]?.contractID || ""
          );
          formData?.append(
            `cooperationPeriod[${i}][startDate]`,
            moment(contracts[i]?.startDate)?.format("YYYY-MM-DD")
          );
          formData?.append(
            `cooperationPeriod[${i}][endDate]`,
            moment(contracts[i]?.endDate)?.format("YYYY-MM-DD")
          );
          formData?.append(
            `cooperationPeriod[${i}][docType]`,
            contracts[i]?.docType || ""
          );
          formData?.append(
            `cooperationPeriod[${i}][docNumber]`,
            contracts[i]?.docNumber || ""
          );
          formData?.append(
            `cooperationPeriod[${i}][status]`,
            contracts[i]?.status || ""
          );
          formData?.append(
            `cooperationPeriod[${i}][remark]`,
            contracts[i]?.remark || ""
          );
          formData?.append(`files[${i}]`, contracts[i]?.file);
        }

        const filesArr = form?.files?.filter((el) => !el?.id);

        for (let i = 0; i < deletedFileIDs.length; i++) {
          formData?.append(`documentID[${i}]`, deletedFileIDs[i]);
        }

        for (let i = 0; i < filesArr.length; i++) {
          const file = filesArr[i];
          formData?.append("files", file);
        }

        if (type === "send") {
          await editOrderAndSendFLB(formData, query.get("i"));
          setSubmitting(false);
          setIsSuccess(true);
        } else {
          await editOrderAsDraft(formData, query.get("i"));
          setSubmittingDraft(false);
          setIsSuccessDraft(true);
        }
      } catch (err) {
        setSubmitting(false);
        setSubmittingDraft(false);
        setError({
          code: err?.response?.data?.code,
          message: err?.response?.data?.error,
        });
      }
    },
    [
      form?.contractType,
      form?.workSchemes?.tkoContract,
      form?.workSchemes?.taxs?.pph21,
      form?.workSchemes?.taxs?.pph23,
      form?.workSchemes?.taxs?.ppn,
      form?.workSchemes?.bpjs?.registration,
      form?.workSchemes?.bpjs?.paymentProof,
      form?.workSchemes?.bpjs?.jkk,
      form?.workSchemes?.bpjs?.jkm,
      form?.workSchemes?.bpjs?.jht,
      form?.workSchemes?.bpjs?.akhdk,
      form?.workSchemes?.bpjs?.jp,
      form?.workSchemes?.bpjs?.health,
      form?.workSchemes?.bpjs?.cutOffBPJSTKO,
      form?.workSchemes?.bpjs?.cutOffNominal,
      form?.workSchemes?.others?.thr,
      form?.workSchemes?.others?.uak,
      form?.clientCode,
      form?.setManFee,
      form?.setTOP,
      form?.remark,
      form?.additional?.recipientsAccount?.bankName,
      form?.additional?.recipientsAccount?.bankNumber,
      form?.additional?.recipientsAccount?.bankRecipient,
      form?.additional?.invoiceFlow?.dateOfReceiptOfData,
      form?.additional?.invoiceFlow?.UP,
      form?.additional?.invoiceFlow?.dateOfFillingOfSoftCopy,
      form?.additional?.invoiceFlow?.dateOfFillingOfHardCopy,
      form?.additional?.invoiceFlow?.payrollDate,
      form?.additional?.invoiceFlow?.cutOffAttendance,
      form?.additional?.invoiceFlow?.supportingDocument,
      form?.additional?.others?.deductedAttendance,
      form?.additional?.others?.others,
      form?.additional?.contacts,
      form?.files,
      defaultSecond?.workScheme,
      withBPJS,
      selectedValueBPU,
      fixedWorkSchemeManFee,
      fixedTkoBank,
      defaultThird?.additional?.flowInvoice,
      mappedDelegationsForm,
      mappedDeletedDelegationsForm,
      positionsForm,
      defaultPositionsForm,
      selectedButtonBPJS,
      contracts,
      deletedFileIDs,
      query,
    ]
  );

  // const renewWarning = useCallback(() => {
  //   const newSteps = [...steps];

  //   if (isDoneChecker?.first() === true) {
  //     newSteps[0].warning = false;
  //     setSteps(newSteps);
  //   } else {
  //     newSteps[0].warning = true;
  //     setSteps(newSteps);
  //   }

  //   if (isDoneChecker?.second() === true) {
  //     newSteps[1].warning = false;
  //     setSteps(newSteps);
  //   } else {
  //     newSteps[1].warning = true;
  //     setSteps(newSteps);
  //   }
  //   if (isDoneChecker?.third() === true) {
  //     newSteps[2].warning = false;
  //     setSteps(newSteps);
  //   } else {
  //     newSteps[2].warning = true;
  //     setSteps(newSteps);
  //   }
  //   if (isDoneChecker?.fourth() === true) {
  //     newSteps[3].warning = false;
  //     setSteps(newSteps);
  //   } else {
  //     newSteps[3].warning = true;
  //     setSteps(newSteps);
  //   }

  //   if (!manfee || !TOP) {
  //     newSteps[4].warning = true;
  //     setSteps(newSteps);
  //   } else {
  //     newSteps[4].warning = false;
  //     setSteps(newSteps);
  //   }

  //   // if (isDoneChecker?.sixth()) {
  //   //   newSteps[5].warning = false;
  //   //   setSteps(newSteps);
  //   // } else {
  //   //   newSteps[5].warning = true;
  //   //   setSteps(newSteps);
  //   // }

  //   // eslint-disable-next-line
  // }, [isDoneChecker, manfee, TOP]);

  // useEffect(() => {
  //   renewWarning();
  // }, [renewWarning]);

  const clearStorage = () => {};

  const alertFound = useMemo(() => {
    let errors = [];
    switch (currentStep) {
      case 1:
        if (!contractForm?.contract || contracts?.length === 0) {
          if (!contractForm?.contract) {
            errors?.push({
              validateContract: true,
              remark: "Tipe Kontrak",
              name: "Contract Type",
            });
          }

          if (contracts?.length === 0) {
            errors?.push({
              validateContracts: true,
              remark: "Dokumen Legal",
              name: "Dokumen Legal",
            });
          }
        }
        break;
      case 2:
        if (
          !workSchemeForm?.taxs?.pph21 ||
          !workSchemeForm?.taxs?.pph23 ||
          !workSchemeForm?.taxs?.ppn ||
          (withBPJS &&
            (!workSchemeForm?.bpjs?.registration ||
              !workSchemeForm?.bpjs?.paymentProof ||
              !workSchemeForm?.bpjs?.cutOffBPJSTKO ||
              (workSchemeForm?.bpjs?.cutOffBPJSTKO === "Manual" &&
                !workSchemeForm?.bpjs?.cutOffNominal) ||
              (selectedButtonBPJS === "BPU" && !selectedValueBPU))) ||
          !workSchemeForm?.others?.manfee?.length ||
          !workSchemeForm?.others?.thr ||
          !workSchemeForm?.others?.uak ||
          !workSchemeForm?.others?.tkoBank?.length ||
          !workSchemeForm?.tkoContract ||
          (workSchemeForm?.bpjs?.jkkOn && !workSchemeForm?.bpjs?.jkk) ||
          (workSchemeForm?.bpjs?.jkmOn && !workSchemeForm?.bpjs?.jkm) ||
          (workSchemeForm?.bpjs?.jhtOn && !workSchemeForm?.bpjs?.jht) ||
          (workSchemeForm?.bpjs?.jpOn && !workSchemeForm?.bpjs?.jp) ||
          (workSchemeForm?.bpjs?.healthOn && !workSchemeForm?.bpjs?.health) ||
          (workSchemeForm?.bpjs?.akhdkOn && !workSchemeForm?.bpjs?.akhdk)
        ) {
          // return "Mohon untuk melengkapi form terlebih dahulu";
          if (!workSchemeForm?.tkoContract) {
            errors?.push({
              validateTkoContract: true,
              remark: "Kontrak TKO",
              name: "Kontrak TKO",
            });
          }

          if (!workSchemeForm?.taxs?.pph21) {
            errors?.push({
              validatePph21: true,
              remark: "PPH21",
              name: "PPH21",
            });
          }

          if (!workSchemeForm?.taxs?.pph23) {
            errors?.push({
              validatePph23: true,
              remark: "PPH23",
              name: "PPH23",
            });
          }

          if (!workSchemeForm?.taxs?.ppn) {
            errors?.push({
              validatePpn: true,
              remark: "PPN",
              name: "PPN",
            });
          }

          if (withBPJS) {
            if (!workSchemeForm?.bpjs?.registration) {
              errors?.push({
                validatePendaftaranBPJS: true,
                remark: "Pendaftaran BPJS",
                name: "Pendaftaran BPJS",
              });
            }

            if (!workSchemeForm?.bpjs?.paymentProof) {
              errors?.push({
                validateBuktiPembayaranBPJS: true,
                remark: "Bukti Pembayaran BPJS",
                name: "Bukti Pembayaran BPJS",
              });
            }

            if (!workSchemeForm?.bpjs?.cutOffBPJSTKO) {
              errors?.push({
                validatePerhitunganPotonganBPJSTKO: true,
                remark: "Perhitungan Potongan BPJS TKO",
                name: "Perhitungan Potongan BPJS TKO",
              });
            }

            if (
              workSchemeForm?.bpjs?.cutOffBPJSTKO === "Manual" &&
              !workSchemeForm?.bpjs?.cutOffNominal
            ) {
              errors?.push({
                validatePerhitunganPotonganBPJSTKO: true,
                remark: "Perhitungan Potongan BPJS TKO Manual",
                name: "Perhitungan Potongan BPJS TKO",
              });
            }

            if (selectedButtonBPJS === "BPU" && !selectedValueBPU) {
              errors?.push({
                validatePenagihanBPJS: true,
                remark: "Penagihan BPJS",
                name: "Penagihan BPJS",
              });
            }
          }

          if (workSchemeForm?.bpjs?.jkkOn && !workSchemeForm?.bpjs?.jkk) {
            errors?.push({
              validateJKK: true,
              remark: "JKK (Jaminan Kelengkapan Kerja)",
              name: "JKK (Jaminan Kelengkapan Kerja)",
            });
          }

          if (workSchemeForm?.bpjs?.jkmOn && !workSchemeForm?.bpjs?.jkm) {
            errors?.push({
              validateJKM: true,
              remark: "JKM (Jaminan Kematian)",
              name: "JKM (Jaminan Kematian)",
            });
          }

          if (workSchemeForm?.bpjs?.jhtOn && !workSchemeForm?.bpjs?.jht) {
            errors?.push({
              validateJHT: true,
              remark: "JHT (Jaminan Hari Tua)",
              name: "JHT (Jaminan Hari Tua)",
            });
          }
          if (workSchemeForm?.bpjs?.jpOn && !workSchemeForm?.bpjs?.jp) {
            errors?.push({
              validateJP: true,
              remark: "JP (Jaminan Pensiun)",
              name: "JP (Jaminan Pensiun)",
            });
          }

          if (workSchemeForm?.bpjs?.healthOn && !workSchemeForm?.bpjs?.health) {
            errors?.push({
              validateKS: true,
              remark: "KS (kesehatan)",
              name: "KS (kesehatan)",
            });
          }

          if (workSchemeForm?.bpjs?.akhdkOn && !workSchemeForm?.bpjs?.akhdk) {
            errors?.push({
              validateAKDHK: true,
              remark: "AKDHK",
              name: "AKDHK",
            });
          }

          if (!workSchemeForm?.others?.manfee?.length) {
            errors?.push({
              validatePengenaanManfee: true,
              remark: "Pengenaan Manfee",
              name: "Pengenaan Manfee",
            });
          }

          if (!workSchemeForm?.others?.thr) {
            errors?.push({
              validatePenagihanTHR: true,
              remark: "Penagihan THR",
              name: "Penagihan THR",
            });
          }

          if (!workSchemeForm?.others?.uak) {
            errors?.push({
              validatePenagihanUAK: true,
              remark: "Penagihan UAK",
              name: "Penagihan UAK",
            });
          }

          if (!workSchemeForm?.others?.tkoBank?.length) {
            errors?.push({
              validateRekeningWajibTKO: true,
              remark: "Rekening Wajib TKO",
              name: "Rekening Wajib TKO",
            });
          }
        }

        break;
      case 3:
        if (
          // !additionalForm?.cooperationPeriod?.startDate
          // || !additionalForm?.cooperationPeriod?.endDate
          // || !additionalForm?.cooperationPeriod?.documentType
          // || !additionalForm?.cooperationPeriod?.documentNumber
          !additionalForm?.contacts?.length ||
          !additionalForm?.invoiceFlow?.dateOfReceiptOfData ||
          !additionalForm?.invoiceFlow?.dateOfFillingOfSoftCopy ||
          !additionalForm?.invoiceFlow?.dateOfFillingOfHardCopy ||
          !additionalForm?.invoiceFlow?.UP ||
          !additionalForm?.invoiceFlow?.payrollDate ||
          !additionalForm?.invoiceFlow?.cutOffAttendance
        ) {
          // return "Mohon untuk melengkapi form terlebih dahulu";
          if (!additionalForm?.contacts?.length) {
            errors?.push({
              validateContactInformation: true,
              remark: "Contact Information",
              name: "Contact Information",
            });
          }

          if (!additionalForm?.invoiceFlow?.dateOfReceiptOfData) {
            errors?.push({
              validateDateOfReceiptOfData: true,
              remark: "Tanggal Diterima Data",
              name: "Tanggal Diterima Data",
            });
          }

          if (!additionalForm?.invoiceFlow?.dateOfFillingOfSoftCopy) {
            errors?.push({
              validateDateOfFillingOfSoftCopy: true,
              remark: "Tanggal Pengajuan Soft Copy Invoice",
              name: "Tanggal Pengajuan Soft Copy Invoice",
            });
          }
          if (!additionalForm?.invoiceFlow?.dateOfFillingOfHardCopy) {
            errors?.push({
              validateDateOfFillingOfHardCopy: true,
              remark: "Tanggal Hard Copy Invoice Diterima",
              name: "Tanggal Hard Copy Invoice Diterima",
            });
          }
          if (!additionalForm?.invoiceFlow?.UP) {
            errors?.push({
              validateUP: true,
              remark: "UP di Invoice",
              name: "UP di Invoice",
            });
          }

          if (!additionalForm?.invoiceFlow?.payrollDate) {
            errors?.push({
              validatePayrollDate: true,
              remark: "Tanggal Mulai Penggajian",
              name: "Tanggal Mulai Penggajian",
            });
          }

          if (!additionalForm?.invoiceFlow?.cutOffAttendance) {
            errors?.push({
              validateCutOffAttendance: true,
              remark: "Tanggal Cut Off Absensi",
              name: "Tanggal Cut Off Absensi",
            });
          }
        }
        break;
      case 4:
        if (!delegationsForm?.length) {
          errors?.push({
            validateCutOffAttendance: true,
            remark: "Daftar Lokasi",
            name: "Daftar Lokasi",
          });
        } else {
          const dArr3 = delegationsForm
            ?.map((obj) =>
              obj?.deployments[3]?.delegations?.map((u) => {
                return {
                  ...u,
                  cityName: obj?.cityName,
                };
              })
            )
            ?.flat();

          if (!dArr3?.find((u) => u?.isPicked)) {
            errors?.push({
              validateRO: true,
              remark: "RECRUITMENT OFFICER",
              name: `Data Recruitment Officer`,
              cityName: delegationsForm[0]?.cityName,
              cityCode: delegationsForm[0]?.cityCode,
            });
          } else {
            const dArr = delegationsForm?.map((obj) => {
              return {
                cityCode: obj?.cityCode,
                cityName: obj?.cityName,
                users: obj?.deployments[0]?.delegations?.map((u) => {
                  return {
                    ...u,
                    cityName: obj?.cityName,
                  };
                }),
              };
            });

            for (let i = 0; i < dArr?.length; i++) {
              const d = dArr[i];

              // console.log(d, "test");

              const found = !d?.users?.find((u) => u?.isPicked);

              if (found) {
                // return `Harap pilih business manager untuk lokasi ${d[0]?.cityName}`;
                errors?.push({
                  [`${i}`]: true,
                  remark: "BUSINESS MANAGER",
                  name: `Data Business Manager ${d?.cityName}`,
                  cityName: d?.cityName,
                  cityCode: d?.cityCode,
                });
              }
            }
          }
        }

        break;

      case 5:
        if (!positionsForm?.length) {
          errors?.push({
            validatePositionsForm: true,
            remark: "Daftar Posisi",
            name: "Daftar Posisi",
          });
        } else {
          const flatPositions = positionsForm
            ?.map((obj) =>
              obj?.citiesData
                ?.filter((f) => f?.isApplied)
                ?.map((c) => {
                  return {
                    ...c,
                    positionName: obj?.positionName,
                  };
                })
            )
            ?.flat();

          for (let i = 0; i < delegationsForm?.length; i++) {
            if (
              !flatPositions?.find(
                (p) => p?.cityCode === delegationsForm[i].cityCode
              )
            ) {
              errors?.push({
                [`${i}`]: true,
                remark: "Daftar Posisi",
                name: `Belum ada posisi diaktifkan untuk kota ${delegationsForm[i]?.cityName}`,
                cityId: positionsForm[0]?.citiesData?.find(
                  (c) => c?.cityCode === delegationsForm[i].cityCode
                )
                  ? positionsForm[0]?.citiesData?.find(
                      (c) => c?.cityCode === delegationsForm[i].cityCode
                    )?.id
                  : "",
                positionId: positionsForm[0]?.id,
              });
            }

            for (let i = 0; i < positionsForm.length; i++) {
              // if (!positionsForm[i]?.positionName) {
              //   return `Salah satu nama posisi belum diisi`;
              // }
              // if (
              //   positionsForm[i]?.qualifications?.find(
              //     (obj) => !obj?.value || !obj?.name
              //   )
              // ) {
              //   return `Kualifikasi untuk ${
              //     positionsForm[i]?.positionName || "salah satu posisi"
              //   } belum diisi`;
              // }
              // if (!positionsForm[i]?.citiesData?.find((c) => c?.isApplied)) {
              //   return `Posisi ${positionsForm[i]?.positionName} belum diaktifkan ke salah satu kota`;
              // }
              const appliedCities = positionsForm[i]?.citiesData?.filter(
                (c) => c?.isApplied
              );

              for (let j = 0; j < appliedCities?.length; j++) {
                const isDuplicate = errors?.some(
                  (e) =>
                    e.cityId === appliedCities[j]?.id &&
                    e.positionId === positionsForm[i]?.id
                );

                if (
                  appliedCities[j]?.salaryValue === "" ||
                  appliedCities[j]?.salaryValue === null
                ) {
                  if (!isDuplicate) {
                    errors.push({
                      [`${j}`]: true,
                      remark: "Salary",
                      name: `Nominal salary ${positionsForm[i]?.positionName} ${appliedCities[j]?.cityName} belum diisi`,
                      cityId: appliedCities[j]?.id,
                      positionId: positionsForm[i]?.id,
                    });
                  }
                }

                if (
                  appliedCities[j]?.salaryDenom === "" ||
                  appliedCities[j]?.salaryDenom === null
                ) {
                  if (!isDuplicate) {
                    errors?.push({
                      [`${j}`]: true,
                      remark: "SalaryDenom",
                      name: `Tempo salary ${positionsForm[i]?.positionName} ${appliedCities[j]?.cityName} belum diisi`,
                      cityId: appliedCities[j]?.id,
                      positionId: positionsForm[i]?.id,
                    });
                  }
                }

                if (appliedCities[j]?.personalEquipments?.length > 0) {
                  for (
                    let l = 0;
                    l < appliedCities[j]?.personalEquipments?.length;
                    l++
                  ) {
                    const personalEquipment =
                      appliedCities[j]?.personalEquipments[l];
                    if (!personalEquipment?.value) {
                      if (!isDuplicate) {
                        errors.push({
                          [`${l}`]: true,
                          remark: `Personal Equipment ${personalEquipment?.name}`,
                          name: `Personal Equipment ${personalEquipment?.name} posisi ${positionsForm[i]?.positionName} ${appliedCities[j]?.cityName} belum diisi`,
                          cityId: appliedCities[j]?.id,
                          positionId: positionsForm[i]?.id,
                        });
                      }
                    }
                  }
                }

                if (appliedCities[j]?.allowances?.length > 0) {
                  for (
                    let k = 0;
                    k < appliedCities[j]?.allowances?.length;
                    k++
                  ) {
                    const allowance = appliedCities[j]?.allowances[k];
                    if (!allowance?.value) {
                      if (!isDuplicate) {
                        errors.push({
                          [`${k}`]: true,
                          remark: `Allowances ${allowance?.name}`,
                          name: `Allowances ${allowance?.name} posisi ${positionsForm[i]?.positionName} ${appliedCities[j]?.cityName} belum diisi`,
                          cityId: appliedCities[j]?.id,
                          positionId: positionsForm[i]?.id,
                        });
                      }
                      // return `Allowances ${allowance?.name} posisi ${positionsForm[i]?.positionName} ${appliedCities[j]?.cityName} belum diisi`;
                    }
                  }
                }

                if (!appliedCities[j]?.expectedDate) {
                  if (!isDuplicate) {
                    errors?.push({
                      [`${j}`]: true,
                      remark: "date",
                      name: `Expected fulfillment date ${positionsForm[i]?.positionName} ${appliedCities[j]?.cityName} belum diisi`,
                      cityId: appliedCities[j]?.id,
                      positionId: positionsForm[i]?.id,
                    });
                  }
                }
              }
            }
          }
        }

        // const noRODedicatedFound = positionsForm?.find(
        //   (obj) => !obj?.dedicatedROId || !obj?.dedicatedROName
        // );
        // if (noRODedicatedFound) {
        //   return `Mohon untuk memilih Recruitment Officer Dedicated untuk posisi ${noRODedicatedFound?.positionName}`;
        // }
        // const noQualificationFound = positionsForm?.find(
        //   (obj) => !obj?.qualifications?.length
        // );
        // if (noQualificationFound) {
        //   return `Mohon untuk memasukkan kualifikasi untuk posisi ${noQualificationFound?.positionName}`;
        // }
        break;

      case 6:
        if (!TOP || TOP === "0") {
          // return `Mohon untuk mengisi set TOP terlebih dahulu`;
          errors?.push({
            validateTOP: true,
            remark: "set",
            name: `Set TOP`,
          });
        }

        if (!manfee || manfee === "0" || manfee === "0%") {
          // return `Mohon untuk mengisi manfee terlebih dahulu`;
          errors?.push({
            validateManfee: true,
            remark: "set",
            name: `Manfee`,
          });
        }

        break;
      default:
        return errors;
    }

    return errors;
  }, [
    TOP,
    manfee,
    additionalForm?.contacts?.length,
    additionalForm?.invoiceFlow?.UP,
    additionalForm?.invoiceFlow?.cutOffAttendance,
    additionalForm?.invoiceFlow?.dateOfFillingOfHardCopy,
    additionalForm?.invoiceFlow?.dateOfFillingOfSoftCopy,
    additionalForm?.invoiceFlow?.dateOfReceiptOfData,
    additionalForm?.invoiceFlow?.payrollDate,
    contractForm?.contract,
    contracts,
    currentStep,
    delegationsForm,
    positionsForm,
    selectedValueBPU,
    withBPJS,
    workSchemeForm?.bpjs?.akhdk,
    workSchemeForm?.bpjs?.akhdkOn,
    workSchemeForm?.bpjs?.cutOffBPJSTKO,
    workSchemeForm?.bpjs?.cutOffNominal,
    workSchemeForm?.bpjs?.health,
    workSchemeForm?.bpjs?.healthOn,
    workSchemeForm?.bpjs?.jht,
    workSchemeForm?.bpjs?.jhtOn,
    workSchemeForm?.bpjs?.jkk,
    workSchemeForm?.bpjs?.jkkOn,
    workSchemeForm?.bpjs?.jkm,
    workSchemeForm?.bpjs?.jkmOn,
    workSchemeForm?.bpjs?.paymentProof,
    workSchemeForm?.bpjs?.registration,
    workSchemeForm?.others?.manfee?.length,
    workSchemeForm?.others?.thr,
    workSchemeForm?.others?.tkoBank?.length,
    workSchemeForm?.others?.uak,
    workSchemeForm?.taxs?.pph21,
    workSchemeForm?.taxs?.pph23,
    workSchemeForm?.taxs?.ppn,
    workSchemeForm?.tkoContract,
    workSchemeForm?.bpjs?.jpOn,
    workSchemeForm?.bpjs?.jp,
    selectedButtonBPJS,
  ]);

  // useEffect(() => {
  //   if (!alertFound) {
  //     setAlertMessage("");
  //   }
  // }, [alertFound, setAlertMessage]);

  // useEffect(() => {
  //   if (alertMessage && alertMessage !== alertFound) {
  //     setAlertMessage(alertFound);
  //   }
  // }, [alertFound, alertMessage, setAlertMessage]);

  const [errorDeployment, setErrorDeployment] = useState(false);

  const handleOnNext = useCallback(() => {
    if (alertFound?.length > 0) {
      setAlertMessage(alertFound);
      if (
        currentStep === 4 &&
        alertFound?.filter((el) => el?.remark === "RECRUITMENT OFFICER")
          ?.length > 0
      ) {
        setErrorDeployment(true);
        setActiveCityID(delegationsForm[0]?.cityCode);
      }
    } else {
      setAlertMessage([]);
      onNext(true);
      setErrorDeployment(false);
    }
    scrollingTop();
  }, [
    alertFound,
    onNext,
    setAlertMessage,
    currentStep,
    setActiveCityID,
    delegationsForm,
  ]);

  // console.log(workSchemeForm, "tasya", contractForm);

  // cek habis ini

  useEffect(() => {
    const form = { ...defaultWorkSchemeForm };

    if (!isLoadingSecond) {
      if (contractForm?.contract?.toLowerCase() === "jasa pemborongan") {
        form.taxs.ppn = "Dari total";
        setWorkSchemeForm(form);
      }
    }
  }, [contractForm, isLoadingSecond, defaultWorkSchemeForm]);

  const contractTypeOptions = useMemo(() => {
    let arr = [];
    for (let i = 0; i < syncData?.options?.length; i++) {
      const option = syncData?.options[i];
      if (
        option?.type === "contractTypeClient" &&
        option?.value === "Jasa Pemborongan"
      ) {
        arr.push({
          type: "PEMBORONGAN",
          title: option?.value,
          label: "PPN Dari Total Biaya",
          rules: [
            {
              id: 11,
              desc: "Status Karyawan PKWT atau Mitra",
            },
            {
              id: 12,
              desc: "PPh 21 dihitung dari penghasilan per bulan",
            },
            {
              id: 13,
              desc: "BPJS Kesehatan dihitung dari bantuan operasional (minimum UMP)",
            },
            {
              id: 14,
              desc: "BPJS Ketenagakerjaan JKK, JKM, JHT, atau JKK & JKM Dihitung dari bantuan operasional",
            },
            {
              id: 15,
              desc: "Manajemen Fee dihitung dari selisih nilai kontrak dengan biaya yang dikeluarkan",
            },
          ],
        });
      } else if (
        option?.type === "contractTypeClient" &&
        option?.value === "Penyedia Jasa Tenaga Kerja"
      ) {
        arr.push({
          type: "PJTK",
          title: option?.value,
          label: "PPN dari Manfee & PPN Fasilitas dari Total",
          rules: [
            {
              id: 21,
              desc: "Status Karyawan PKWT",
            },
            {
              id: 22,
              desc: "PPH 21 dihitung dari penghasilan yang disetahunkan",
            },
            {
              id: 23,
              desc: "BPJS Kesehatan dihitung dari Gapok + Tunjangan tetap (minimum UMP)",
            },
            {
              id: 24,
              desc: "BPJS Ketenagakerjaan JKK, JKM, JHT, JP Dihitung dari Gapok + Tunjangan tetap",
            },
            {
              id: 25,
              desc: "Manajemen Fee dihitung dari prosentase atau nominal yang disepakati",
            },
          ],
        });
      }
    }
    return arr;
  }, [syncData?.options]);

  const selectedContract = useMemo(() => {
    return contractTypeOptions?.find(
      (obj) => obj?.title === contractForm?.contract
    );
  }, [contractForm?.contract, contractTypeOptions]);

  const activeStep = useMemo(() => {
    switch (currentStep) {
      case 1:
        return (
          <FirstStep
            alertMessage={alertMessage}
            form={contractForm}
            setForm={setContractForm}
            options={optionsFetcher?.first()}
            setContractType={setContractType}
            setTrigger={setTrigger}
            isDone={isDoneChecker?.first()}
            onNext={handleOnNext}
            contracts={contracts}
            setContracts={setContracts}
            flbData={{
              schemeID: defaultSecond?.workScheme?.schemeID || query.get("i"),
              docNumber: defaultThird?.additional?.periodeKerjasama?.docNumber,
              logDate: defaultFirst?.pendingLog?.updatedAt,
            }}
          />
        );
      case 2:
        return (
          <SecondStep
            defaultForm={defaultWorkSchemeForm}
            form={workSchemeForm}
            setForm={setWorkSchemeForm}
            options={optionsFetcher?.second()}
            isDone={isDoneChecker?.second()}
            // onNext={() => onNext(isDoneChecker?.second())}
            onNext={handleOnNext}
            onPrevious={onPrevious}
            setTrigger={setTrigger}
            setActiveButtonBPJS={setActiveButtonBPJS}
            selectedButtonBPJS={selectedButtonBPJS}
            setSelectedButtonBPJS={setSelectedButtonBPJS}
            selectedValueBPU={selectedValueBPU}
            setSelectedValueBPU={setSelectedValueBPU}
            alertMessage={alertMessage}
            contractForm={contractForm}
            withBPJS={withBPJS}
            setWithBPJS={setWithBPJS}
            optionalBPJS
          />
        );
      case 3:
        return (
          <ThirdStep
            form={additionalForm}
            setForm={setAdditionalForm}
            files={files}
            setFiles={setFiles}
            onPrevious={onPrevious}
            onNext={handleOnNext}
            isDone={isDoneChecker?.third()}
            setTrigger={setTrigger}
            clientContacts={clientContacts}
            alertMessage={alertMessage}
          />
        );
      case 4:
        return (
          <DeploymentStep
            delegationsForm={delegationsForm}
            setDelegationsForm={setDelegationsForm}
            defaultDeployments={defaultDeploymentsData}
            cities={syncData?.cities}
            headerHeight={headerHeight}
            alertFound={alertMessage}
            errorDeployment={errorDeployment}
            setErrorDeployment={setErrorDeployment}
            activeCityID={activeCityID}
            setActiveCityID={setActiveCityID}
          />
          // <FourthStep
          //   form={manPowerForm}
          //   setForm={setManPowerForm}
          //   options={optionsFetcher?.fourth()}
          //   cities={syncData?.cities}
          //   remark={remark}
          //   setRemark={setRemark}
          //   trigger={trigger}
          //   setTrigger={setTrigger}
          //   isDone={isDoneChecker?.fourth()}
          //   onPrevious={onPrevious}
          //   onNext={() => onNext(isDoneChecker?.fourth())}
          //   debounceTrigger={debounceTrigger}
          //   selectedValueBPU={selectedValueBPU}
          // />
          // <NewFourth
          //   formArr={manPowerFormArr}
          //   setFormArr={setManPowerFormArr}
          //   cities={syncData?.cities}
          //   remark={remark}
          //   setRemark={setRemark}
          //   defaultDeployments={defaultDeploymentsData}
          //   contractTypeOptions={syncData?.options?.filter(obj => obj?.type === "contractTypeWorker")?.map(obj => obj?.value)}
          //   salaryDenomOptions={["Harian", "Mingguan", "Bulanan"]}
          //   personalEquipmentOptions={syncData?.options?.filter(obj => obj?.type === 'Personal Work Equipment')?.map(obj => obj?.value)}
          //   allowanceOptions={syncData?.options?.filter(obj => obj?.type === "Allowance")?.map(obj => obj?.value)}
          //   qualificationOptions={syncData?.qualifications?.map(obj => {
          //     return {
          //       // id: makeRandomString(5),
          //       name: obj?.value,
          //       denom: obj?.denom,
          //       value: ''
          //     }
          //   })}
          //   areaEquipmentOptions={syncData?.options?.filter(obj => obj?.type === 'Area Work Equipment')?.map(obj => obj?.value)}
          //   onNext={() => onNext(true)}
          //   onPrevious={onPrevious}
          //   isDone={isDoneChecker?.fourth()}
          //   selectedValueBPU={selectedValueBPU}
          // />
          // <FourthRevamp
          //   onPrevious={onPrevious}
          //   onNext={handleOnNext}
          //   delegationsForm={delegationsForm}
          //   setDelegationsForm={setDelegationsForm}
          //   positionsForm={positionsForm}
          //   setPositionsForm={setPositionsForm}
          //   remark={remark}
          //   setRemark={setRemark}
          //   defaultDeployments={defaultDeploymentsData}
          //   cities={syncData?.cities}
          //   alertMessage={alertMessage}
          //   contractTypeOptions={syncData?.options
          //     ?.filter((obj) => obj?.type === "contractTypeWorker")
          //     ?.map((obj) => obj?.value)}
          //   personalEquipmentOptions={syncData?.options
          //     ?.filter((obj) => obj?.type === "Personal Work Equipment")
          //     ?.map((obj) => obj?.value)}
          //   allowanceOptions={syncData?.options
          //     ?.filter((obj) => obj?.type === "Allowance")
          //     ?.map((obj) => obj?.value)}
          //   qualificationOptions={syncData?.qualifications?.map((obj) => {
          //     return {
          //       // id: makeRandomString(5),
          //       name: obj?.value,
          //       denom: obj?.denom,
          //       value: "",
          //     };
          //   })}
          //   areaEquipmentOptions={syncData?.options
          //     ?.filter((obj) => obj?.type === "Area Work Equipment")
          //     ?.map((obj) => obj?.value)}
          //   selectedButtonBPJS={selectedButtonBPJS}
          //   selectedValueBPU={selectedValueBPU}
          //   scrollingTop={scrollingTop}
          // />
        );
      case 5:
        return (
          <PositionStep
            activeExactPositionID={activeExactPositionID}
            setActiveExactPositionID={setActiveExactPositionID}
            activePositionID={activePositionID}
            setActivePositionID={setActivePositionID}
            delegationsForm={delegationsForm}
            setDelegationsForm={setDelegationsForm}
            positionsForm={positionsForm}
            setPositionsForm={setPositionsForm}
            headerHeight={headerHeight}
            alertMessage={alertMessage}
            qualificationOptions={syncData?.qualifications?.map((obj) => {
              return {
                // id: makeRandomString(5),
                name: obj?.value,
                denom: obj?.denom,
                value: "",
              };
            })}
            remark={remark}
            setRemark={setRemark}
            cities={syncData?.cities}
            personalEquipmentOptions={syncData?.options
              ?.filter((obj) => obj?.type === "Personal Work Equipment")
              ?.map((obj) => obj?.value)}
            allowanceOptions={syncData?.options
              ?.filter((obj) => obj?.type === "Allowance")
              ?.map((obj) => obj?.value)}
            selectedButtonBPJS={selectedButtonBPJS}
            selectedValueBPU={selectedValueBPU}
            scrollingTop={scrollingTop}
          />

          // <FourthStep
          //   form={manPowerForm}
          //   setForm={setManPowerForm}
          //   options={optionsFetcher?.fourth()}
          //   cities={syncData?.cities}
          //   remark={remark}
          //   setRemark={setRemark}
          //   trigger={trigger}
          //   setTrigger={setTrigger}
          //   isDone={isDoneChecker?.fourth()}
          //   onPrevious={onPrevious}
          //   onNext={() => onNext(isDoneChecker?.fourth())}
          //   debounceTrigger={debounceTrigger}
          //   selectedValueBPU={selectedValueBPU}
          // />
          // <NewFourth
          //   formArr={manPowerFormArr}
          //   setFormArr={setManPowerFormArr}
          //   isDone={fourthStepDoneIndicator}
          //   cities={cities}
          //   remark={remark}
          //   setRemark={setRemark}
          //   defaultDeployments={defaultDeploymentsData}
          //   contractTypeOptions={options?.filter(obj => obj?.type === "contractTypeWorker")?.map(obj => obj?.value)}
          //   salaryDenomOptions={["Harian", "Mingguan", "Bulanan"]}
          //   personalEquipmentOptions={options?.filter(obj => obj?.type === 'Personal Work Equipment')?.map(obj => obj?.value)}
          //   allowanceOptions={options?.filter(obj => obj?.type === "Allowance")?.map(obj => obj?.value)}
          //   qualificationOptions={qualifications?.map(obj => {
          //     return {
          //       // id: makeRandomString(5),
          //       name: obj?.value,
          //       denom: obj?.denom,
          //       value: ''
          //     }
          //   })}
          //   areaEquipmentOptions={options?.filter(obj => obj?.type === 'Area Work Equipment')?.map(obj => obj?.value)}
          //   onNext={() => onNext(true)}
          //   onPrevious={onPrevious}
          //   selectedValueBPU={selectedValueBPU}
          // />
          // <FourthRevamp
          //   onPrevious={onPrevious}
          //   onNext={handleOnNext}
          //   delegationsForm={delegationsForm}
          //   setDelegationsForm={setDelegationsForm}
          //   positionsForm={positionsForm}
          //   setPositionsForm={setPositionsForm}
          //   remark={remark}
          //   setRemark={setRemark}
          //   defaultDeployments={defaultDeploymentsData}
          //   cities={cities}
          //   alertMessage={alertMessage}
          //   contractTypeOptions={options
          //     ?.filter((obj) => obj?.type === "contractTypeWorker")
          //     ?.map((obj) => obj?.value)}
          //   personalEquipmentOptions={options
          //     ?.filter((obj) => obj?.type === "Personal Work Equipment")
          //     ?.map((obj) => obj?.value)}
          //   allowanceOptions={options
          //     ?.filter((obj) => obj?.type === "Allowance")
          //     ?.map((obj) => obj?.value)}
          //   qualificationOptions={qualifications?.map((obj) => {
          //     return {
          //       // id: makeRandomString(5),
          //       name: obj?.value,
          //       denom: obj?.denom,
          //       value: "",
          //     };
          //   })}
          //   areaEquipmentOptions={options
          //     ?.filter((obj) => obj?.type === "Area Work Equipment")
          //     ?.map((obj) => obj?.value)}
          //   selectedButtonBPJS={selectedButtonBPJS}
          //   selectedValueBPU={selectedValueBPU}
          //   scrollingTop={scrollingTop}
          // />
        );
      case 6:
        return (
          <FifthStep
            alertMessage={alertMessage}
            noTaxes
            debounceTrigger={debounceTrigger}
            manfeeArr={workSchemeForm?.others?.manfee}
            fixedManfee={fixedWorkSchemeManFee}
            fixedBpjsCut={fixedBpjsCut}
            manfee={manfee}
            setManfee={setManFee}
            TOP={TOP}
            setTOP={setTOP}
            pph={workSchemeForm?.taxs?.pph23}
            form={delegationsForm?.map((obj) => {
              return {
                location: obj?.cityCode,
                cityName: obj?.cityName,
                umk: obj?.umk,
                areaWorkEquipments: [],
                jobs: positionsForm
                  ?.filter((p) =>
                    p?.citiesData?.find(
                      (c) => c?.cityCode === obj?.cityCode && c?.isApplied
                    )
                  )
                  ?.map((pObj) => {
                    return {
                      title: pObj?.positionName,
                      demand: pObj?.citiesData?.find(
                        (c) => c?.cityCode === obj?.cityCode
                      )?.amount,
                      contractType: workSchemeForm?.tkoContract,
                      fulfillmentDate: pObj?.citiesData?.find(
                        (c) => c?.cityCode === pObj?.cityCode
                      )?.expectedDate,
                      salary: pObj?.citiesData?.find(
                        (c) => c?.cityCode === obj?.cityCode
                      )?.salaryValue,
                      salaryPeriod: pObj?.citiesData?.find(
                        (c) => c?.cityCode === obj?.cityCode
                      )?.salaryDenom,
                      personalEquipments: pObj?.citiesData?.find(
                        (c) => c?.cityCode === obj?.cityCode
                      )?.personalEquipments,
                      qualifications: pObj?.qualifications,
                      allowances: !selectedValueBPU?.includes(
                        "Tagih Ke Perusahaan"
                      )
                        ? pObj?.citiesData?.find(
                            (c) => c?.cityCode === obj?.cityCode
                          )?.allowances
                        : [
                            ...pObj?.citiesData?.find(
                              (c) => c?.cityCode === obj?.cityCode
                            )?.allowances,
                            {
                              name: "JKK",
                              value: pObj?.citiesData?.find(
                                (c) => c?.cityCode === obj?.cityCode
                              )?.jkk,
                              type: "BPU",
                            },
                            {
                              name: "JKM",
                              value: pObj?.citiesData?.find(
                                (c) => c?.cityCode === obj?.cityCode
                              )?.jkm,
                              type: "BPU",
                            },
                            {
                              name: "JHT",
                              value: pObj?.citiesData?.find(
                                (c) => c?.cityCode === obj?.cityCode
                              )?.jht,
                              type: "BPU",
                            },
                          ],
                    };
                  }),
              };
            })}
            workSchemeForm={workSchemeForm}
            setForm={setManPowerForm}
            cities={syncData?.cities}
            onSubmit={handleSubmit}
            onPrevious={onPrevious}
            selectedValueBPU={selectedValueBPU}
            isSubmitting={submitting}
            isSubmittingDraft={submittingDraft}
            submittingType={submittingType}
            // isVerified={defaultOrderForm?.contractType?.isVerified}
            error={error}
            onNext={handleOnNext}
          />
        );

      case 7:
        return (
          <NewSixth
            noTaxes
            onSubmit={handleSubmit}
            onPrevious={onPrevious}
            isSubmitting={submitting}
            isSubmittingDraft={submittingDraft}
            submittingType={submittingType}
            contract={selectedContract}
            contracts={contracts}
            workSchemeForm={workSchemeForm}
            employmentType={contractType}
            remark={remark}
            additionalForm={additionalForm}
            contactInformation={additionalForm?.contacts}
            fixedManfee={fixedWorkSchemeManFee}
            fixedBpjsCut={fixedBpjsCut}
            manfee={manfee}
            TOP={TOP}
            delegationsForm={delegationsForm?.map((obj) => {
              return {
                location: obj?.cityCode,
                cityName: obj?.cityName,
                umk: obj?.umk,
                areaWorkEquipments: [],
                jobs: positionsForm
                  ?.filter((p) =>
                    p?.citiesData?.find(
                      (c) => c?.cityCode === obj?.cityCode && c?.isApplied
                    )
                  )
                  ?.map((pObj) => {
                    return {
                      title: pObj?.positionName,
                      demand: pObj?.citiesData?.find(
                        (c) => c?.cityCode === obj?.cityCode
                      )?.amount,
                      contractType: workSchemeForm?.tkoContract,
                      fulfillmentDate: pObj?.citiesData?.find(
                        (c) => c?.cityCode === pObj?.cityCode
                      )?.expectedDate,
                      salary: pObj?.citiesData?.find(
                        (c) => c?.cityCode === obj?.cityCode
                      )?.salaryValue,
                      salaryPeriod: pObj?.citiesData?.find(
                        (c) => c?.cityCode === obj?.cityCode
                      )?.salaryDenom,
                      personalEquipments: pObj?.citiesData?.find(
                        (c) => c?.cityCode === obj?.cityCode
                      )?.personalEquipments,
                      qualifications: pObj?.qualifications,
                      // allowances: pObj?.citiesData?.find(c => c?.cityCode === obj?.cityCode)?.allowances,
                      jkk:
                        pObj?.citiesData?.find(
                          (c) => c?.cityCode === obj?.cityCode
                        )?.jkk || 0,
                      jkm:
                        pObj?.citiesData?.find(
                          (c) => c?.cityCode === obj?.cityCode
                        )?.jkm || 0,
                      jht:
                        pObj?.citiesData?.find(
                          (c) => c?.cityCode === obj?.cityCode
                        )?.jht || 0,
                      jkk1:
                        pObj?.citiesData?.find(
                          (c) => c?.cityCode === obj?.cityCode
                        )?.jkk1 || 0,
                      jkm1:
                        pObj?.citiesData?.find(
                          (c) => c?.cityCode === obj?.cityCode
                        )?.jkm1 || 0,
                      jht1:
                        pObj?.citiesData?.find(
                          (c) => c?.cityCode === obj?.cityCode
                        )?.jht1 || 0,
                      allowances: !selectedValueBPU?.includes(
                        "Tagih Ke Perusahaan"
                      )
                        ? pObj?.citiesData?.find(
                            (c) => c?.cityCode === obj?.cityCode
                          )?.allowances
                        : [
                            ...pObj?.citiesData?.find(
                              (c) => c?.cityCode === obj?.cityCode
                            )?.allowances,
                            {
                              name: "JKK",
                              value: pObj?.citiesData?.find(
                                (c) => c?.cityCode === obj?.cityCode
                              )?.jkk,
                              type: "BPU",
                            },
                            {
                              name: "JKM",
                              value: pObj?.citiesData?.find(
                                (c) => c?.cityCode === obj?.cityCode
                              )?.jkm,
                              type: "BPU",
                            },
                            {
                              name: "JHT",
                              value: pObj?.citiesData?.find(
                                (c) => c?.cityCode === obj?.cityCode
                              )?.jht,
                              type: "BPU",
                            },
                          ],
                    };
                  }),
              };
            })}
            positionsForm={positionsForm}
            deploymentsForm={delegationsForm}
            cities={syncData?.cities}
            selectedValueBPU={selectedValueBPU}
            selectedButtonBPJS={selectedButtonBPJS}
          />
        );

      default:
        return (
          <FirstStep
            form={contractForm}
            setForm={setContractForm}
            options={optionsFetcher?.first()}
            setContractType={setContractType}
            setTrigger={setTrigger}
            isDone={isDoneChecker?.first()}
            onNext={() => onNext(isDoneChecker?.first())}
            alertMessage={alertMessage}
          />
        );
    }
  }, [
    currentStep,
    alertMessage,
    contractForm,
    optionsFetcher,
    setContractType,
    isDoneChecker,
    handleOnNext,
    contracts,
    defaultSecond?.workScheme?.schemeID,
    query,
    defaultThird?.additional?.periodeKerjasama?.docNumber,
    defaultFirst?.pendingLog?.updatedAt,
    defaultWorkSchemeForm,
    workSchemeForm,
    onPrevious,
    selectedButtonBPJS,
    selectedValueBPU,
    withBPJS,
    additionalForm,
    files,
    clientContacts,
    delegationsForm,
    defaultDeploymentsData,
    syncData?.cities,
    syncData?.qualifications,
    syncData?.options,
    headerHeight,
    positionsForm,
    remark,
    debounceTrigger,
    fixedWorkSchemeManFee,
    fixedBpjsCut,
    manfee,
    TOP,
    handleSubmit,
    submitting,
    submittingDraft,
    submittingType,
    error,
    selectedContract,
    contractType,
    onNext,
    errorDeployment,
    activeCityID,
    setActiveCityID,
    activePositionID,
    setActivePositionID,
    activeExactPositionID,
    setActiveExactPositionID,
    setPositionsForm,
  ]);

  const { width } = useWindowSize();

  useEffect(() => {
    if (defaultOrderForm?.contractType) {
      setContractType((prev) => ({
        ...prev,
        type: defaultOrderForm?.contractType?.contractType,
        label:
          defaultOrderForm?.contractType?.contractType === "Jasa Pemborongan"
            ? "PPN Dari Total Biaya"
            : "PPN dari Manfee & PPN Fasilitas dari Total",
      }));
    } else {
      setContractType(null);
    }
  }, [setContractType, defaultOrderForm]);

  return (
    <div className={Styles.container}>
      <div className={Styles.autoScroll} ref={targetElement} />
      {+currentStep !== 1 && width <= 768 && (
        <div className={Styles.contractType}>
          <div className={Styles.icon}>
            <Icon icon={"file"} size={24} color={"#1571DE"} />
          </div>
          <div className={Styles.desc}>
            <span>{contractType?.type || "-"}</span>
            <span>{contractType?.label || "-"}</span>
          </div>
        </div>
      )}
      {activeState?.isLoading && currentStep !== 2 ? (
        <LoadingAnimationPage
          height={`calc(100vh - ${headerHeight}px - 76px)`}
        />
      ) : (
        <div className={Styles.content}>{activeStep}</div>
      )}

      {error && (
        <ErrorModal
          error={error?.message || "-"}
          handleDone={() => setError(null)}
          onBack={() => setError(null)}
        />
      )}
      {/* {isSuccess && (
        <FinishedModal
          clearStorage={clearStorage}
          setShow={setIsSuccess}
          submittingType={submittingType}
        />
      )} */}

      {(submitting || submittingDraft || isSuccess || isSuccessDraft) && (
        <SendFLBModal
          // error={error}
          show={submitting || submittingDraft || isSuccess || isSuccessDraft}
          type={
            submitting
              ? "submit"
              : submittingDraft
              ? "draft"
              : isSuccess
              ? "success"
              : isSuccessDraft
              ? "success-draft"
              : "failure"
          }
          onNext={() => {
            navigate(-1);
          }}
          onClose={() => {}}
        />
      )}

      {showSideBar && (
        <SideBarErrors
          handleClose={() => setShowSideBar(false)}
          data={alertMessage}
          setActiveCityID={setActiveCityID}
          activePositionID={activePositionID}
          setActivePositionID={setActivePositionID}
          activeExactPositionID={activeExactPositionID}
          setActiveExactPositionID={setActiveExactPositionID}
          positionsForm={positionsForm}
          setPositionsForm={setPositionsForm}
        />
      )}
      <div
        className={`${Styles.buttonsWrapper} ${
          +currentStep !== 1 && +currentStep !== 7 ? Styles.height : ""
        }`}
      >
        <div className={Styles.innerButtonsWrapper}>
          {currentStep != 1 && (
            <button className={Styles.previous} onClick={onPrevious}>
              <Icon icon={"arrow-left-back"} size={20} />
              <span>
                {`Mundur ke Step ${+currentStep - 1}: ${previousButtonText}`}
              </span>
            </button>
          )}
          {currentStep != 7 && (
            <button className={Styles.next} onClick={handleOnNext}>
              <span>
                {`Lanjut ke Step ${+currentStep + 1}: ${nextButtonText}`}
              </span>
              <Icon icon={"arrow-right-next"} size={20} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
