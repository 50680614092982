import Icon from "@Atom/Icon";
import UseQuery from "@Hooks/useQuery";
import CustomBreadCrumb from "@Molecule/CustomBreadCrumb";
import Stepper from "@Molecule/Stepper";
import AddOrderLayout from "@Organism/Manager/Client/AddOrder";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import {
  getAllContactsByClientCode,
  orderSync,
} from "@Services/manager/client";
import { Suspense, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Await, defer, useLoaderData, useNavigate } from "react-router-dom";
import LoadingSkeleton from "./Skeleton";
import Styles from "./style.module.scss";

export async function loader(route) {
  const url = new URL(route?.request?.url);
  const code = url.searchParams.get("c") || "";
  const packageOrderSyncPromise = orderSync("OWNER");
  const packageClientContactPromise = getAllContactsByClientCode(code);

  return defer({
    packageOrderSync: packageOrderSyncPromise,
    packageClientContacts: packageClientContactPromise,
  });
}

export default function AddNewOrder() {
  const query = UseQuery();
  const name = query.get("n");
  const code = query.get("c");

  const { packageOrderSync, packageClientContacts } = useLoaderData();
  const [alertMessage, setAlertMessage] = useState([]);
  const [showSideBar, setShowSideBar] = useState(false);
  const [activeCityID, setActiveCityID] = useState("");
  const [activePositionID, setActivePositionID] = useState("");
  const [activeExactPositionID, setActiveExactPositionID] = useState("");

  const defaultPositionsForm = JSON.parse(
    sessionStorage.getItem("positionsForm")
  );

  const [positionsForm, setPositionsForm] = useState(
    defaultPositionsForm?.length > 0 ? defaultPositionsForm : []
  );

  // useEffect(() => {
  //   if (alertMessage) {
  //     setTimeout(() => {
  //       setAlertMessage(null);
  //     }, 5000);
  //   }
  // }, [alertMessage]);

  const defaultSteps = JSON.parse(sessionStorage.getItem("steps"));

  const [stepsOptions, setStepOptions] = useState([
    {
      id: 1,
      label: "Contract Type",
      isDone: defaultSteps ? defaultSteps[0]?.isDone : false,
    },
    {
      id: 2,
      label: "Work Scheme",
      isDone: defaultSteps ? defaultSteps[1]?.isDone : false,
    },
    {
      id: 3,
      label: "Additional Information",
      isDone: defaultSteps ? defaultSteps[2]?.isDone : false,
    },
    {
      id: 4,
      label: "Deployment",
      isDone: defaultSteps ? defaultSteps[3]?.isDone : false,
    },
    {
      id: 5,
      label: "Position",
      isDone: defaultSteps ? defaultSteps[4]?.isDone : false,
    },
    {
      id: 6,
      label: "Payment Method & Estimation",
      isDone: defaultSteps ? defaultSteps[5]?.isDone : false,
    },
    {
      id: 7,
      label: "Summary",
      isDone: defaultSteps ? defaultSteps[6]?.isDone : false,
    },
    // {
    //   id: 6,
    //   label: "Deployment",
    //   isDone: defaultSteps ? defaultSteps[5]?.isDone : false,
    // },
    // {
    //   id: 7,
    //   label: "Finish",
    //   isDone: defaultSteps ? defaultSteps[6]?.isDone : false,
    // },
  ]);

  const defaultStep = sessionStorage.getItem("orderStep");

  const [currentStep, setCurrentStep] = useState(
    defaultStep ? +defaultStep : 1
  );
  const [maxStep, setMaxStep] = useState(1);

  const [contractType, setContractType] = useState(null);

  const handleClickStep = (step) => {
    if (currentStep >= step) {
      setCurrentStep(step);
      setAlertMessage("");
    }
  };
  const navigate = useNavigate();
  const handleClickBack = () => {
    // navigate(`/clients`);
    navigate(-1);
  };

  const handleNavigateDetails = () => {
    navigate(
      `/clients/details?n=${name}&c=${code}&h=${String(Math.random()).slice(2)}`
    );
  };

  useEffect(() => {
    sessionStorage.setItem("orderStep", currentStep);
  }, [currentStep]);

  const [headerHeight, setHeaderHeight] = useState();
  const headerRef = useRef();

  const getHeight = () => {
    const newHeight = headerRef?.current?.clientHeight;
    setHeaderHeight(newHeight);
  };

  useEffect(() => {
    getHeight();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getHeight);
  }, []);

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Add New Order</title>
      </Helmet>
      <div ref={headerRef} className={Styles.headerWrapper}>
        <div className={Styles.topWrapper}>
          <div className={Styles.Wrapper}>
            <div className={Styles.breadcrumbWrapper}>
              <span onClick={handleClickBack}>
                <Icon
                  icon="arrow-left-rounded-primary"
                  color="#1571DE"
                  size={20}
                />
              </span>
              <CustomBreadCrumb>
                <span
                  onClick={handleClickBack}
                  className={Styles.breadcrumbText}
                >
                  Client
                </span>
                <span
                  onClick={handleNavigateDetails}
                  className={Styles.breadcrumbText}
                >
                  {name}
                </span>
                <span className={Styles.breadcrumbText2}>New Order</span>
              </CustomBreadCrumb>
            </div>
            {contractType && (
              <div className={Styles.typeWrapper}>
                <Icon icon="file" size={27} className={Styles.contractIcon} />
                <div className={Styles.DescWrapper}>
                  <span>{contractType?.type}</span>
                  <span>{contractType?.label}</span>
                </div>
              </div>
            )}
          </div>
          <div className={Styles.stepsWrapper}>
            <Stepper
              type="filled"
              onClick={handleClickStep}
              maxStep={maxStep}
              setMaxstep={setMaxStep}
              options={stepsOptions}
              currentStep={currentStep}
              noWhiteSpace
              noDoneIcon
            />
          </div>
        </div>
        {alertMessage?.length > 0 ? (
          <div className={Styles.infoWrapper}>
            <div className={Styles.left}>
              <div className={Styles.icon}>
                <Icon icon={"alert-solid"} size={20} color={"#F5610D"} />
              </div>

              <span>
                Terdapat data yang belum diisi, silahkan lengkapi data dibawah
                untuk dapat melanjutkan ke step selanjutnya
              </span>
            </div>

            <div
              className={Styles.buttonRight}
              onClick={() => {
                if (alertMessage?.length > 1) {
                  setShowSideBar(true);
                } else {
                  if (
                    alertMessage[0]?.name?.includes("Data Business Manager") ||
                    alertMessage[0]?.name?.includes("Data Recruitment Officer")
                  ) {
                    setActiveCityID(alertMessage[0]?.cityCode);
                  }

                  if (
                    alertMessage[0]?.name?.includes(
                      "Belum ada posisi diaktifkan"
                    ) ||
                    alertMessage[0]?.name?.includes("Tempo salary") ||
                    alertMessage[0]?.name?.includes("Nominal salary") ||
                    alertMessage[0]?.name?.includes(
                      "Expected fulfillment date"
                    ) ||
                    alertMessage[0]?.name?.includes("Personal Equipment") ||
                    alertMessage[0]?.name?.includes("Allowances")
                  ) {
                    setActivePositionID(alertMessage[0]?.positionId);
                    setActiveExactPositionID(
                      alertMessage[0]?.positionId +
                        "-" +
                        alertMessage[0]?.cityId
                    );
                    setPositionsForm(
                      positionsForm?.map((obj) =>
                        obj?.id === alertMessage[0]?.positionId
                          ? {
                              ...obj,
                              isExpanded: true,
                            }
                          : {
                              ...obj,
                              isExpanded: false,
                            }
                      )
                    );
                  }

                  const element = document.getElementById(
                    `${alertMessage[0]?.remark}`
                  );
                  if (element) {
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                      inline: "start",
                    });
                  }
                }
              }}
            >
              <span>
                {alertMessage?.length === 1
                  ? "Lengkapi Data"
                  : "Lihat Daftar Data Kosong"}
              </span>
              <div>
                <Icon icon={"arrow-up-right"} size={20} color={"#1571DE"} />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {/* {alertMessage && currentStep !== 4 ? (
          <div className={Styles.infoWrapper}>
            <Icon icon={"alert-solid"} size={20} color={"#F5610D"} />
            <span>{alertMessage}</span>
          </div>
        ) : (
          <></>
        )} */}
      </div>

      <div className={Styles.contentWrapper}>
        <Suspense fallback={<LoadingSkeleton />}>
          <Await
            resolve={packageOrderSync}
            errorElement={
              <div className={Styles.errorWrapper}>
                <AsyncErrorBoundary />
              </div>
            }
          >
            {(packageOrderSync) => (
              <Suspense fallback={<LoadingSkeleton />}>
                <Await
                  resolve={packageClientContacts}
                  errorElement={
                    <div className={Styles.errorWrapper}>
                      <AsyncErrorBoundary />
                    </div>
                  }
                >
                  {(packageClientContacts) => (
                    <AddOrderLayout
                      showSideBar={showSideBar}
                      setShowSideBar={setShowSideBar}
                      options={packageOrderSync?.response?.options}
                      clientContacts={packageClientContacts?.response}
                      qualifications={
                        packageOrderSync?.response?.qualifications
                      }
                      cities={packageOrderSync?.response?.cities}
                      users={packageOrderSync?.response?.users}
                      banks={packageOrderSync?.response?.banks}
                      setContractType={setContractType}
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      clientCode={code}
                      clientName={name}
                      maxStep={maxStep}
                      setMaxStep={setMaxStep}
                      steps={stepsOptions}
                      setSteps={setStepOptions}
                      alertMessage={alertMessage}
                      setAlertMessage={setAlertMessage}
                      contractType={contractType}
                      previousButtonText={
                        currentStep !== 1
                          ? stepsOptions[+currentStep - 2]?.label
                          : ""
                      }
                      nextButtonText={
                        currentStep !== 7
                          ? stepsOptions[+currentStep]?.label
                          : ""
                      }
                      headerHeight={headerHeight}
                      activeCityID={activeCityID}
                      setActiveCityID={setActiveCityID}
                      activePositionID={activePositionID}
                      setActivePositionID={setActivePositionID}
                      activeExactPositionID={activeExactPositionID}
                      setActiveExactPositionID={setActiveExactPositionID}
                      positionsForm={positionsForm}
                      setPositionsForm={setPositionsForm}
                    />
                  )}
                </Await>
              </Suspense>
            )}
          </Await>
        </Suspense>
      </div>
    </div>
  );
}
